import React from 'react'
import { Form, Select } from 'antd'
import { colSettings } from '../../../../../constants'
// import { CandidatePanelContext } from '../../../../../contexts/candidatePanelContext'
import { selectSearchFilter } from '../../../../../helpers'
// import { appConfig } from '../../../../../constants/appConfig'
// import { ConfigContext } from '../../../../../contexts/configContext'

const { Option } = Select

function VacanciesItemSelector({
  onChange,
  vacancyList,
  label = 'Вакансия',
  required = true,
  allowClear = false,
  size = 'middle',
  name = 'vacancy',
  wrapperCol = colSettings.full,
  mode = ''
}) {
  // const { candidate } = useContext(CandidatePanelContext)
  // const {
  //   features: { data: features }
  // } = useContext(ConfigContext)

  return (
    <Form.Item
      label={label}
      required={required}
      labelCol={colSettings.full}
      wrapperCol={wrapperCol}
      name={name}
      rules={[{ required, message: 'Укажите вакансию' }]}
    >
      <Select
        size={size}
        placeholder="Выберите вакансию"
        onChange={value => onChange?.(value)}
        mode={mode}
        allowClear={allowClear}
        optionFilterProp="children"
        filterOption={selectSearchFilter}
      >
        {vacancyList?.map(vacancy => (
          <Option
            key={vacancy._id}
            value={vacancy._id}
            // disabled={
            //   candidate?._id && features.includes(appConfig.features.amp)
            //     ? candidate.vacancy?.module !== vacancy.module
            //     : false
            // }
          >
            {vacancy.displayName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default VacanciesItemSelector
