import React, { useMemo } from 'react'
import { Table } from 'antd'
import { upperFirst } from '../../../../helpers'
import { useVacancies } from '../../../../hooks/useVacancies'

const defaultSize = {
  name: 'auto',
  fullTime: 70
}
function RequestNeedTableAMP({
  data,
  size = 'middle',
  columnsSize = defaultSize
  // isDepartmentPlan,
  // readOnly
}) {
  const { isLoadingVacancies } = useVacancies({
    showInForecasting: true
  })

  const dataSource = useMemo(() => data, [data])
  // const dataSource = useMemo(
  //   () =>
  //     (isDepartmentPlan
  //       ? data
  //       : [
  //           ...(data || []),
  //           ...(
  //             ampVacancies?.map(v => {
  //               if (!data?.find(i => i.vacancy?._id === v._id)) {
  //                 return { fullTime: 0, vacancy: v }
  //               }
  //             }) || []
  //           ).filter(Boolean)
  //         ]
  //     )
  //       ?.map(e => ({ ...e, ...e.vacancy }))
  //       ?.filter(v => (readOnly ? Number.isInteger(v.fullTime) : true)),
  //   [ampVacancies, isDepartmentPlan, readOnly, data]
  // )

  const columns = [
    {
      title: 'Должность',
      dataIndex: 'displayName',
      width: columnsSize.name,
      render: text => upperFirst(text)
    },
    {
      title: 'Количество',
      dataIndex: 'fullTime',
      align: 'center',
      width: columnsSize.fullTime
    }
  ]

  return (
    <Table
      rowKey="name"
      loading={isLoadingVacancies}
      columns={columns}
      dataSource={dataSource}
      bordered
      pagination={false}
      size={size}
    />
  )
}

export default RequestNeedTableAMP
