import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Form, Typography } from 'antd'

import DemandApprovalStage from './DemandApprovalStage'
import { ConfigContext } from '../../../../contexts/configContext'
import { appConfig } from '../../../../constants/appConfig'

const { Text } = Typography
const validRoles = [appConfig.roles.operationDirector, appConfig.roles.partner, appConfig.roles.TU]

export default function DemandApprovalRoutes({ list, form, editMode }) {
  const [stages, setStages] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])

  useEffect(() => {
    if (list) {
      setStages(list)
      setSelectedRoles(
        list.reduce(
          (prev, curr) => [...prev, ...curr.approvers.map(approver => approver?.value)],
          []
        )
      )
    }
  }, [list])

  const {
    roles: { data: roles }
  } = useContext(ConfigContext)

  const actualRoles = useMemo(
    () => roles.filter(role => validRoles.includes(role.name)) || [],
    [roles]
  )

  const filteredRoles = useMemo(() => {
    let existsRoles = (form.getFieldValue('approvalStages') || []).reduce(
      (prev, curr) => [...prev, ...curr.approvers],
      selectedRoles
    )
    return actualRoles?.filter(p => !existsRoles?.includes(p?.name)) || []
  }, [actualRoles, form, selectedRoles])

  const handleSelectRole = useCallback(
    (role, isRemove) => {
      if (isRemove) {
        setSelectedRoles(selectedRoles.filter(e => e !== role))
      } else {
        setSelectedRoles([...selectedRoles, role])
      }
    },
    [selectedRoles]
  )

  const handleAddStage = useCallback(() => {
    let list = [
      ...(form.getFieldValue('approvalStages') || []),
      { name: 'Этап ' + (stages.length + 1), approvers: [] }
    ]
    setStages(list)
    form.setFieldsValue({ approvalStages: list })
  }, [form, stages])

  return (
    <div className="mb-3">
      <div className="sub-title">Маршрут согласования заявок</div>
      <Form.Item hidden name="approvalStages" />
      {stages?.length ? (
        stages.map(stage => (
          <DemandApprovalStage
            stage={stage}
            key={stage.name}
            form={form}
            isEdit={editMode}
            actualRoles={actualRoles}
            filteredRoles={filteredRoles}
            onSelectRole={handleSelectRole}
          />
        ))
      ) : (
        <Text type="secondary">Не задано</Text>
      )}
      {editMode && (
        <Button
          type="primary"
          block
          ghost
          onClick={handleAddStage}
          size="large"
          className="mt-3 mb-3"
        >
          Добавить этап согласования
        </Button>
      )}
    </div>
  )
}
