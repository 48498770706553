import { atom } from 'recoil'
import { initialPagination } from '../helpers'

//  инициализация фильтров из 'localStorage'
const inboxFilteredInfo = localStorage.getItem('inboxFilterModel') || '{}'
const inboxSortedInfo = localStorage.getItem('inboxSortModel') || '{}'
const workflowFilteredInfo = localStorage.getItem('workflowFilterModel') || '{}'
const workflowSortedInfo = localStorage.getItem('workflowSortModel') || '{}'
const vacancyFilteredInfo = localStorage.getItem('vacancyFilterModel') || '{}'
const vacancySortedInfo = localStorage.getItem('vacancySortModel') || '{}'
const demandFilteredInfo = localStorage.getItem('demandFilterModel') || '{}'
const demandSortedInfo = localStorage.getItem('demandSortModel') || '{}'

export const initPageSettings = {
  // кандидаты рекрутера
  inbox: {
    pagination: {
      current: initialPagination.current,
      pageSize: initialPagination.pageSize,
      // position: ['topRight', 'bottomRight'],
      showSizeChanger: true
    },
    activeTab: ''
  },
  // Настройка воронки
  workflow: {
    pagination: {
      current: initialPagination.current,
      pageSize: initialPagination.pageSize
    },
    search: '',
    activeTab: ''
  },
  // вакансии
  vacancy: {
    pagination: {
      current: initialPagination.current,
      pageSize: initialPagination.pageSize,
      position: ['topRight', 'bottomRight'],
      showSizeChanger: true
    },
    search: '',
    activeTab: ''
  },
  // заявки
  demand: {
    pagination: {
      current: initialPagination.current,
      pageSize: initialPagination.pageSize,
      position: ['bottomRight'],
      showSizeChanger: true
    }
    // search: '',
    // activeTab: ''
  },
  // поиск кандидата
  find: {
    search: '',
    activeTab: ''
  },
  // кандидаты директора
  hiringManager: {
    activeTab: ''
  },
  // пропсы прокинутые в компонент извне
  customProperties: {
    menuTheme: 'light'
  }
}
const localStorageEffect =
  key =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue, _, isReset) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue))
      return isReset ? {} : newValue
    })
  }

export const findSettingsAtom = atom({
  key: 'findSettings',
  default: initPageSettings.find
})

export const hiringManagerSettingsAtom = atom({
  key: 'hiringManagerSettings',
  default: initPageSettings.hiringManager
})

export const inboxSettingsAtom = atom({
  key: 'inboxSettings',
  default: initPageSettings.inbox
})

export const inboxFiltersAtom = atom({
  key: 'inboxFilter',
  default: JSON.parse(inboxFilteredInfo),
  effects: [localStorageEffect('inboxFilterModel')]
})

export const inboxSorterAtom = atom({
  key: 'inboxSort',
  default: JSON.parse(inboxSortedInfo),
  effects: [localStorageEffect('inboxSortModel')]
})

export const workflowSettingsAtom = atom({
  key: 'workflowSettings',
  default: initPageSettings.workflow
})

export const workflowFiltersAtom = atom({
  key: 'workflowFilter',
  default: JSON.parse(workflowFilteredInfo),
  effects: [localStorageEffect('workflowFilterModel')]
})

export const workflowSorterAtom = atom({
  key: 'workflowSort',
  default: JSON.parse(workflowSortedInfo),
  effects: [localStorageEffect('workflowSortModel')]
})

export const customPropertiesSettingsAtom = atom({
  key: 'customProperties',
  default: initPageSettings.customProperties
  // effects: [
  //   ({ onSet }) => {
  //     onSet(newID => {
  //       console.debug(
  //         '%c Current user ID:',
  //         'color: green; font-weight: bold; font-size: 22px',
  //         newID
  //       )
  //     })
  //   }
  // ]
})

export const vacancySettingsAtom = atom({
  key: 'vacancySettings',
  default: initPageSettings.vacancy
})

export const vacancyFiltersAtom = atom({
  key: 'vacancyFilter',
  default: JSON.parse(vacancyFilteredInfo),
  effects: [localStorageEffect('vacancyFilterModel')]
})

export const vacancySorterAtom = atom({
  key: 'vacancySort',
  default: JSON.parse(vacancySortedInfo),
  effects: [localStorageEffect('vacancySortModel')]
})

export const demandSettingsAtom = atom({
  key: 'demandSettings',
  default: initPageSettings.demand
})

export const demandFiltersAtom = atom({
  key: 'demandFilter',
  default: JSON.parse(demandFilteredInfo),
  effects: [localStorageEffect('demandFilterModel')]
})

export const demandSorterAtom = atom({
  key: 'demandSort',
  default: JSON.parse(demandSortedInfo),
  effects: [localStorageEffect('demandSortModel')]
})
