import React, { useContext } from 'react'
import { Checkbox, Form } from 'antd'
import { ConfigContext } from '../../../../../contexts/configContext'

function ModulesItem({ onChange, disabledValues }) {
  const {
    modules: { data: modules }
  } = useContext(ConfigContext)
  return (
    <Form.Item
      label="Модуль"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
      labelAlign="left"
      required
      name="modules"
      rules={[
        {
          required: true,
          message: 'Выберите модуль'
        }
      ]}
    >
      <Checkbox.Group>
        {modules?.map(module => (
          <Checkbox
            key={module.name}
            value={module.name}
            onChange={e => onChange?.(e)}
            disabled={disabledValues ? disabledValues.includes(module.name) : undefined}
          >
            {module.displayName}
          </Checkbox>
        ))}
      </Checkbox.Group>
    </Form.Item>
  )
}

export default ModulesItem
