import { Table, message, Spin, Button, Row, Col, Select, Typography, Switch } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import { appConfig } from '../../../../constants/appConfig'
import { useUsers } from '../../../../api/users'
import { renderNameWithEmail } from '../../../../helpers/department'
import { useLinkUsersJobSite } from '../../../../api/integrations'
import { selectSearchFilter } from '../../../../helpers'
import { useVacancies } from '../../../../hooks/useVacancies'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import { useDepartmentsQuery } from '../../../../api/department'

// const { confirm } = Modal
const { Text } = Typography
const { Option } = Select

function IntegrationsBots({ data, jobSiteData }) {
  const queryClient = useQueryClient()
  const [bots, setBots] = useState([])
  const [errors, setErrors] = useState({})
  const { getDepartmentPluralName, rolesLabels } = useConfigContextData()

  useEffect(() => {
    setBots(
      structuredClone(
        data?.map(item => ({
          ...item,
          internal: {
            ...(item.internal || {}),
            isActive: !!item.internal
          }
        }))
      )
    )
  }, [data])

  const { vacancies, isErrorVacancies, isLoadingVacancies } = useVacancies({
    showInLists: true
  })

  useEffect(() => {
    if (isErrorVacancies) {
      message.error('Ошибка загрузки данных для вакансий')
    }
  }, [isErrorVacancies])

  const {
    data: dataRecruiters,
    isError: isErrorRecruiters,
    isLoading: isLoadingRecruiters
  } = useUsers({
    filters: { roles: [appConfig.roles.recruiter], active: true }
  })

  const recruiterList = useMemo(() => dataRecruiters?.data || [], [dataRecruiters?.data])

  useEffect(() => {
    if (isErrorRecruiters) {
      message.error('Ошибка загрузки данных о рекрутерах')
    }
  }, [isErrorRecruiters])

  const {
    mutate: mutateLinkBotsJobSite,
    isSuccess: isSuccessLinkUsersJobSite,
    isError: isErrorLinkUsersJobSite,
    isLoading: isLoadingLinkUsersJobSite
  } = useLinkUsersJobSite(jobSiteData?.id, queryClient)

  useEffect(() => {
    if (isErrorLinkUsersJobSite) {
      message.error('Ошибка привязки ботов')
    } else if (isSuccessLinkUsersJobSite) {
      message.success('Боты успешно привязаны')
    }
  }, [isSuccessLinkUsersJobSite, isErrorLinkUsersJobSite])

  const { data: departmentsData, isError: isErrorDepartments } = useDepartmentsQuery()

  const departments = useMemo(() => departmentsData?.data, [departmentsData?.data])

  useEffect(() => {
    if (isErrorDepartments) {
      message.error('Ошибка загрузки орг. единиц')
    }
  }, [isErrorDepartments])

  const handleChangeIsActive = useCallback(
    (value, item) => {
      let currentBot = bots.find(bot => bot?.external?.id === item?.external?.id)
      currentBot.internal.isActive = value
      if (!value) {
        currentBot.internal = currentBot.internal || {}
        currentBot.internal.vacancy = undefined
        currentBot.internal.user = undefined
        currentBot.internal.departments = undefined
        delete errors[item?.external?.id]
        setErrors(errors)
      }
      setBots([...bots])
    },
    [bots, errors]
  )

  const handleClearVacancy = useCallback(
    item => {
      let currentBot = bots.find(bot => bot?.external?.id === item?.external?.id)
      currentBot.internal.vacancy = undefined
      setBots([...bots])
    },
    [bots]
  )

  const handleClearUser = useCallback(
    item => {
      let currentBot = bots.find(bot => bot?.external?.id === item?.external?.id)
      currentBot.internal.user = undefined
      setBots([...bots])
    },
    [bots]
  )

  const handleSelectVacancy = useCallback(
    (value, item) => {
      let currentBot = bots.find(bot => bot?.external?.id === item?.external?.id)
      currentBot.internal = currentBot.internal || {}
      currentBot.internal.vacancy = value
      setBots([...bots])
    },
    [bots]
  )

  const handleSelectUser = useCallback(
    (value, item) => {
      let currentBot = bots.find(bot => bot?.external?.id === item?.external?.id)
      currentBot.internal = currentBot.internal || {}
      currentBot.internal.user = value
      setBots([...bots])
    },
    [bots]
  )

  const handleChangeDepartments = useCallback(
    (value, item) => {
      let currentBot = bots.find(bot => bot?.external?.id === item?.external?.id)
      currentBot.internal = currentBot.internal || {}
      currentBot.internal.departments = value?.length ? value : undefined
      setBots([...bots])
    },
    [bots]
  )

  const handleCancel = useCallback(() => {
    setBots(
      structuredClone(
        data?.map(item => ({
          ...item,
          internal: {
            ...(item.internal || {}),
            isActive: !!item.internal
          }
        }))
      )
    )
    setErrors({})
  }, [data])

  // const getUsers = useCallback(
  //   item => {
  //     let activeUsers = bots
  //       .filter(bot => bot.external.id !== item.external.id && bot.internal?.user)
  //       .map(bot => bot.internal?.user)
  //     return recruiterList.filter(user => !activeUsers.includes(user._id))
  //   },
  //   [recruiterList, bots]
  // )

  const columns = [
    {
      title: '',
      dataIndex: ['internal', 'isActive'],
      width: 70,
      render: (value, item) => (
        <Switch
          checked={value}
          className="checkbox-line-height-24"
          value={appConfig.modules.crewman}
          onChange={checked => handleChangeIsActive(checked, item)}
        />
      )
    },
    {
      title: `${jobSiteData?.displayName} боты`,
      dataIndex: 'external',
      render: text => <Row>{renderNameWithEmail(text)}</Row>
    },
    {
      title: 'Системная вакансия',
      dataIndex: ['internal', 'vacancy'],
      render: (vacancy, item) =>
        item.internal.isActive ? (
          <div className="select-with-error">
            <Select
              onSelect={id => handleSelectVacancy(id, item)}
              value={vacancy}
              className={`integrations-selector ${
                errors?.[item.external.id] && !errors?.[item.external.id]?.vacancy && 'error'
              }`}
              placeholder="Системная вакансия"
              disabled={!item.internal.isActive}
              showSearch
              optionFilterProp="children"
              filterOption={selectSearchFilter}
              allowClear
              onClear={() => handleClearVacancy(item)}
            >
              {vacancies?.map(vacancy => (
                <Option key={vacancy?._id} value={vacancy?._id}>
                  {vacancy?.displayName}
                </Option>
              ))}
            </Select>
            {errors?.[item.external.id] && !errors?.[item.external.id]?.vacancy && (
              <Text type="danger">поле обязательно</Text>
            )}
          </div>
        ) : (
          <Text type="danger">неактивно</Text>
        )
    },
    {
      title: `Ответственный ${rolesLabels[appConfig.roles.recruiter].toLowerCase()}`,
      dataIndex: ['internal', 'user'],
      render: (recruiter, item) =>
        item.internal.isActive ? (
          <div className="select-with-error">
            <Select
              value={recruiter}
              disabled={!item.internal.isActive}
              onSelect={id => handleSelectUser(id, item)}
              placeholder={`Ответственный ${rolesLabels[appConfig.roles.recruiter].toLowerCase()}`}
              showSearch
              className={`integrations-selector ${
                errors?.[item.external.id] && !errors?.[item.external.id]?.user && 'error'
              }`}
              optionFilterProp="children"
              filterOption={selectSearchFilter}
              allowClear
              onClear={() => handleClearUser(item)}
            >
              {recruiterList?.map(user => (
                <Option key={user._id} value={user._id}>
                  {user.name}
                </Option>
              ))}
            </Select>
            {errors?.[item.external.id] && !errors?.[item.external.id]?.user && (
              <Text type="danger">поле обязательно</Text>
            )}
          </div>
        ) : (
          <Text type="danger">неактивно</Text>
        )
    },
    {
      title: getDepartmentPluralName(),
      dataIndex: ['internal', 'departments'],
      render: (departmentsIds, item) =>
        item.internal.isActive ? (
          <div className="select-with-error">
            <Select
              value={departmentsIds}
              placeholder="Все"
              showSearch
              optionFilterProp="children"
              filterOption={selectSearchFilter}
              allowClear
              className="integrations-selector"
              mode="multiple"
              onChange={ids => handleChangeDepartments(ids, item)}
            >
              {departments?.map(department => (
                <Option key={department._id} value={department._id}>
                  {department.name}
                </Option>
              ))}
            </Select>
          </div>
        ) : (
          <Text type="danger">неактивно</Text>
        )
    }
  ]

  const handleSave = () => {
    let errors = Object.fromEntries(
      bots
        .filter(bot => bot.internal.isActive && (!bot.internal?.user || !bot.internal?.vacancy))
        .map(bot => [bot.external.id, bot.internal])
    )
    if (Object.keys(errors).length) {
      setErrors(errors)
    } else {
      mutateLinkBotsJobSite({
        jobId: jobSiteData?.id,
        data: {
          bots: bots.map(b => ({
            externalId: b.external.id,
            external: b.external,
            linkedId: b?.internal?.user,
            vacancyId: b?.internal?.vacancy,
            departmentIds: b?.internal?.departments
          }))
        }
      })
    }
  }

  return (
    <Spin spinning={isLoadingRecruiters || isLoadingVacancies}>
      <div className="content IntegrationsBots">
        <Table
          size="middle"
          columns={columns}
          rowKey={item => item.external.id}
          dataSource={bots}
          pagination={false}
          scroll={{ x: '100%', y: 'calc(100vh - 400px)' }}
        />
      </div>
      <Row justify="end" className="mt-3">
        <Col>
          <Button type="link" disabled={isLoadingLinkUsersJobSite} onClick={handleCancel}>
            Отмена
          </Button>
          <Button type="primary" onClick={handleSave} loading={isLoadingLinkUsersJobSite}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </Spin>
  )
}
export default IntegrationsBots
