import { api } from '../api'
export const COMMENTS_KEY = 'comments'

export const onSuccessMutateComment = queryClient => () => {
  queryClient.refetchQueries(COMMENTS_KEY, { active: true })
  queryClient.refetchQueries(COMMENTS_KEY)
}

export const getComments = ({ queryKey }) => {
  const [, /* key */ params] = queryKey
  return api.get({ path: COMMENTS_KEY, params })
}

/**
 * data: {
 *   text: "comment",
 *   contentType: "demand",
 *   entity: demandId
 * }
 */
export const putComment = data => {
  return api.put({ path: COMMENTS_KEY, data })
}

export const deleteComment = id => {
  return api.del({ path: `${COMMENTS_KEY}/${id}` })
}
/**
 * data: {
 *   text: "comment"
 * }
 */
export const patchComment = ({ id, data }) => {
  return api.patch({ path: `${COMMENTS_KEY}/${id}`, data })
}
