import { Col, message, Progress, Row, Spin, Typography } from 'antd'
// import { green, red } from '@ant-design/colors'
import React, { useEffect, useMemo } from 'react'

import { appConfig } from '../../../../constants/appConfig'
import { useGetSettingsLimits } from '../../../../api/setting'
import './UsersLimits.less'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'

const { Text } = Typography
const roles = [
  appConfig.roles.admin,
  appConfig.roles.manager,
  appConfig.roles.hiringManager,
  appConfig.roles.recruiter
]
export default function UsersLimits() {
  const { rolesLabels } = useConfigContextData()
  const {
    data: dataSettingsLimits,
    isError: isErrorSettingsLimits,
    isLoading: isLoadingSettingsLimits
  } = useGetSettingsLimits()

  const userSettingsLimits = useMemo(() => dataSettingsLimits?.data, [dataSettingsLimits?.data])
  useEffect(() => {
    if (isErrorSettingsLimits) {
      message.error('Ошибка получения лимитов пользователей')
    }
  }, [isErrorSettingsLimits])

  return (
    <div>
      <Spin spinning={isLoadingSettingsLimits}>
        {roles.map(role => (
          <Row align="middle" justify="start" key={role}>
            <Col span={8}>
              <Progress
                percent={
                  userSettingsLimits?.usersStat?.roles?.[role] &&
                  userSettingsLimits?.limits?.users?.roles?.[role]
                    ? userSettingsLimits?.limits?.users?.roles?.[role] <
                      userSettingsLimits?.usersStat?.roles?.[role]
                      ? 100
                      : Math.ceil(
                          userSettingsLimits?.limits?.users?.roles?.[role] /
                            userSettingsLimits?.usersStat?.roles?.[role]
                        )
                    : 5
                }
                showInfo={false}
                status="active"
              />
            </Col>
            <Col className="users-limits-lable" span={2}>
              {userSettingsLimits?.usersStat?.roles?.[role] ? (
                <Text>
                  {userSettingsLimits?.usersStat?.roles?.[role]}/
                  {userSettingsLimits?.limits?.users?.roles?.[role] || <>&#8734;</>}
                </Text>
              ) : (
                <>&#8734;</>
              )}
            </Col>
            <Col className="users-limits-lable">
              <Text className="ml-3">{rolesLabels[role]}</Text>
            </Col>
          </Row>
        ))}
      </Spin>
    </div>
  )
}
