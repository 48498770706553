import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form, Row, Select, Typography } from 'antd'
import { Link } from 'react-router-dom'

import './DemandApprovalStage.less'
import { colSettings } from '../../../../constants'
import { DraggableList } from '../DraggableList/DraggableList'
import { DeleteOutlined, HolderOutlined } from '@ant-design/icons'
import { selectSearchFilter } from '../../../../helpers'

const { Option } = Select
const { Text } = Typography

export default function DemandApprovalStage({
  stage,
  form,
  isEdit = false,
  actualRoles,
  filteredRoles,
  onSelectRole
}) {
  const [approvers, setApprovers] = useState([])
  useEffect(() => {
    if (stage?.approvers) {
      setApprovers(stage?.approvers.map(approver => approver?.value || approver))
      form.setFieldsValue({ [stage.name]: stage })
    }
  }, [stage, form])

  const handleAddOption = useCallback(() => {
    if (approvers.includes('')) return
    const list = [...approvers, '']
    setApprovers(list)
    let stages = form.getFieldValue('approvalStages')
    stages.map(s => {
      if (s.name === stage.name) {
        s.approvers = list
      }
    })
    form.setFieldsValue({ [stage.name]: { approvers: list }, approvalStages: stages })
  }, [approvers, form, stage])

  const handleSelect = useCallback(
    value => {
      let list = [...approvers.slice(0, -1), value]

      setApprovers(list)
      let stages = form.getFieldValue('approvalStages')
      stages.map(s => {
        if (s.name === stage.name) {
          s.approvers = list
        }
      })
      onSelectRole(value)
      form.setFieldsValue({ [stage.name]: { approvers: list }, approvalStages: stages })
    },
    [approvers, form, stage, onSelectRole]
  )

  // const handleEditOption = useCallback(
  //   ({ index, value }) => {
  //     let list = [...approvers]
  //     if (value) {
  //       list[index] = value
  //     } else {
  //       // удаляем пустое значение если в причине ничего не введено
  //       if (!list[index]) {
  //         list = list.slice(0, -1)
  //       }
  //     }
  //     setApprovers(list)
  //     let stages = form.getFieldValue('approvalStages')
  //       stages.map(s => {
  //         if (s.name === stage.name) {
  //           s.approvers = list
  //         }
  //       })
  //     form.setFieldsValue({ [stage.name]: { approvers: list }, approvalStages: stages })
  //   },
  //   [approvers, form, stage]
  // )

  const handleDeleteOption = useCallback(
    (index, role) => {
      const list = [...approvers]
      list.splice(index, 1)

      setApprovers(list)
      let stages = form.getFieldValue('approvalStages')
      stages.map(s => {
        if (s.name === stage.name) {
          s.approvers = list
        }
      })
      onSelectRole(role, true)
      form.setFieldsValue({ [stage.name]: { approvers: list }, approvalStages: stages })
    },
    [approvers, form, stage, onSelectRole]
  )

  const handleChangeList = useCallback(
    list => {
      setApprovers(list)
      let stages = form.getFieldValue('approvalStages')
      stages.map(s => {
        if (s.name === stage.name) {
          s.approvers = list
        }
      })
      form.setFieldsValue({ [stage.name]: { approvers: list }, approvalStages: stages })
    },
    [form, stage]
  )
  const onRenderContent = useCallback(
    (o, index) => {
      const pos = actualRoles.find(p => p.name === o)?.displayName

      return (
        <Row className="list-wrapper" key={index} align="middle">
          {isEdit && (
            <Col>
              <HolderOutlined />
            </Col>
          )}
          <Col className="ml-3 name-item">
            {o ? (
              <Text>{pos}</Text>
            ) : (
              <Select
                // className="manager-selector"
                // loading={isLoadingUsers}
                onChange={handleSelect}
                placeholder="Выберите роль"
                showSearch
                optionFilterProp="children"
                filterOption={selectSearchFilter}
                allowClear
              >
                {filteredRoles?.map(role => (
                  <Option key={role.name} value={role.name}>
                    <div>{role.displayName}</div>
                  </Option>
                ))}
              </Select>
            )}
          </Col>
          <Col>
            {isEdit && (
              <Link
                title="Удалить"
                onClick={() => handleDeleteOption(index, o)}
                className="close ml-3"
              >
                <DeleteOutlined className="red" />
              </Link>
            )}
          </Col>
        </Row>
      )
    },
    [handleDeleteOption, filteredRoles, handleSelect, actualRoles, isEdit]
  )

  return (
    <div>
      <Form.Item
        className="space-between"
        label={stage.name}
        labelCol={colSettings.full}
        wrapperCol={colSettings.full}
        name={[stage.name, 'approvers']}
        // rules={[
        //   {
        //     required: true,
        //     validator: (rule, value) => {
        //       if (value.length < 2) {
        //         return Promise.reject('Добавьте не менее 2 вариантов')
        //       }
        //       return Promise.resolve()
        //     }
        //   }
        // ]}
      >
        <DraggableList
          list={approvers}
          onChangeList={handleChangeList}
          onRenderContent={onRenderContent}
        />
        {isEdit && (
          <Link onClick={handleAddOption} disabled={approvers.includes('')}>
            + Добавить
          </Link>
        )}
      </Form.Item>
    </div>
  )
}
