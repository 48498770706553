import { notification } from 'antd'
import { useCallback } from 'react'

const DEFAULT_DURATION = 4.5
const DEFAULT_PLACEMENT = 'topRight'

/**
 * Хук для работы с уведомлениями
 * Предоставляет методы для показа различных типов уведомлений
 * @returns {Object} API для работы с уведомлениями:
 * - success(message, options) - показать успешное уведомление
 * - error(message, options) - показать уведомление об ошибке
 * - info(message, options) - показать информационное уведомление
 * - warning(message, options) - показать предупреждающее уведомление
 * @example
 * const notify = useNotification()
 * notify.success('Операция выполнена успешно')
 * notify.error('Произошла ошибка', { title: 'Ошибка' })
 */
export const useNotification = () => {
  const [api] = notification.useNotification()

  const showNotification = useCallback(
    (type, message, options = {}) => {
      const { title, duration = DEFAULT_DURATION, placement = DEFAULT_PLACEMENT, key } = options

      api[type]({
        message: title,
        description: message,
        duration,
        placement,
        key
      })
    },
    [api]
  )

  return {
    success: useCallback(
      (message, options) => showNotification('success', message, options),
      [showNotification]
    ),
    error: useCallback(
      (message, options) => showNotification('error', message, options),
      [showNotification]
    ),
    info: useCallback(
      (message, options) => showNotification('info', message, options),
      [showNotification]
    ),
    warning: useCallback(
      (message, options) => showNotification('warning', message, options),
      [showNotification]
    )
  }
}
