import React, { lazy } from 'react'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useSetRecoilState } from 'recoil'
import { useQueryClient } from 'react-query'

import { ProtectedLayout } from './components/ProtectedLayout'
import { HomeLayout } from './components/HomeLayout'
import { UserLayout } from './components/UserLayout'
import { AuthLayout } from './components/AuthLayout'
import { RequiredDataLayout } from './components/RequiredDataLayout'
import { Theme } from './components/Theme'
import { profileLoader } from './api/users'
import { modulesLoader } from './api/modules'
import { settingsLoader } from './api/setting'
import { workflowsLoader } from './api/workflows'
import { featuresLoader } from './api/features'
import { AppointmentProvider } from './hooks/useAppointment'
import MassRecruitment from './modules/massRecruitment/MassRecruitment'
import Auth from './modules/massRecruitment/Screens/Auth/Auth'
import {
  EXTERNAL_BUTTON,
  EXTERNAL_BUTTON_CANDIDATE_PAGE,
  FORGOT_PASSWORD,
  NO_AUTH_CANDIDATE_PAGE,
  PASSWORD_RECOVERY
} from './constants/routes'
import { ExternalCandidateEdit, ExternalMain, ExternalAuthError } from './modules/ExternalButton'
import { ForgotPassword, PasswordRecovery } from './modules/massRecruitment/Screens'
import { ErrorLayout } from './modules/massRecruitment/Screens/ErrorLayout/ErrorLayout'
import { customPropertiesSettingsAtom } from './recoil/atoms'
import { rolesLoader } from './api/roles'
// import { modules } from './constants'
const NoAuthCandidate = lazy(() => import('./modules/NoAuthCandidate'))

const requiredDataLoader = queryClient => async () => {
  const settings = settingsLoader(queryClient)
  const workflows = workflowsLoader(queryClient)
  const roles = rolesLoader(queryClient)
  const features = featuresLoader(queryClient)
  const modules = modulesLoader(queryClient)
  return Promise.all([settings, workflows, features, roles, modules]).then(
    ([settings, workflows, features, roles, modules]) => {
      // if (settings?.status === 200) {
      //   initLocale(settings?.data?.[appConfig.settings.language])
      //   window.__localeId__ = settings?.data?.[appConfig.settings.language]
      // } else {
      //   message.error('Ошибка получения настроек')
      // }
      // if (workflows?.status !== 200) {
      //   message.error('Ошибка получения процессов найма')
      // }
      // if (features?.status !== 200) {
      //   message.error('Ошибка получения списка фич')
      // }
      return {
        settings: settings?.data,
        workflows: workflows?.data,
        features: features?.data,
        roles: roles?.data,
        modules: modules?.data
      }
    }
  )
}
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = queryClient =>
  sentryCreateBrowserRouter(
    // const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<UserLayout />}>
        <Route path={PASSWORD_RECOVERY} element={<PasswordRecovery />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={NO_AUTH_CANDIDATE_PAGE} element={<NoAuthCandidate />} />

        <Route
          element={<RequiredDataLayout />}
          loader={requiredDataLoader(queryClient)}
          errorElement={<ExternalAuthError />}
        >
          <Route path={EXTERNAL_BUTTON} element={<ExternalMain />} />
          <Route path={EXTERNAL_BUTTON_CANDIDATE_PAGE} element={<ExternalCandidateEdit />} />
        </Route>

        <Route
          element={<AuthLayout />}
          loader={profileLoader(queryClient)}
          errorElement={
            <HomeLayout>
              <Auth />
            </HomeLayout>
          }
        >
          <Route element={<RequiredDataLayout />} loader={requiredDataLoader(queryClient)}>
            <Route element={<HomeLayout />}>
              <Route path="/" element={<Auth />} />
            </Route>
            <Route element={<AppointmentProvider />}>
              <Route element={<ProtectedLayout />} errorElement={<ErrorLayout />}>
                <Route path="/*" element={<MassRecruitment />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    )
  )
export default function App({ customProperties }) {
  const setCustomProperties = useSetRecoilState(customPropertiesSettingsAtom)
  const queryClient = useQueryClient()

  setCustomProperties(oldValues => ({
    ...(oldValues || {}),
    ...(customProperties || {})
    // systemVersionTitle: 'Enterprise edition', // для тестирования Бургера
    // menuTheme: 'dark', // для тестирования Бургера
    // moduleName: modules.bk // для тестирования Бургера
  }))

  return (
    <Theme>
      <div className="App">
        <RouterProvider router={router(queryClient)} />
      </div>
    </Theme>
  )
}
