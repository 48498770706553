import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons'
import draftToHtml from 'draftjs-to-html'
import { mdToDraftjs } from 'draftjs-md-converter'
import React from 'react'

import { defaultTitle } from '../constants'

export const renderUserFieldValue = item => (
  <>
    {item.field?.controlType === 'checkbox' ? (
      item.value ? (
        <CheckSquareOutlined />
      ) : (
        <CloseSquareOutlined />
      )
    ) : item.field?.controlType === 'editor' ? (
      item.value ? (
        <div dangerouslySetInnerHTML={{ __html: draftToHtml(mdToDraftjs(item.value)) }} />
      ) : (
        item.value || defaultTitle
      )
    ) : Array.isArray(item.value) ? (
      item.value.join(', ')
    ) : (
      item.value || defaultTitle
    )}
  </>
)
