import React, { lazy, Suspense, useMemo } from 'react'
import { ConfigProvider, Spin } from 'antd'
// для подгрузки стилей к drawer
import '../styles/themes/default.less'
import { useRecoilValue } from 'recoil'
import { customPropertiesSettingsAtom } from '../recoil/atoms'

export const Theme = ({ children }) => {
  // const { pathname } = useLocation()
  const { theme, variables } = useRecoilValue(customPropertiesSettingsAtom)

  const themes = useMemo(() => {
    // let MassRecruitmentTheme = () => <></>
    let ExternalTheme = () => <></>
    // if (pathname !== '/') {
    let MassRecruitmentTheme = lazy(() => import('./MassRecruitmentTheme'))
    theme && (ExternalTheme = theme)
    // }
    return (
      <>
        <MassRecruitmentTheme />
        <ExternalTheme />
      </>
    )
  }, [/*pathname,*/ theme])

  return (
    <Suspense
      fallback={
        <div className="w-100 h-100 text-center">
          <Spin spinning size="large" className="full-screen-spin" />
        </div>
      }
    >
      <ConfigProvider theme={theme}>
        <ConfigProvider
          theme={{
            token: {
              fontSize: 16,
              borderRadius: 6,
              fontFamily: "'Roboto', sans-serif",
              ...variables
            }
          }}
        >
          {themes}
          {children}
        </ConfigProvider>
      </ConfigProvider>
    </Suspense>
  )
}
