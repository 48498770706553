import { useMutation, useQuery } from 'react-query'
import {
  COMMENTS_KEY,
  deleteComment,
  getComments,
  onSuccessMutateComment,
  patchComment,
  putComment
} from './comments'

export function useQueryPlanComments(params, options) {
  return useQuery([COMMENTS_KEY, params], getComments, options)
}

const putPlanComment = ({ id, data }) => {
  return putComment({
    ...data,
    contentType: 'plan',
    entity: id
  })
}

const deletePlanComment = ({ commentId }) => {
  return deleteComment(commentId)
}

const patchPlanComment = ({ commentId: id, data }) => {
  return patchComment({ id, data })
}

export function useMutateCreatePlanComment(queryClient) {
  return useMutation(putPlanComment, {
    onSuccess: onSuccessMutateComment(queryClient)
  })
}

export function useMutateDeletePlanComment(queryClient) {
  return useMutation(deletePlanComment, {
    onSuccess: onSuccessMutateComment(queryClient)
  })
}

export function useMutateUpdatePlanComment(queryClient) {
  return useMutation(patchPlanComment, {
    onSuccess: onSuccessMutateComment(queryClient)
  })
}
