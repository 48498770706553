import React from 'react'
import { Col, Form, Row } from 'antd'
import { useConfigContextData } from '../../../../../hooks/useConfigContextData'

const DepartmentNumberItem = ({ name, value }) => {
  const { departmentType } = useConfigContextData()
  return (
    <Row align="middle">
      <Col span={5}>
        <Form.Item label={departmentType} required />
      </Col>
      <Col span={19}>
        <Form.Item name="departmentExternalId" initialValue={value}>
          {name}
        </Form.Item>
      </Col>
    </Row>
  )
}

export default DepartmentNumberItem
