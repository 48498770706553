import { api } from './api'
import { useMutation, useQuery } from 'react-query'
import { DEMANDS_KEY } from './demands'

export const DEMAND_USER_FIELDS_KEY = 'demandUserFields'
export const USER_FIELDS_KEY = 'candidateUserFields'

export const fetchGetDemandUserFields = () => {
  return api.get({ path: 'userform/demand' })
}
export const fetchGetCandidateUserFields = () => {
  return api.get({ path: 'userform/candidate' })
}
export const fetchUpdateUserField = ({ data, id }) => {
  return api.patch({ path: `userform/${id}`, data })
}
export const putUserField = data => {
  return api.put({ path: 'userform', data })
}

export function useGetDemandUserFields(options) {
  return useQuery([DEMAND_USER_FIELDS_KEY], fetchGetDemandUserFields, options)
}
export function useGetCandidateUserFields(options) {
  return useQuery([USER_FIELDS_KEY], fetchGetCandidateUserFields, options)
}

export function useMutateCreateUserField(queryClient) {
  return useMutation(putUserField, {
    onSuccess: () => {
      queryClient.refetchQueries(USER_FIELDS_KEY, { active: true })
      queryClient.refetchQueries(DEMAND_USER_FIELDS_KEY, { active: true })
      queryClient.invalidateQueries(DEMANDS_KEY)
      queryClient.refetchQueries(DEMANDS_KEY, { active: true })
    }
  })
}
export function useMutateUpdateUserField(queryClient) {
  return useMutation(fetchUpdateUserField, {
    onSuccess: () => {
      queryClient.refetchQueries(USER_FIELDS_KEY, { active: true })
      queryClient.refetchQueries(DEMAND_USER_FIELDS_KEY, { active: true })
      queryClient.invalidateQueries(DEMANDS_KEY)
      queryClient.refetchQueries(DEMANDS_KEY, { active: true })
    }
  })
}
