import { useContext, useEffect, useState } from 'react'

import { default as massRecruitmentRoutes } from '../modules/massRecruitment/routes'
import { areArraysIntersect } from '../helpers'
import { ConfigContext } from '../contexts/configContext'
import { useConfigContextData } from './useConfigContextData'

export const useRoutes = (user, module) => {
  const [routes, setRoutes] = useState(null)
  const {
    features: { data: features }
  } = useContext(ConfigContext)
  const { getDepartmentPluralName } = useConfigContextData()

  useEffect(() => {
    const routes = [...massRecruitmentRoutes({ departmentName: getDepartmentPluralName() })]
    if (!Object.keys(user).length || !module) return setRoutes(null)
    const isValidRoles = roles => areArraysIntersect(roles, user.roles)
    const isValidOptionally = options =>
      user.roles.includes(options.role) && user.modules.includes(options.module)

    const isRouteAvailable = route =>
      route.availableToAll ||
      isValidRoles(route.availableRoles) ||
      (route.availableOptionally && isValidOptionally(route.availableOptionally))

    const data = routes
      .filter(route => isRouteAvailable(route))
      .filter(route => !route.featureToggle || features?.includes(route.featureToggle))
      .filter(route => !route.withoutAgency || !user.agency)
      .map(route => {
        if (route?.children) {
          route.children = route.children
            .filter(childRoute => isRouteAvailable(childRoute))
            .filter(
              childRoute =>
                !childRoute.featureToggle || features?.includes(childRoute.featureToggle)
            )
            .filter(childRoute => !childRoute.withoutAgency || !childRoute.agency)
        }
        return route
      })
    setRoutes(data)
  }, [getDepartmentPluralName, user, module, features])

  return {
    routes
  }
}
