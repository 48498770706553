import React from 'react'
import { Col } from 'antd'

import { sortBy } from '../../../../../helpers'
import { renderUserFieldValue } from '../../../../../helpers/userFields'

function UserFieldsList({ userFields, withoutEmptyRow = false, boldTitle = false }) {
  return (
    <>
      {/*<Text type="secondary">Пользовательские поля:</Text>*/}
      {!withoutEmptyRow && <div className="item">&nbsp;</div>}
      {userFields
        ?.filter(item => item.field?.enable)
        ?.sort(sortBy('field.sortOrder'))
        ?.map(item => (
          <Col span={24} className="item" key={item._id}>
            {boldTitle ? <b>{item.field?.displayName}:</b> : `${item.field?.displayName}:`}{' '}
            {renderUserFieldValue(item)}
          </Col>
        ))}
      {/*{!userFields?.length && <Text type="secondary">&nbsp;Отсутствуют</Text>}*/}
    </>
  )
}

export default UserFieldsList
