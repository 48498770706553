import React, { memo } from 'react'
import { Skeleton, Space } from 'antd'

const skeletonStyles = {
  padding: '24px',
  background: '#fff',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
}

/**
 * Компонент для отображения состояния загрузки
 * Использует Ant Design Skeleton для создания плейсхолдера контента
 * @param {Object} props
 * @param {boolean} [props.avatar=true] - Показывать ли аватар
 * @param {number} [props.rows=4] - Количество строк в скелетоне
 * @param {string} [props.className] - Дополнительные стили
 */
const LoadingSpinner = memo(({ avatar = true, rows = 4, className }) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} className={className}>
      <div style={skeletonStyles}>
        <Skeleton active avatar={avatar} paragraph={{ rows }} round />
      </div>
    </Space>
  )
})

LoadingSpinner.displayName = 'LoadingSpinner'

export { LoadingSpinner }
