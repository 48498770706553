import React, { lazy, useEffect, useMemo } from 'react'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useSetRecoilState } from 'recoil'
import { useQueryClient } from 'react-query'

import { ProtectedLayout } from './components/ProtectedLayout'
import { HomeLayout } from './components/HomeLayout'
import { UserLayout } from './components/UserLayout'
import { AuthLayout } from './components/AuthLayout'
import { RequiredDataLayout } from './components/RequiredDataLayout'
import { Theme } from './components/Theme'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner'

import { profileLoader } from './api/users'
import { modulesLoader } from './api/modules'
import { settingsLoader } from './api/setting'
import { workflowsLoader } from './api/workflows'
import { featuresLoader } from './api/features'
import { rolesLoader } from './api/roles'

import { AppointmentProvider } from './hooks/useAppointment'
import { useNotification } from './hooks/useNotification'

import MassRecruitment from './modules/massRecruitment/MassRecruitment'
import Auth from './modules/massRecruitment/Screens/Auth/Auth'
import {
  EXTERNAL_BUTTON,
  EXTERNAL_BUTTON_CANDIDATE_PAGE,
  FORGOT_PASSWORD,
  NO_AUTH_CANDIDATE_PAGE,
  PASSWORD_RECOVERY
} from './constants/routes'
import { ExternalCandidateEdit, ExternalMain, ExternalAuthError } from './modules/ExternalButton'
import { ForgotPassword, PasswordRecovery } from './modules/massRecruitment/Screens'
import { ErrorLayout } from './modules/massRecruitment/Screens/ErrorLayout/ErrorLayout'
import { customPropertiesSettingsAtom } from './recoil/atoms'

// Ленивая загрузка компонента
const NoAuthCandidate = lazy(() => import('./modules/NoAuthCandidate'))

/**
 * Загрузчик необходимых данных для приложения
 * @param {QueryClient} queryClient - Клиент React Query
 * @param {Object} notify - Объект для уведомлений
 * @returns {Promise<Object>} Объект с загруженными данными
 */
const requiredDataLoader = (queryClient, notify) => async () => {
  try {
    const [settings, workflows, features, roles, modules] = await Promise.all([
      settingsLoader(queryClient),
      workflowsLoader(queryClient),
      featuresLoader(queryClient),
      rolesLoader(queryClient),
      modulesLoader(queryClient)
    ])

    // Проверка статусов и обработка ошибок
    const responses = { settings, workflows, features, roles, modules }
    Object.entries(responses).forEach(([key, response]) => {
      if (response?.status !== 200) {
        notify.error(`Ошибка получения ${key}`)
      }
    })

    return {
      settings: settings?.data,
      workflows: workflows?.data,
      features: features?.data,
      roles: roles?.data,
      modules: modules?.data
    }
  } catch (error) {
    Sentry.captureException(error)
    notify.error('Ошибка загрузки данных')
    throw error
  }
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

/**
 * Конфигурация роутера приложения
 * @param {QueryClient} queryClient - Клиент React Query
 * @param {Object} notify - Объект для уведомлений
 */
const router = (queryClient, notify) =>
  sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route element={<UserLayout />}>
        <Route
          path={PASSWORD_RECOVERY}
          element={
            <ErrorBoundary>
              <PasswordRecovery />
            </ErrorBoundary>
          }
        />
        <Route
          path={FORGOT_PASSWORD}
          element={
            <ErrorBoundary>
              <ForgotPassword />
            </ErrorBoundary>
          }
        />
        <Route
          path={NO_AUTH_CANDIDATE_PAGE}
          element={
            <React.Suspense fallback={<LoadingSpinner />}>
              <ErrorBoundary>
                <NoAuthCandidate />
              </ErrorBoundary>
            </React.Suspense>
          }
        />

        <Route
          element={<RequiredDataLayout />}
          loader={requiredDataLoader(queryClient, notify)}
          errorElement={<ExternalAuthError />}
        >
          <Route path={EXTERNAL_BUTTON} element={<ExternalMain />} />
          <Route path={EXTERNAL_BUTTON_CANDIDATE_PAGE} element={<ExternalCandidateEdit />} />
        </Route>

        <Route
          element={<AuthLayout />}
          loader={profileLoader(queryClient)}
          errorElement={
            <HomeLayout>
              <Auth />
            </HomeLayout>
          }
        >
          <Route element={<RequiredDataLayout />} loader={requiredDataLoader(queryClient, notify)}>
            <Route element={<HomeLayout />}>
              <Route path="/" element={<Auth />} />
            </Route>
            <Route element={<AppointmentProvider />}>
              <Route element={<ProtectedLayout />} errorElement={<ErrorLayout />}>
                <Route path="/*" element={<MassRecruitment />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    )
  )

/**
 * Главный компонент приложения
 * @param {Object} props - Пропсы компонента
 * @param {Object} props.customProperties - Пользовательские настройки
 */
export default function App({ customProperties }) {
  const setCustomProperties = useSetRecoilState(customPropertiesSettingsAtom)
  const queryClient = useQueryClient()
  const notify = useNotification()

  useEffect(() => {
    if (customProperties) {
      setCustomProperties(oldValues => ({
        ...(oldValues || {}),
        ...customProperties
        /**
         * systemVersionTitle: 'Enterprise edition', // для тестирования Бургера
         * menuTheme: 'dark', // для тестирования Бургера
         * moduleName: modules.bk // для тестирования Бургера
         */
      }))
    }
  }, [customProperties, setCustomProperties])

  const routerInstance = useMemo(() => router(queryClient, notify), [queryClient, notify])

  return (
    <ErrorBoundary>
      <Theme>
        <div className="App">
          <RouterProvider router={routerInstance} />
        </div>
      </Theme>
    </ErrorBoundary>
  )
}
