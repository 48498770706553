import React from 'react'
import { Typography, Collapse, Badge, Table, Avatar, Tooltip } from 'antd'
import { UserOutlined, TagsOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { appConfig } from '../../constants/appConfig'
import './ChangesHistoryPanel.less'

const { Text } = Typography

/**
 * Компонент панели истории изменений
 * @param {Object} props - Свойства компонента
 * @param {Array} props.changesData - Данные об изменениях для отображения
 */
function ChangesHistoryPanel({ changesData = [] }) {
  const renderTruncatedCell = text => {
    if (!text) return <Text type="secondary">—</Text>
    const truncatedText =
      String(text).length > 20 ? String(text).substring(0, 20) + '...' : String(text)
    return (
      <Tooltip title={String(text)}>
        <span className="truncated-cell">{truncatedText}</span>
      </Tooltip>
    )
  }

  const changesMemberColumns = [
    {
      title: 'Дата и время',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: value => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {dayjs(value).format(appConfig.formats.shortDateAndTime)}
        </div>
      )
    },
    {
      title: 'Автор',
      dataIndex: 'user',
      key: 'user',
      width: 150,
      render: (name, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.avatar ? (
            <Avatar size="small" src={record.avatar} style={{ marginRight: '8px' }} />
          ) : (
            <UserOutlined style={{ marginRight: '8px', color: '#1890ff' }} />
          )}
          {renderTruncatedCell(name)}
        </div>
      )
    },
    {
      title: 'Поле',
      dataIndex: 'field',
      key: 'field',
      width: 150,
      render: value => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TagsOutlined style={{ marginRight: '8px', color: '#1890ff' }} />
          {renderTruncatedCell(value)}
        </div>
      )
    },
    {
      title: 'Было',
      dataIndex: 'old',
      key: 'old',
      width: 150,
      render: renderTruncatedCell
    },
    {
      title: 'Стало',
      dataIndex: 'new',
      key: 'new',
      width: 150,
      render: renderTruncatedCell
    }
  ]

  return (
    <div className="changes-history-panel">
      <div className="changes-history-panel-collapse">
        {changesData.map(change => (
          <Collapse key={change.name}>
            <Collapse.Panel
              header={
                <>
                  <div className="changes-header">
                    <div className="changes-info">
                      <Text strong>{change.name}</Text>
                      <Badge count={change.members?.length} className="changes-badge" />
                    </div>
                    <Text type="secondary" className="changes-date">
                      {change.lastUpdated}
                    </Text>
                  </div>
                </>
              }
              key={change.id || change.name}
            >
              <Table
                dataSource={change.members}
                columns={changesMemberColumns}
                rowKey={record => `${record.id || record.email}`}
                pagination={false}
                rowClassName={record => `row-type-${record.changeType}`}
                size="small"
              />
            </Collapse.Panel>
          </Collapse>
        ))}
      </div>
    </div>
  )
}

export default ChangesHistoryPanel
