import React from 'react'
import { Modal, Select, Button, Row, Col } from 'antd'

const { Option } = Select

const IntegrationsMassUpdatePopup = ({
  visible,
  onClose,
  onSave,
  vacancies,
  recruiters,
  selectedVacancy,
  selectedRecruiter,
  setSelectedVacancy,
  setSelectedRecruiter
}) => {
  if (!visible) return null

  return (
    <Modal
      title="Изменить выбранные вакансии"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отмена
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={onSave}
          disabled={!selectedVacancy || !selectedRecruiter}
        >
          Сохранить
        </Button>
      ]}
    >
      <Row gutter={[16, 16]} className="mt-3">
        <Col span={20}>
          <Select
            value={selectedVacancy}
            onChange={setSelectedVacancy}
            placeholder="Системная вакансия"
            className="mb-4 full-width"
          >
            {vacancies.map(vacancy => (
              <Option key={vacancy._id} value={vacancy._id}>
                {vacancy.displayName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={20}>
          <Select
            value={selectedRecruiter}
            onChange={setSelectedRecruiter}
            placeholder="Ответственный рекрутер"
            className="mb-4 full-width"
          >
            {recruiters.map(recruiter => (
              <Option key={recruiter._id} value={recruiter._id}>
                {recruiter.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  )
}

export default IntegrationsMassUpdatePopup
