import { useQuery, useMutation } from 'react-query'
import { api, downloadFile } from './api'
import { APPOINTMENTS_KEY } from './appointment'
import { makeFilterParams, makeSearchParamByPhoneOrName } from '../helpers'
import { AMP_KEY } from './amp'

export const CANDIDATE_KEY = 'candidate'
export const CANDIDATES_KEY = 'candidates'
export const CANDIDATES_SEARCH_KEY = 'candidates_search'
export const CANDIDATES_STATES_KEY = 'candidates_states'
export const CANDIDATES_MINE = 'candidates_mine'
export const CANDIDATES_REPORT = 'candidates_report'
export const CANDIDATES_RESPONSIBLES_KEY = 'candidates_responsibles'
export const CANDIDATES_VACANCIES_KEY = 'candidates_vacancies'
export const CANDIDATE_MESSAGES_KEY = 'candidate_messages'
export const CANDIDATES_FILTER_VALUES_KEY = 'candidates_filter_values'
export const CANDIDATES_FIND_SIMILAR = 'candidates_find_similar'
export const CANDIDATES_CONFLICTS_KEY = 'candidates_conflicts'

export const refetchCandidate = queryClient => {
  queryClient.refetchQueries(CANDIDATE_KEY, { active: true })
  queryClient.refetchQueries(CANDIDATES_KEY, { active: true })
}

const fetchGetCandidateById = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `candidates/${id}` })
}

const fetchGetCandidatesBySearch = ({ queryKey }) => {
  const [, /* key */ value] = queryKey
  return api.get({ path: `candidates/search/${value}?size=150` })
}

const fetchPatchCandidateById = ({ id, data }) => {
  return api.patch({ path: `candidates/${id}`, data })
}

const fetchPutCandidate = ({ data }) => {
  return api.put({ path: 'candidates', data })
}

const fetchFindSimilar = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: 'candidates/findSimilar', data })
}

// все статусы в которых есть кандидаты
const getCandidatesStates = () => {
  return api.get({ path: 'candidates/states' })
}

const getCandidatesMine = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  const params = { ...data }
  if (params?.expired < 0) {
    delete params.expired
  }
  if (params?.period) {
    delete params.period
  }
  if (params?.range) {
    delete params.range
  }
  if (params?.vacancies) {
    params.vacancies = params.vacancies.join()
  }
  return api.get({ path: 'candidates/mine', params })
}

const getCandidatesReport = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: 'candidates/report', data })
}

// по возможности использовать <a href="...">
const getCandidatesReportXlsx = params => {
  const path = 'candidates/report/xlsx'
  return downloadFile({ path, filename: 'Отчет.xlsx', params })
}

// статусы которые отображаются на инбоксе
const getCandidatesStatesBySearch = ({ queryKey }) => {
  const [, /* key */ { mine, searchValue, filters }] = queryKey
  const data = {
    ...(mine && { mine }),
    ...makeFilterParams(filters),
    ...makeSearchParamByPhoneOrName(searchValue)
  }
  return api.post({ path: 'candidates/states/find', data })
}

const getCandidatesResponsible = () => {
  return api.get({ path: 'candidates/responsible' })
}

const getCandidatesVacancies = () => {
  return api.get({ path: 'candidates/vacancies' })
}

const fetchGetCandidates = ({ queryKey }) => {
  const [, /* key */ { pagination, filters = {}, sorter = {}, searchValue }] = queryKey
  const path = 'candidates'
  const data = {
    ...(pagination && { limit: pagination.pageSize, page: pagination.current }),
    ...(sorter.order && { order: sorter.order, orderField: sorter.orderField }),
    ...makeFilterParams(filters),
    ...makeSearchParamByPhoneOrName(searchValue)
  }
  return api.post({ path, data })
}

const getCandidateMessages = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `candidates/${id}/messages` })
}

const getCandidatesFilterValues = ({ queryKey }) => {
  const [, /* key */ { filterName, searchValue, filters = {}, state, mine }] = queryKey
  const utms = ['requestType', 'utm_source']
  const data = {
    ...(utms.includes(filterName) ? {} : makeFilterParams(filters)),
    ...(mine && { mine }),
    ...(state && { state }),
    ...makeSearchParamByPhoneOrName(searchValue)
  }
  return api.post({ path: `candidates/filterValues/${filterName}`, data })
}

const getCandidatesConflicts = ({ queryKey }) => {
  const [, /* key */ pagination] = queryKey
  const params = { limit: pagination?.pageSize, page: pagination?.current }
  return api.get({ path: `candidates/conflicts`, params })
}

// const fetchReuseCandidateById = ({ id, data }) => {
//   return api.post({ path: `candidates/${id}/reuse`, data })
// }

export function useCandidatesQuery(data, options) {
  // data = { pagination, filters, sorter }
  return useQuery([CANDIDATES_KEY, data], fetchGetCandidates, options)
}

export function useCandidateByIdQuery(id, options) {
  return useQuery([CANDIDATE_KEY, id], fetchGetCandidateById, options)
}

export function useCandidatesBySearch(value, options) {
  return useQuery([CANDIDATES_SEARCH_KEY, value], fetchGetCandidatesBySearch, options)
}

export function useMutateUpdateCandidate(queryClient) {
  return useMutation(fetchPatchCandidateById, {
    onSuccess: (data, variables) => {
      data?.data?._id && queryClient.setQueryData([CANDIDATE_KEY, variables.id], data)
      queryClient.refetchQueries(CANDIDATE_KEY, { active: true })
      queryClient.invalidateQueries(CANDIDATE_KEY)
      queryClient.refetchQueries(CANDIDATES_KEY)
      queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
      queryClient.refetchQueries(APPOINTMENTS_KEY, { active: true })
      queryClient.invalidateQueries(AMP_KEY)
      queryClient.refetchQueries(AMP_KEY, { active: true })
      queryClient.refetchQueries(CANDIDATES_MINE)
      queryClient.refetchQueries(CANDIDATES_FILTER_VALUES_KEY, { active: true })
    }
  })
}

export function useMutateCreateCandidate(queryClient) {
  return useMutation(fetchPutCandidate, {
    onSuccess: () => {
      queryClient.refetchQueries(APPOINTMENTS_KEY, { active: true })
      queryClient.removeQueries(AMP_KEY)
      queryClient.removeQueries(CANDIDATES_MINE)
      queryClient.refetchQueries(CANDIDATES_KEY)
      queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
      queryClient.refetchQueries(CANDIDATES_STATES_KEY)
      queryClient.refetchQueries(CANDIDATES_FILTER_VALUES_KEY, { active: true })
      queryClient.refetchQueries(CANDIDATES_FIND_SIMILAR, { active: true })
    },
    onSettled: () => {
      queryClient.refetchQueries(AMP_KEY, { active: true })
      queryClient.refetchQueries(CANDIDATES_MINE)
    }
  })
}

export function useFindSimilar(data, options) {
  return useQuery([CANDIDATES_FIND_SIMILAR, data], fetchFindSimilar, options)
}

export function useCandidatesStates(options) {
  return useQuery([CANDIDATES_STATES_KEY], getCandidatesStates, options)
}

export function useCandidatesMine(query, options) {
  return useQuery([CANDIDATES_MINE, query], getCandidatesMine, options)
}

export function useCandidatesReport(data, options) {
  return useQuery([CANDIDATES_REPORT, data], getCandidatesReport, options)
}

export function useCandidatesReportXlsx() {
  return useMutation(getCandidatesReportXlsx)
}

export function useCandidatesStatesBySearch(data, options) {
  return useQuery([CANDIDATES_STATES_KEY, data], getCandidatesStatesBySearch, options)
}

export function useCandidatesResponsible(options) {
  return useQuery([CANDIDATES_RESPONSIBLES_KEY], getCandidatesResponsible, options)
}

export function useCandidatesVacancies(options) {
  return useQuery([CANDIDATES_VACANCIES_KEY], getCandidatesVacancies, options)
}

export function useCandidateMessages(id, options) {
  return useQuery([CANDIDATE_MESSAGES_KEY, id], getCandidateMessages, options)
}

export function useCandidatesFilterValues(data, options) {
  return useQuery([CANDIDATES_FILTER_VALUES_KEY, data], getCandidatesFilterValues, options)
}

export function useCandidatesConflicts(data, options) {
  return useQuery([CANDIDATES_CONFLICTS_KEY, data], getCandidatesConflicts, options)
}
