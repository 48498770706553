import React, { useEffect } from 'react'
import { generatePath, useNavigate, useOutlet } from 'react-router-dom'
import { useProfile } from '../hooks/useProfile'
import { useRoutes } from '../hooks/useRoutes'
import { modules } from '../constants'

export const HomeLayout = ({ children }) => {
  const { user } = useProfile()
  const outlet = useOutlet()
  const navigate = useNavigate()

  // "user" нужен для того чтобы не пускать пользователя на логин без нажатия выход
  const { routes } = useRoutes(user || {}, modules.massRecruitment)

  useEffect(() => {
    if (routes) {
      navigate(generatePath(routes[0]?.path || routes[0]?.children?.[0]?.path, { id: null }))
    }
  }, [navigate, routes])

  return <div>{outlet || children}</div>
}
