import { api } from './api'
import { useMutation, useQuery } from 'react-query'
import { defaultQueryOptions } from '../constants'

const ROLES_KEY = 'roles'

const fetchRoles = () => {
  return api.get({ path: ROLES_KEY })
}
export function useQueryRoles(options) {
  return useQuery([ROLES_KEY], fetchRoles, { ...defaultQueryOptions, ...options })
}

const fetchUpdateRoles = ({ id, data }) => {
  return api.patch({ path: `${ROLES_KEY}/${id}`, data })
}
export function useMutateUpdateRoles(queryClient) {
  return useMutation(fetchUpdateRoles, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROLES_KEY)
      queryClient.refetchQueries(ROLES_KEY)
    }
  })
}

const rolesQuery = options => ({
  queryKey: [ROLES_KEY],
  queryFn: async () => fetchRoles(),
  options: { ...defaultQueryOptions, ...(options || {}) }
})
export const rolesLoader = async queryClient => {
  const query = rolesQuery()
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}
