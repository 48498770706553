import { api } from './api'
import { useMutation, useQuery } from 'react-query'
import { AGENCY_KEY } from './agency'
import { ORG_STRUCTURE_KEY } from './orgStructure'

export const USERS_KEY = 'users'
export const PROFILE_KEY = 'profile'

export const fetchProfile = () => {
  return api.get({ path: `${USERS_KEY}/profile` })
}
const profileQuery = () => ({
  queryKey: [PROFILE_KEY],
  queryFn: async () => fetchProfile()
})
export const profileLoader = queryClient => async () => {
  const query = profileQuery()
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export function useGetProfile(options) {
  return useQuery([PROFILE_KEY], fetchProfile, options)
}

export function useAuthProfileMutate(options) {
  return useMutation(fetchProfile, options)
}

const patchProfile = data => {
  return api.patch({ path: `${USERS_KEY}/profile`, data })
}

export function useMutateProfile() {
  return useMutation(patchProfile)
}

const fetchRequestPassword = data => {
  return api.post({ path: `${USERS_KEY}/password/request`, data })
}

export function useMutateRequestPassword() {
  return useMutation(fetchRequestPassword)
}

const fetchUpdatePassword = data => {
  return api.post({ path: `${USERS_KEY}/password/update`, data })
}

export function useMutateUpdatePassword() {
  return useMutation(fetchUpdatePassword)
}

const patchUser = ({ id, data }) => {
  return api.patch({ path: `${USERS_KEY}/${id}`, data })
}

export function useMutateUser(queryClient) {
  return useMutation(patchUser, {
    onSuccess: () => {
      queryClient.refetchQueries(USERS_KEY)
      queryClient.invalidateQueries(USERS_KEY)
      queryClient.refetchQueries(PROFILE_KEY)
      queryClient.invalidateQueries(AGENCY_KEY)
      queryClient.refetchQueries(ORG_STRUCTURE_KEY, { active: true })
    }
  })
}

const putUser = data => {
  return api.put({ path: `${USERS_KEY}/signup`, data })
}

export function useMutateCreateUser(queryClient) {
  return useMutation(putUser, {
    onSuccess: () => {
      queryClient.refetchQueries(USERS_KEY)
      queryClient.invalidateQueries(AGENCY_KEY)
    }
  })
}

const getUsers = ({ queryKey }) => {
  const [, /* key */ { pagination, filters }] = queryKey
  const data = {}
  if (pagination) {
    data.limit = pagination.pageSize
    data.page = pagination.current
  }

  if (filters) {
    data.roles = filters.roles
    data.position = filters.position
    data.name = filters.name
    data.email = filters.email
    data.text = filters.text
    data.orgUnit = filters.orgUnit
    data.active = filters.active
  }

  return api.post({ path: `${USERS_KEY}/find`, data })
}

export function useUsers(data = {}, options) {
  return useQuery([USERS_KEY, data], getUsers, options)
}

// const fetchGetRecruiters = ({ queryKey }) => {
//   const [, /* key */ scope] = queryKey
//   return api.get({ path: `${USERS_KEY}/recruiters/${scope}` })
// }

// export function useGetRecruiters(scope, options) {
//   return useQuery([`${USERS_KEY}/recruiters`, scope], fetchGetRecruiters, options)
// }
