import React, { useContext, useEffect } from 'react'
import { Form } from 'antd'
import { useRecoilValue } from 'recoil'

import { convertCrewmenToObject } from '../../../../helpers/plan'
import RequestNeedTable from '../PlanningPersonal/RequestNeedTable'

import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import RequestNeedTableAMP from '../PlanningPersonal/RequestNeedTableAMP'
import { modules } from '../../../../constants'
import { customPropertiesSettingsAtom } from '../../../../recoil/atoms'
import DemandNeedTableAMP from '../Demand/DemandNeedTableAMP'

const COLUMNS_SIZE = {
  name: 140,
  partTime: 40,
  fullTime: 40,
  forAdultsOnly: 40,
  morning: 40,
  noon: 40,
  evening: 40,
  night: 40,
  weekend: 40,
  cis: 40,
  male: 40,
  female: 40
}

export default function DepartmentPlanOrDemands({ department, onAction }) {
  const [form] = Form.useForm()
  const { isAmpCandidate } = useContext(CandidatePanelContext)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)

  useEffect(() => {
    if (department?.plan) {
      form.setFieldsValue(convertCrewmenToObject(department?.plan))
    }
  }, [form, department?.plan])

  return (
    <Form form={form} className="planningPersonal" colon={false}>
      {isAmpCandidate ? (
        moduleName === modules.bk ? (
          <RequestNeedTableAMP data={department?.plan?.additional} size="small" />
        ) : (
          <DemandNeedTableAMP department={department} size="small" onAction={onAction} />
        )
      ) : (
        <RequestNeedTable
          readOnly
          isDepartmentPlan
          data={department?.plan.crewmen}
          size="small"
          columnsSize={COLUMNS_SIZE}
          plan={department?.plan}
        />
      )}
    </Form>
  )
}
