import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import { Typography, Row, Col, message, Select, Form } from 'antd'
import { Link } from 'react-router-dom'

import PlanComments from '../PlanningPersonal/PlanComments'
import { useDepartmentsQuery } from '../../../../api/department'
import { debounce, removeSpecSymbols, simpleReducer } from '../../../../helpers'
import { countOfSymbolsInSearchFieldLikeId, defaultQueryOptions } from '../../../../constants'
import { appConfig } from '../../../../constants/appConfig'
import { UserContext } from '../../../../contexts/userContext'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'

const { Title, Text } = Typography
const { Option } = Select

function ExtraBlock({
  plan,
  interviewerDepartment,
  departmentForNewPlanId = null,
  form,
  onEdit,
  readOnly = false
}) {
  const initialState = {
    departments: [],
    search: undefined
  }
  const { user, isRecruiter, isManager, isHiringManager, isAdmin } = useContext(UserContext)
  const { departmentType, rolesLabels } = useConfigContextData()

  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: departmentData,
    isSuccess: isSuccessDepartment,
    isError: isErrorDepartment,
    isLoading: isLoadingDepartment
  } = useDepartmentsQuery(
    { text: state.search },
    {
      ...defaultQueryOptions,
      enabled: !!state.search && state.search.length < countOfSymbolsInSearchFieldLikeId
    }
  )

  useEffect(() => {
    if (isSuccessDepartment) {
      setState({
        departments: departmentData?.data
      })
    } else if (isErrorDepartment) {
      message.error('Ошибка поиска орг. единиц')
    }
  }, [departmentData, isSuccessDepartment, isErrorDepartment])

  useEffect(() => {
    // state.search: null - возможность удаления ресторана
    if (interviewerDepartment?.name && !state.search && state.search !== null) {
      setState({ search: interviewerDepartment.name })
    }
  }, [interviewerDepartment?.name, state.search])

  const handleSearch = value => {
    if (value) {
      debounceSearch(removeSpecSymbols(value))
    } else {
      // если очистили поле, чистим state
      setState(initialState)
    }
  }

  const isValidRole = useMemo(
    () => isManager || isHiringManager || isAdmin,
    [isManager, isHiringManager, isAdmin]
  )

  const debounceSearch = useMemo(
    () =>
      debounce(search => {
        setState({ search })
      }, 800),
    []
  )

  const handleChange = (search = null) => {
    setState({ search })
    onEdit?.(true)
  }

  const currentDepartmentId = useMemo(
    () => plan?.department?._id || user?.department?._id || departmentForNewPlanId,
    [departmentForNewPlanId, plan?.department?._id, user?.department?._id]
  )

  if (isRecruiter && !interviewerDepartment) return null
  return (
    <div className="mt-3">
      <Title level={4} className="blockSubTitle">
        Делегировать проведение собеседований в:
      </Title>
      <Row align="base">
        <Col span={8}>
          {!isValidRole ? (
            <Text>{interviewerDepartment?.name || plan?.department?.name}</Text>
          ) : (
            <Form.Item valuePropName="option" className="mb-3" name="interviewerDepartment">
              <Select
                showSearch
                allowClear
                value={state.search}
                placeholder={`${departmentType} для интервью`}
                loading={isLoadingDepartment}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={
                  state.search ? (
                    <Col span={24} key="nothing">
                      Ничего не найдено
                    </Col>
                  ) : null
                }
                disabled={readOnly}
              >
                {state.departments
                  ?.filter(department => department._id !== currentDepartmentId)
                  ?.map(item => (
                    <Option key={item._id} value={item._id}>
                      <Col span={24} className="result-item">
                        <Row justify="space-between" gutter={16} className="w-100">
                          <Col span={24} className="flex-column">
                            <div>{item.name}</div>
                          </Col>
                        </Row>
                      </Col>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        {interviewerDepartment && isValidRole && !readOnly && (
          <Col span={8} className="ml-3">
            <Link
              onClick={() => {
                handleChange()
                form.setFieldsValue({ interviewerDepartment: null })
              }}
            >
              Удалить из плана
            </Link>
          </Col>
        )}
      </Row>
      {isValidRole && (
        <>
          <Title level={4} className="blockSubTitle">
            Комментарии по планированию ({rolesLabels[appConfig.roles.manager]})
          </Title>
          {plan._id ? (
            <Row>
              <Col sm={22} md={16} lg={12} xl={10}>
                <PlanComments plan={plan} />
              </Col>
            </Row>
          ) : (
            <Text type="secondary">Комментарии недоступны пока план не создан.</Text>
          )}
        </>
      )}
    </div>
  )
}

export default ExtraBlock
