import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, Form, Input, Select } from 'antd'
import { convertFromRaw, EditorState } from 'draft-js'
import { mdToDraftjs } from 'draftjs-md-converter'
import { Editor } from 'react-draft-wysiwyg'

import { colSettings } from '../../../../../constants'
import { useGetCandidateUserFields } from '../../../../../api/userForm'
import { toolbarInline, toolbarList } from '../../../../../constants/editor'

// const { Text } = Typography
const { TextArea } = Input
const { Option } = Select

function UserFieldsFormItem({ form, onGetUserFields, userFields }) {
  const { data: userFieldsData } = (onGetUserFields || useGetCandidateUserFields)({ retry: 0 })
  const fields = useMemo(() => userFieldsData?.data, [userFieldsData?.data])
  const [editorStates, setEditorStates] = useState({})

  useEffect(() => {
    let userFieldsObj =
      Object.fromEntries(userFields?.map(item => [item.field?._id, item.value]) || []) || {}
    let fieldsData = { ...userFieldsObj }
    let stateData = {}
    fields?.forEach(field => {
      if (!userFieldsObj?.[field._id] && field.defaultValue !== undefined) {
        fieldsData[field._id] = field.defaultValue
      }
      if (field.controlType === 'editor') {
        if (userFieldsObj?.[field._id] && typeof userFieldsObj?.[field._id] === 'string') {
          stateData[field._id] = EditorState.createWithContent(
            convertFromRaw(mdToDraftjs(userFieldsObj?.[field._id]))
          )
        } else if (field.defaultValue) {
          stateData[field._id] = EditorState.createWithContent(
            convertFromRaw(mdToDraftjs(field.defaultValue))
          )
        } else {
          stateData[field._id] = EditorState.createEmpty()
        }
      }
    })
    setEditorStates(stateData)
    form.setFieldsValue({ userFields: { ...fieldsData } })
  }, [fields, form, userFields])

  // useEffect(() => {
  //   const data = fields?.filter(field => field.controlType === 'editor')
  //   if (data?.length) {
  //     setEditorState(
  //       data.reduce(
  //         (obj, item) => ({
  //           ...obj,
  //           [item._id]: item.defaultValue
  //             ? EditorState.createWithContent(convertFromRaw(mdToDraftjs(item.defaultValue)))
  //             : EditorState.createEmpty()
  //         }),
  //         {}
  //       )
  //     )
  //   }
  // }, [fields])

  const onEditorStateChange = useCallback(
    (id, editorState) => setEditorStates({ ...editorStates, [id]: editorState }),
    [editorStates]
  )

  const renderField = useCallback(
    field => {
      switch (field.controlType) {
        case 'select':
          return (
            <Select
              size="large"
              placeholder={field.placeholder}
              notFoundContent="Нет вариантов"
              defaultValue={field.defaultValue}
              mode={field.multi ? 'multiple' : ''}
              // onChange={onChange}
              disabled={!field.enable}
              // getPopupContainer={trigger => trigger.parentNode}
            >
              {field.options.map(c => (
                <Option key={c} value={c}>
                  {c}
                </Option>
              ))}
            </Select>
          )
        case 'textarea':
          return (
            <TextArea
              size="large"
              // maxLength={field.maxLength}
              placeholder={field.placeholder}
              disabled={!field.enable}
              defaultValue={field.defaultValue}
            />
          )
        case 'checkbox':
          return (
            <Checkbox
              size="large"
              defaultChecked={field.defaultValue}
              placeholder={field.placeholder}
              disabled={!field.enable}
            />
          )
        case 'text':
          return (
            <Input
              size="large"
              defaultValue={field.defaultValue}
              // maxLength={field.maxLength}
              placeholder={field.placeholder}
              disabled={!field.enable}
              type={field.dataType}
            />
          )
        case 'editor':
          return (
            <Editor
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbar={{
                options: ['inline', 'list'],
                inline: toolbarInline,
                list: toolbarList
              }}
              editorState={editorStates[field._id]}
              onEditorStateChange={state => onEditorStateChange(field._id, state)}
            />
          )
        default:
          return null
      }
    },
    [editorStates, onEditorStateChange]
  )

  return (
    <div>
      {/*<Text type="secondary">Пользовательские поля</Text>*/}
      {fields
        ?.filter(item => item.enable)
        ?.map(field =>
          field ? (
            <Form.Item
              key={field._id}
              label={field.displayName}
              labelCol={colSettings.full}
              wrapperCol={colSettings.full}
              name={['userFields', field._id]}
              rules={[
                {
                  required: field.required,
                  message: `Укажите ${field.displayName?.toLowerCase()}`,
                  ...(field.controlType === 'checkbox'
                    ? { transform: value => value || undefined, type: 'boolean' }
                    : {})
                },
                ...(field.maxLength
                  ? [
                      {
                        max: field.maxLength,
                        message: `Используйте не больше ${field.maxLength} символов`
                      }
                    ]
                  : [])
              ]}
              valuePropName={field.controlType === 'checkbox' ? 'checked' : 'value'}
            >
              {renderField(field)}
            </Form.Item>
          ) : null
        )}
    </div>
  )
}

export default UserFieldsFormItem
