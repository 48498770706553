import React, { useCallback, useState, useMemo } from 'react'
import { Col, Row, Typography, Input, Select, Empty, Spin } from 'antd'
import dayjs from 'dayjs'
import { SearchOutlined, UserOutlined, TagsOutlined } from '@ant-design/icons'

import { appConfig } from '../../../../constants/appConfig'
import { useCandidateChangesHistory } from '../../../../api/candidate'
import { useGetCandidateUserFields } from '../../../../api/userForm'
import { formatPhoneNumber } from '../../../../helpers/phone'
import { numberDeclension } from '../../../../helpers'
import './ChangesHistory.less'
import ChangesHistoryPanel from '../../../../components/ChangesHistoryPanel/ChangesHistoryPanel'

const { Search } = Input
const { Option } = Select
const { Title } = Typography

/**
 * Сравнивает два значения, учитывая возможность массивов
 * @param {*} oldValue - Старое значение
 * @param {*} newValue - Новое значение
 * @returns {boolean} - true если значения идентичны
 */
const areValuesEqual = (oldValue, newValue) => {
  if (Array.isArray(oldValue) && Array.isArray(newValue)) {
    if (oldValue.length !== newValue.length) return false
    return oldValue.every((item, index) => {
      if (typeof item === 'object' && item !== null) {
        return JSON.stringify(item) === JSON.stringify(newValue[index])
      }
      return item === newValue[index]
    })
  }
  return oldValue === newValue
}

function ChangesHistory({ candidateId }) {
  const [searchText, setSearchText] = useState('')
  const [authorFilter, setAuthorFilter] = useState('all')
  const [fieldFilter, setFieldFilter] = useState('all')

  // Получаем историю изменений кандидата
  const { data: changesHistoryData, isLoading: isChangesHistoryLoading } =
    useCandidateChangesHistory(candidateId, {
      enabled: true
    })

  // Получаем список всех пользовательских полей
  const { data: userFieldsData } = useGetCandidateUserFields({ retry: 0 })

  // Создаем мапу пользовательских полей для быстрого доступа
  const userFieldsMap = useMemo(() => {
    if (!userFieldsData?.data) return {}
    return userFieldsData.data.reduce((acc, field) => {
      acc[field._id] = {
        displayName: field.displayName,
        enabled: field.enable
      }
      return acc
    }, {})
  }, [userFieldsData])

  // Обработка изменения поискового запроса
  const handleSearch = useCallback(value => {
    setSearchText(value)
  }, [])

  // Обработка изменения фильтра автора
  const handleAuthorFilterChange = useCallback(value => {
    setAuthorFilter(value)
  }, [])

  // Обработка изменения фильтра поля
  const handleFieldFilterChange = useCallback(value => {
    setFieldFilter(value)
  }, [])

  // Функция для создания уникального списка авторов
  const getUniqueAuthors = useCallback(() => {
    if (!changesHistoryData?.data) return []
    const authors = new Set()

    changesHistoryData.data.forEach(statusGroup => {
      statusGroup.changes?.forEach(change => {
        authors.add(change.user.name || change.createdBy)
      })
    })
    return Array.from(authors).sort()
  }, [changesHistoryData])

  // Форматирование значений
  const formatValue = (value, key) => {
    if (value === undefined || value === null) return ''

    // Форматирование телефона
    if (key === 'phone') {
      return formatPhoneNumber(value)
    }

    // Форматирование возраста
    if (key === 'age' && value) {
      return `${value} ${numberDeclension(value, ['год', 'года', 'лет'])}`
    }

    // Форматирование даты рождения
    if (key === 'birthday' && value) {
      return dayjs(value).format('DD.MM.YYYY')
    }

    if (Array.isArray(value)) {
      // Специальная обработка для поля education
      if (key === 'education' && value.length > 0) {
        return value
          .sort((a, b) => (b.to || '').localeCompare(a.to || ''))
          .map(edu => {
            const year = edu.to ? `, ${edu.to}` : ''
            return `${edu.name}${year}${edu.description ? ` | ${edu.description}` : ''}`
          })
          .join('\n')
      }

      // Специальная обработка для поля experience
      if (key === 'experience' && value.length > 0) {
        return value
          .map(exp => {
            const duration =
              exp.from && exp.to
                ? `${dayjs(exp.from).format('MM.YYYY')} - ${dayjs(exp.to).format('MM.YYYY')}`
                : ''
            return `${exp.company} | ${exp.position} | ${duration}${exp.description ? ` | ${exp.description}` : ''}`
          })
          .join('\n')
      }
      return value.join(', ')
    }
    if (typeof value === 'object' && value && value.displayName) return value.displayName
    return String(value)
  }

  /**
   * Извлекает пользовательские поля из массивов old и new и преобразует их в массив полей для отображения
   * @param {Object} diff - Объект с изменениями
   * @param {string} key - Ключ поля
   * @param {Object} change - Объект изменения
   * @returns {Array} - Массив объектов с данными полей
   */
  const extractUserFields = (diff, key, change) => {
    const newValue = diff[key].new
    const oldValue = diff[key].old

    // Если это не массивы пользовательских полей, возвращаем один элемент
    if (key !== 'userFields') {
      const formattedOldValue = formatValue(oldValue, key)
      const formattedNewValue = formatValue(newValue, key)

      let changeType = 'changed'
      if (!oldValue && newValue) changeType = 'added'
      if (oldValue && !newValue) changeType = 'deleted'

      const field = appConfig.candidateFieldDictionary[key] || key

      return [
        {
          key,
          field,
          new: formattedNewValue,
          old: formattedOldValue,
          createdAt: change.createdAt,
          user: change.user?.name || change.createdBy,
          changeType,
          id: `${change.createdAt}_${key}`,
          name: field,
          lastActive: dayjs(change.createdAt).format(appConfig.formats.shortDateAndTime),
          avatar: null
        }
      ]
    }

    // Создаем индексы для старых и новых значений по идентификатору поля
    const oldFieldsMap = {}
    const newFieldsMap = {}

    // Индексируем старые поля
    oldValue?.forEach(item => {
      if (item.field) {
        oldFieldsMap[item.field] = item.value
      }
    })

    // Индексируем новые поля
    newValue?.forEach(item => {
      if (item.field) {
        newFieldsMap[item.field] = item.value
      }
    })

    // Собираем все уникальные ключи полей
    const allFieldKeys = [
      ...new Set([...Object.keys(oldFieldsMap), ...Object.keys(newFieldsMap)])
    ].filter(fieldId => {
      // Если поле существует в userFieldsMap и оно включено
      return !userFieldsMap[fieldId] || userFieldsMap[fieldId].enabled
    })

    // Создаем элемент для каждого пользовательского поля
    return allFieldKeys
      .map(fieldId => {
        const oldFieldValue = oldFieldsMap[fieldId]
        const newFieldValue = newFieldsMap[fieldId]

        // Пропускаем поля, где старое и новое значение совпадают
        if (areValuesEqual(oldFieldValue, newFieldValue)) {
          return null
        }

        // Используем общую функцию форматирования
        const formattedOldValue = formatValue(oldFieldValue, fieldId)
        const formattedNewValue = formatValue(newFieldValue, fieldId)

        // Определяем тип изменения
        let changeType = 'changed'
        if (!oldFieldValue && newFieldValue) changeType = 'added'
        if (oldFieldValue && !newFieldValue) changeType = 'deleted'

        // Получаем имя поля из мапы пользовательских полей
        const fieldName = userFieldsMap[fieldId]?.displayName || `Поле ${fieldId}`

        return {
          key: `userFields.${fieldId}`,
          field: fieldName,
          new: formattedNewValue,
          old: formattedOldValue,
          createdAt: change.createdAt,
          user: change.user?.name || change.createdBy,
          changeType,
          id: `${change.createdAt}_userFields_${fieldId}`,
          name: fieldName,
          email: `${formattedOldValue || '—'} → ${formattedNewValue || '—'}`,
          role: change.user?.name || change.createdBy,
          lastActive: dayjs(change.createdAt).format(appConfig.formats.shortDateAndTime),
          avatar: null
        }
      })
      .filter(Boolean) // Фильтруем null значения
  }

  // Функция для создания уникального списка полей
  const getUniqueFields = useCallback(() => {
    if (!changesHistoryData?.data) return []

    const fields = new Set()

    changesHistoryData.data.forEach(group => {
      group.changes?.forEach(change => {
        // Добавляем обычные поля
        Object.keys(change.diff).forEach(key => {
          if (!['_id', 'createdAt', 'updatedAt'].includes(key)) {
            fields.add(key)
          }
        })

        // Добавляем пользовательские поля
        if (change.diff.userFields) {
          const userFields = change.diff.userFields.new || change.diff.userFields.old || []
          userFields.forEach(field => {
            if (field.field && userFieldsMap[field.field]?.enabled) {
              fields.add(`userFields.${field.field}`)
            }
          })
        }
      })
    })

    return Array.from(fields).sort((a, b) => {
      const getDisplayName = field => {
        if (field.startsWith('userFields.')) {
          const fieldId = field.replace('userFields.', '')
          return userFieldsMap[fieldId]?.displayName || field
        }
        return appConfig.candidateFieldDictionary[field] || field
      }
      return getDisplayName(a).localeCompare(getDisplayName(b))
    })
  }, [changesHistoryData, userFieldsMap])

  // Преобразование данных истории изменений в формат для ChangesHistoryPanel
  const getChangesHistoryPanelData = useCallback(() => {
    if (!changesHistoryData?.data) return []

    return changesHistoryData.data
      .map(statusGroup => {
        // Создаем массив всех изменений в формате, подходящем для ChangesHistoryPanel
        const allMembers =
          statusGroup.changes?.flatMap(change =>
            Object.keys(change.diff)
              .filter(key => !['_id', 'createdAt', 'updatedAt'].includes(key))
              .flatMap(key => extractUserFields(change.diff, key, change))
          ) || []

        // Фильтруем members по выбранному автору и полю
        const filteredMembers = allMembers.filter(member => {
          const matchesAuthor = authorFilter === 'all' || member.user === authorFilter

          const matchesField =
            fieldFilter === 'all' ||
            (fieldFilter === 'userFields' && member.key.startsWith('userFields.')) ||
            member.key === fieldFilter

          return matchesAuthor && matchesField
        })

        // Возвращаем группу с отфильтрованными изменениями в формате для ChangesHistoryPanel
        return {
          id: statusGroup.status,
          name: statusGroup.displayName,
          members: filteredMembers,
          lastUpdated: dayjs(statusGroup.createdAt).format(appConfig.formats.shortDateAndTime),
          count: filteredMembers.length
        }
      })
      .filter(group => group.members.length > 0) // Фильтруем только группы с изменениями
  }, [changesHistoryData, authorFilter, fieldFilter])

  // Получаем данные для ChangesHistoryPanel
  const changesHistoryPanelData = getChangesHistoryPanelData()

  // Фильтрация данных по поисковому запросу
  const filteredChangesHistoryPanelData = useMemo(() => {
    if (!searchText) return changesHistoryPanelData
    return changesHistoryPanelData
      .map(change => {
        const filteredMembers = change.members.filter(member => {
          return (
            member.field.toLowerCase().includes(searchText.toLowerCase()) ||
            (member.old && member.old.toLowerCase().includes(searchText.toLowerCase())) ||
            (member.new && member.new.toLowerCase().includes(searchText.toLowerCase()))
          )
        })
        return {
          ...change,
          members: filteredMembers,
          count: filteredMembers.length
        }
      })
      .filter(change => change.members.length > 0)
  }, [changesHistoryPanelData, searchText])

  return (
    <div className="changes-history">
      <div className="changes-history-container mb-4">
        <Title level={5} style={{ marginBottom: '16px' }}>
          История изменений
        </Title>
        <Row className="mb-2" gutter={[12, 0]}>
          <Col span={8}>
            <Search
              placeholder="Поиск..."
              onSearch={handleSearch}
              onChange={e => handleSearch(e.target.value)}
              className="w-100 search-input"
              prefix={<SearchOutlined style={{ color: '#bfbfbf' }} />}
            />
          </Col>
          <Col span={8}>
            <Select
              className="w-100"
              placeholder="Автор"
              defaultValue="all"
              onChange={handleAuthorFilterChange}
              suffixIcon={<UserOutlined />}
            >
              <Option value="all">Все авторы</Option>
              {getUniqueAuthors().map(author => (
                <Option key={author} value={author}>
                  {author}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              className="w-100"
              placeholder="Поле"
              defaultValue="all"
              onChange={handleFieldFilterChange}
              suffixIcon={<TagsOutlined />}
            >
              <Option value="all">Все поля</Option>
              {getUniqueFields().map(field => (
                <Option key={field} value={field}>
                  {field.startsWith('userFields.')
                    ? userFieldsMap[field.replace('userFields.', '')]?.displayName || field
                    : appConfig.candidateFieldDictionary[field] || field}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        {isChangesHistoryLoading ? (
          <div className="text-center py-4">
            <Spin tip="Загрузка истории изменений..." />
          </div>
        ) : filteredChangesHistoryPanelData.length === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Нет данных по истории изменений"
          />
        ) : (
          <ChangesHistoryPanel changesData={filteredChangesHistoryPanelData} isLoading={false} />
        )}
      </div>
    </div>
  )
}

export default ChangesHistory
