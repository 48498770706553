import React, { useEffect } from 'react'
import { Button, Col, Form, Input, Row, Spin, Typography, message } from 'antd'
import { useAuthJobSite } from '../../../../api/integrations'
import videoIcon from '../../../../img/integrations-video.svg'

const { Title } = Typography

function AuthIntegrations({ getUsers, jobSiteData }) {
  const {
    mutate: mutateAuthJobSite,
    isSuccess: isSuccessAuthJobSite,
    isError: isErrorAuthJobSite,
    isLoading: isLoadingAuthJobSite
  } = useAuthJobSite()

  useEffect(() => {
    if (isSuccessAuthJobSite) {
      getUsers()
    } else if (isErrorAuthJobSite) {
      message.error('Ошибка авторизации')
    }
  }, [getUsers, isSuccessAuthJobSite, isErrorAuthJobSite])

  const handleFinish = values => {
    mutateAuthJobSite({
      jobId: jobSiteData?.id,
      data: {
        credentials: {
          clientId: values.clientId,
          clientSecret: values.clientSecret
        }
      }
    })
  }

  return (
    <Spin spinning={isLoadingAuthJobSite}>
      <Row gutter={[24, 12]} align="top" justify="start" className="AuthIntegrations">
        <Col span={14} className="video-block">
          <img src={videoIcon} alt="video" />
        </Col>
        <Col span={10}>
          <Row align="top">
            <Title level={5}>Интеграция с {jobSiteData?.displayName}</Title>
            <Col className="description">
              Чтобы автоматически загружать отклики в SolvoPro, подключите ваш аккаунт на{' '}
              {jobSiteData?.displayName}. Как настроить интеграцию, смотрите в видеоинструкции.
            </Col>
            <Col>
              <Form name="AuthIntegrations" onFinish={handleFinish} layout="vertical">
                <Form.Item
                  className="justify-center"
                  name="clientId"
                  label="Client Id"
                  rules={[{ required: true, message: 'Пожалуйста введите ваш Client Id!' }]}
                >
                  <Input size="large" placeholder="введите Client Id" />
                </Form.Item>

                <Form.Item
                  className="justify-center"
                  name="clientSecret"
                  label="Client Secret"
                  rules={[{ required: true, message: 'Пожалуйста введите ваш Client Secret!' }]}
                >
                  <Input size="large" placeholder="введите Client Secret" />
                </Form.Item>

                <Form.Item className="justify-center">
                  <Button type="primary" htmlType="submit" block size="large">
                    Дальше
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  )
}

export default AuthIntegrations
