import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
  Typography,
  Modal
} from 'antd'
import { useParams } from 'react-router-dom'
import MaterialIcon from 'material-icons-react'
import { useRecoilValue } from 'recoil'
import { LeftOutlined } from '@ant-design/icons'
import { useQueryClient } from 'react-query'

import { colSettings, commonDrawerWidth, modules } from '../../../../constants'
import { VacancyContext } from '../../../../contexts/vacancyContext'
import { appConfig } from '../../../../constants/appConfig'
import { ConfigContext } from '../../../../contexts/configContext'
import { useMutateUpdateVacancy, useMutateCreateVacancy } from '../../../../api/vacancy'
import { isActiveVacancyText } from '../../../../helpers/vacancy'
import { customPropertiesSettingsAtom } from '../../../../recoil/atoms'
import { useGetLinkedUsersJobSite } from '../../../../api/integrations'
import DemandApprovalRoutes from '../Demand/DemandApprovalRoutes'

const { Option } = Select
const { Text } = Typography
const { confirm } = Modal
const leftLabelCol = { ...colSettings.half, pull: 1, offset: 1 }

function VacancyDrawer({ onClose, drawerClass, vacanciesForResponses }) {
  const queryClient = useQueryClient()
  const { vacancy = {} } = useContext(VacancyContext)
  const {
    workflows,
    features: { data: features }
  } = useContext(ConfigContext)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)
  const workflowList = useMemo(
    () => workflows?.data?.filter(w => w.name !== appConfig.workflows.plan.name),
    [workflows]
  )

  const { id } = useParams()
  const isNewVacancy = useMemo(() => id === 'new', [id])

  const [form] = Form.useForm()
  const [editMode, setEditMode] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [module, setModule] = useState('')

  const { mutate: mutateUpdateVacancy, isLoading: isLoadingUpdateVacancy } =
    useMutateUpdateVacancy(queryClient)

  const { mutate: mutateCreateVacancy, isLoading: isLoadingCreateVacancy } =
    useMutateCreateVacancy(queryClient)

  useEffect(() => {
    if (!id) {
      setEditMode(false)
      setIsActive(false)
    }
  }, [id])

  useEffect(() => {
    if (isNewVacancy) {
      setEditMode(true)
      setIsActive(false)
      setModule(appConfig.modules.crewman)
      form.resetFields()
      form.setFieldsValue({ workflow: workflowList[0]?._id, module: appConfig.modules.crewman })
    }
  }, [form, workflowList, isNewVacancy])

  useEffect(() => {
    if (vacancy?._id) {
      form.setFieldsValue({
        ...vacancy,
        workflow: vacancy?.workflow?._id,
        approvalStages:
          vacancy?.approvalStages.map(stage => {
            stage.approvers = stage.approvers.map(approver => approver.value)
            return stage
          }) || []
      })
      setIsActive(vacancy.active)
      setModule(vacancy.module)
    }
  }, [form, vacancy])

  const { data: dataGetUsersJobSite, isError: isErrorGetUsersJobSite } = useGetLinkedUsersJobSite(
    appConfig.integrationSites.hrm,
    {
      enabled: !!id && features?.includes(appConfig.features.hrm)
    }
  )

  useEffect(() => {
    if (isErrorGetUsersJobSite) {
      message.error('Ошибка получения списка ботов')
    }
  }, [isErrorGetUsersJobSite, dataGetUsersJobSite])
  const jobSiteDataBots = useMemo(() => dataGetUsersJobSite?.data, [dataGetUsersJobSite])
  const errorMsgVacancyWithActiveBot = useMemo(() => {
    if (!id) return null
    const bot = jobSiteDataBots?.find(bot => bot.internal?.vacancy === id)
    return bot
      ? `Вакансия прикреплена к активному боту ${bot?.external?.name} и не может быть отключена.`
      : null
  }, [jobSiteDataBots, id])

  const handleFinish = useCallback(
    values => {
      if (values?.approvalStages) {
        values.approvalStages.forEach(s => {
          // удаляем вспомогательные поля
          delete values[s.name]
        })
        values.approvalStages = values.approvalStages
          .filter(stage => stage.approvers?.length)
          .map(stage => {
            stage.approvers = stage.approvers.map(approver => ({
              type: 'role',
              value: approver
            }))
            return stage
          })
      }
      if (isNewVacancy) {
        const callbackOptions = {
          onSuccess: () => {
            message.success('Вакансия успешно добавлена')
            onClose?.()
          },
          onError: () => message.error('Ошибка добавления вакансии')
        }
        confirm({
          title: 'Добавление новой вакансии',
          content: 'Уведомить пользователей о новой вакансии по email?',
          okText: 'ДА',
          cancelText: 'НЕТ',
          onOk: () => mutateCreateVacancy({ ...values, notify: 1 }, callbackOptions),
          onCancel: () => mutateCreateVacancy(values, callbackOptions)
        })
      } else {
        mutateUpdateVacancy(
          { ...vacancy, ...values },
          {
            onSuccess: () => {
              message.success('Вакансия успешно обновлена')
              onClose?.()
            },
            onError: () => {
              message.error('Ошибка обновления данных вакансии')
            }
          }
        )
      }
    },
    [mutateUpdateVacancy, mutateCreateVacancy, vacancy, isNewVacancy, onClose]
  )

  const handleEditButton = () => {
    setEditMode(true)
    form.setFieldsValue({
      ...vacancy,
      workflow: vacancy?.workflow?._id
    })
  }
  const handleCloseEdit = () => {
    form.setFieldsValue({
      ...vacancy,
      workflow: vacancy?.workflow?._id
    })
    setEditMode(false)
  }

  const vacancyForResponses = useMemo(
    () => vacanciesForResponses?.find(v => v.workflow?.name === vacancy?.workflow?.name),
    [vacanciesForResponses, vacancy]
  )

  const handleChangeForResponses = value => {
    if (value) {
      confirm({
        title: 'Редактирование данных',
        content: (
          <div>
            Данная вакансия будет использована для откликов из работных сайтов. Вакансия{' '}
            <b>{vacancyForResponses?.displayName}</b> перестанет использоваться для этих целей.
          </div>
        ),
        okText: 'ОК',
        cancelText: 'Отмена',
        onCancel: () =>
          form.setFieldsValue({
            forResponses: false
          })
      })
    } else if (vacancyForResponses?._id === id) {
      form.setFieldsValue({
        forResponses: true
      })
      confirm({
        title: 'Редактирование данных',
        content:
          'Необходимо назначить вакансию для откликов из работных сайтов.' +
          ' Пожалуйста, выберите другую вакансию и назначьте ее вакансией для откликов.',
        okText: 'ОК',
        cancelButtonProps: { style: { display: 'none' } }
      })
    }
  }

  const handleChangeModule = module => {
    if (module === appConfig.modules.amp) {
      form.setFieldsValue({
        countInTotal: false,
        countCouriersInTotal: false
      })
    }
    setModule(module)
  }
  const handleChangeStatus = status => {
    if (status) {
      form.setFieldsValue({
        showInLists: true,
        showInForecasting: true,
        countInTotal: module !== appConfig.modules.amp
      })
    } else {
      if (form.getFieldValue('forResponses')) {
        form.setFieldsValue({
          active: true
        })
        return confirm({
          title: 'Редактирование данных',
          content: 'Невозможно сделать неактивной вакансию для откликов',
          okText: 'ОК',
          cancelButtonProps: { style: { display: 'none' } }
        })
      }
      form.setFieldsValue({
        showInLists: false,
        showInForecasting: false
      })
    }
    setIsActive(status)
  }

  return (
    <Drawer
      width={commonDrawerWidth}
      placement="right"
      closable={false}
      onClose={() => onClose?.()}
      open={!!id}
      rootClassName={drawerClass}
      destroyOnClose
    >
      <div className="drawer__wrap">
        <Form form={form} scrollToFirstError className="drawer-content" onFinish={handleFinish}>
          <div>
            <Row justify="space-between">
              <Col>
                <div className="no-gutters block-title">Настройки</div>
              </Col>
              <Col>
                {editMode && !isNewVacancy && (
                  <div className="profile-close" onClick={handleCloseEdit}>
                    <LeftOutlined /> назад
                  </div>
                )}
                {!editMode && (
                  <div className="profile-edit" onClick={handleEditButton}>
                    <MaterialIcon icon="edit" size={24} color="#afafaf" />
                  </div>
                )}
              </Col>
            </Row>

            <Divider rootClassName="small" />

            <Form.Item
              label="Название вакансии"
              labelAlign="left"
              labelCol={editMode ? colSettings.full : leftLabelCol}
              wrapperCol={colSettings.full}
              className="mt-3"
              name="displayName"
              rules={[
                {
                  required: true,
                  message: 'Введите название'
                }
              ]}
            >
              {editMode ? (
                <Input placeholder="Введите название" />
              ) : (
                <Col>
                  <b>{vacancy.displayName}</b>
                </Col>
              )}
            </Form.Item>

            <Form.Item
              label="Процесс найма"
              labelAlign="left"
              labelCol={editMode ? colSettings.full : leftLabelCol}
              wrapperCol={colSettings.full}
              name="workflow"
            >
              {editMode ? (
                <Select size="middle" placeholder="Выберите процесс">
                  {workflowList?.map(c => (
                    <Option key={c._id} value={c._id}>
                      {c.displayName}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Col>
                  <b>{vacancy?.workflow?.displayName}</b>
                </Col>
              )}
            </Form.Item>

            {features.includes(appConfig.features.amp) && (
              <Form.Item
                label="Модуль"
                labelAlign="left"
                labelCol={editMode ? colSettings.full : leftLabelCol}
                wrapperCol={colSettings.full}
                name="module"
              >
                {editMode ? (
                  <Select size="middle" placeholder="Выберите модуль" onChange={handleChangeModule}>
                    {(Object.keys(appConfig?.modules) || [])?.map(c => (
                      <Option key={c} value={c}>
                        {appConfig.modulesLabels[c]}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Col>
                    <b>{appConfig.modulesLabels[vacancy.module]}</b>
                  </Col>
                )}
              </Form.Item>
            )}

            <Row justify="space-between" align="baseline">
              {editMode && <Col className="text-center">Статус:</Col>}
              <Form.Item
                className={!editMode && 'full-width'}
                label={!editMode ? 'Статус' : null}
                labelAlign="left"
                labelCol={!editMode && leftLabelCol}
                wrapperCol={colSettings.full}
                name="active"
                valuePropName="checked"
                rules={[
                  {
                    transform: value => value || undefined,
                    type: 'boolean'
                  }
                ]}
              >
                {editMode ? (
                  <Switch
                    checkedChildren="Активная"
                    unCheckedChildren="Неактивная"
                    onChange={handleChangeStatus}
                    disabled={errorMsgVacancyWithActiveBot}
                  />
                ) : (
                  <Col>
                    {vacancy._id && (
                      <Text type={!vacancy.active && 'danger'}>
                        <b>{isActiveVacancyText(vacancy.active)}</b>
                      </Text>
                    )}
                  </Col>
                )}
              </Form.Item>
            </Row>
            {editMode && <Text type="danger">{errorMsgVacancyWithActiveBot}</Text>}

            <div className="sub-title">Опции</div>
            <Row justify="space-between" align="baseline">
              <Col className="text-center">Вакансия для откликов:</Col>
              <Form.Item
                wrapperCol={colSettings.full}
                name="forResponses"
                valuePropName="checked"
                rules={[
                  {
                    transform: value => value || undefined,
                    type: 'boolean'
                  }
                ]}
              >
                <Switch disabled={!editMode || !isActive} onChange={handleChangeForResponses} />
              </Form.Item>
            </Row>

            <Row justify="space-between" align="baseline">
              <Col>Отображать при создании и редактировании кандидата:</Col>
              <Form.Item
                wrapperCol={colSettings.full}
                name="showInLists"
                valuePropName="checked"
                rules={[
                  {
                    transform: value => value || undefined,
                    type: 'boolean'
                  }
                ]}
              >
                <Switch disabled={!editMode || !isActive} />
              </Form.Item>
            </Row>

            <Row justify="space-between" align="middle">
              <Col span={21} className="vacancy-label-margin">
                Отображать на экране планирования и в отчете о планировании:
              </Col>
              <Form.Item
                wrapperCol={colSettings.full}
                name="showInForecasting"
                valuePropName="checked"
                rules={[
                  {
                    transform: value => value || undefined,
                    type: 'boolean'
                  }
                ]}
              >
                <Switch disabled={!editMode || !isActive} />
              </Form.Item>
            </Row>

            <Row justify="space-between" align="baseline">
              <Col>Суммировать в отчете о планировании:</Col>
              <Form.Item
                wrapperCol={colSettings.full}
                name="countInTotal"
                valuePropName="checked"
                rules={[
                  {
                    transform: value => value || undefined,
                    type: 'boolean'
                  }
                ]}
              >
                <Switch disabled={!editMode || module === appConfig.modules.amp} />
              </Form.Item>
            </Row>

            {moduleName === modules.bk && (
              <Row justify="space-between" align="baseline">
                <Col>Суммировать курьеров для отчета планирования:</Col>
                <Form.Item
                  wrapperCol={colSettings.full}
                  name="countCouriersInTotal"
                  valuePropName="checked"
                  rules={[
                    {
                      transform: value => value || undefined,
                      type: 'boolean'
                    }
                  ]}
                >
                  <Switch disabled={!editMode || module === appConfig.modules.amp} />
                </Form.Item>
              </Row>
            )}

            <DemandApprovalRoutes form={form} editMode={editMode} list={vacancy.approvalStages} />
          </div>

          {editMode && (
            <div className="drawer-footer">
              <Divider className="small" />
              <Row className="buttons">
                <Col>
                  <Button
                    type="link"
                    className="mr-3"
                    onClick={() => onClose?.()}
                    disabled={isLoadingUpdateVacancy || isLoadingCreateVacancy}
                  >
                    Отмена
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoadingUpdateVacancy || isLoadingCreateVacancy}
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Form>
      </div>
    </Drawer>
  )
}
export default VacancyDrawer
