import { Button, Input, Tooltip } from 'antd'
import { Search as SearchIcon } from '@mui/icons-material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useNavigate, useLocation } from 'react-router-dom'

import { findSettingsAtom } from '../../../../recoil/atoms'
import { FIND_CANDIDATES_PAGE } from '../../../../constants/routes'
import { removeSpecSymbols } from '../../../../helpers'

const { Search } = Input
let timeout = null

export default function CommonSearch() {
  const inputRef = useRef(null)
  const [pageBeforeSearch, setPageBeforeSearch] = useState('')
  const [isEdited, setIsEdited] = useState('')
  // const [isInputFocus, setIsInputFocus] = useState(false)

  // const handleFocus = useCallback(() => setIsInputFocus(true), [])
  // const handleBlur = useCallback(() => {
  //   timeout = setTimeout(() => {
  //     setIsInputFocus(false)
  //   }, 150)
  // }, [])

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.input.onfocus = handleFocus
  //     inputRef.current.input.onblur = handleBlur
  //   }
  // }, [inputRef, handleBlur, handleFocus])

  const navigate = useNavigate()
  const location = useLocation()
  const [settings, setSettings] = useRecoilState(findSettingsAtom)
  const [search, setSearch] = useState(settings?.search || '')
  // const { pathname } = useLocation()

  useEffect(() => {
    if (!search && !isEdited && settings.search) {
      setSearch(settings.search)
    }
  }, [search, settings.search, isEdited])

  const setContextSearch = useCallback(
    value => {
      setSettings(oldValues => ({
        ...(oldValues || {}),
        search: value
      }))
      if (value) {
        navigate(FIND_CANDIDATES_PAGE)
      } else if (pageBeforeSearch) {
        navigate(pageBeforeSearch)
      }
      if (FIND_CANDIDATES_PAGE !== location.pathname) {
        setPageBeforeSearch(location.pathname)
      }
    },
    [setSettings, navigate, location, pageBeforeSearch]
  )

  const handleSearch = useCallback(
    value => {
      inputRef.current.input.blur()
      timeout && clearTimeout(timeout)
      // setIsInputFocus(false)
      setContextSearch(removeSpecSymbols(value))
    },
    [setContextSearch]
  )

  // const handleSetGlobalFind = useCallback(() => {
  //   navigate(FIND_CANDIDATES_PAGE)
  // }, [navigate])
  // const isNeedGlobalFind = useMemo(() => pathname?.includes(CANDIDATES_PAGE), [pathname])

  const handleChangeSearch = useCallback(({ target }) => {
    setSearch(target.value)
    setIsEdited(true)
  }, [])

  return (
    <>
      <Search
        ref={inputRef}
        type="tel"
        name="phone"
        size="large"
        placeholder="Глобальный поиск по телефону или ФИО"
        value={search}
        onSearch={handleSearch}
        onChange={handleChangeSearch}
        autoComplete="off"
        enterButton={
          <Button
            className="enterButton"
            disabled={!search}
            icon={
              <Tooltip title="Искать везде">
                {/*<Tooltip title={isNeedGlobalFind ? 'Фильтровать' : 'Искать везде'}>*/}
                <SearchIcon style={{ fontSize: 30, color: '#fff' }} />
              </Tooltip>
            }
            size="small"
          />
        }
        allowClear
      />

      {/*{isNeedGlobalFind && search && isInputFocus && (*/}
      {/*  <Button size="large" onClick={handleSetGlobalFind} className="all-search" type="link">*/}
      {/*    Глобальный поиск*/}
      {/*  </Button>*/}
      {/*)}*/}
    </>
  )
}
