import React, { useEffect, useMemo } from 'react'
import { message, Spin } from 'antd'

import Comments from '../../../components/Common/Comments/Comments'
import {
  useMutateCreateCandidateComment,
  useMutateUpdateCandidateComment,
  useMutateDeleteCandidateComment,
  useQueryCandidateComments
} from '../../../api/comments/candidateComment'

function CandidateComments({ candidate, avatarColSpan = 3 }) {
  const {
    data: commentsData,
    isError: isErrorComments,
    isLoading: isLoadingComments
  } = useQueryCandidateComments({ entity: candidate?._id }, { enabled: !!candidate?._id })

  const comments = useMemo(() => commentsData?.data?.docs || [], [commentsData?.data])

  useEffect(() => {
    if (isErrorComments) {
      message.error('Ошибка загрузки комментариев')
    }
  }, [isErrorComments])

  return (
    <Spin spinning={isLoadingComments}>
      <Comments
        avatarColSpan={avatarColSpan}
        entity={candidate}
        comments={comments}
        onDelete={useMutateDeleteCandidateComment}
        onCreate={useMutateCreateCandidateComment}
        onUpdate={useMutateUpdateCandidateComment}
      />
    </Spin>
  )
}

export default CandidateComments
