import { api } from './api'
import { useQuery } from 'react-query'
import { defaultQueryOptions } from '../constants'

const ROLES_KEY = 'roles'

const fetchRoles = () => {
  return api.get({ path: ROLES_KEY })
}
export function useQueryRoles(options) {
  return useQuery([ROLES_KEY], fetchRoles, { ...defaultQueryOptions, ...options })
}

const rolesQuery = options => ({
  queryKey: [ROLES_KEY],
  queryFn: async () => fetchRoles(),
  ...defaultQueryOptions,
  ...(options || {})
})
export const rolesLoader = async queryClient => {
  const query = rolesQuery()
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}
