import {
  inboxFiltersAtom,
  inboxSorterAtom,
  inboxSettingsAtom,
  workflowSorterAtom,
  workflowSettingsAtom,
  workflowFiltersAtom,
  vacancySorterAtom,
  vacancySettingsAtom,
  vacancyFiltersAtom,
  demandSorterAtom,
  demandSettingsAtom,
  demandFiltersAtom
} from './atoms'
import { selector } from 'recoil'

export const inboxSettingsSelector = selector({
  key: 'allInboxSettings',
  get: ({ get }) => {
    const inboxSettings = get(inboxSettingsAtom)
    const filters = get(inboxFiltersAtom)
    const sorter = get(inboxSorterAtom)

    return {
      ...inboxSettings,
      filters,
      sorter
    }
  }
})

export const demandSettingsSelector = selector({
  key: 'allDemandSettings',
  get: ({ get }) => {
    const demandSettings = get(demandSettingsAtom)
    const filters = get(demandFiltersAtom)
    const sorter = get(demandSorterAtom)

    return {
      ...demandSettings,
      filters,
      sorter
    }
  }
})

export const workflowSettingsSelector = selector({
  key: 'allWorkflowSettings',
  get: ({ get }) => {
    const workflowSettings = get(workflowSettingsAtom)
    const filters = get(workflowFiltersAtom)
    const sorter = get(workflowSorterAtom)

    return {
      ...workflowSettings,
      filters,
      sorter
    }
  }
})

export const vacancySettingsSelector = selector({
  key: 'allVacancySettings',
  get: ({ get }) => {
    const vacancySettings = get(vacancySettingsAtom)
    const filters = get(vacancyFiltersAtom)
    const sorter = get(vacancySorterAtom)

    return {
      ...vacancySettings,
      filters,
      sorter
    }
  }
})
