import React, { useCallback, useState } from 'react'
import { Modal, Row, Col, Form } from 'antd'
import VacanciesItemSelector from '../FormItems/VacanciesItemSelector/VacanciesItemSelector'
import { colSettings } from '../../../../constants'

export default function ReuseModalForm({
  isVisible = false,
  onOk,
  onClose,
  list,
  loading = false
}) {
  const [form] = Form.useForm()
  const [vacancy, setVacancy] = useState('')

  const handleOk = useCallback(() => form.submit(), [form])

  const handleChange = useCallback(value => setVacancy?.(value), [])

  const handleCancel = useCallback(() => onClose?.(), [onClose])

  const handleFinish = useCallback(values => onOk?.(values), [onOk])

  return (
    <Modal
      title="Выбор вакансии кандидата"
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Готово"
      cancelText="Отмена"
      okButtonProps={{ loading, disabled: !vacancy }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Form form={form} onFinish={handleFinish}>
        <Col span={24}>
          <Row span={24} className="mb-3">
            Вакансия неактивна. Пожалуйста, выберите новую вакансию для кандидата из списка:
          </Row>
          <VacanciesItemSelector
            label=""
            required={false}
            vacancyList={list}
            onChange={handleChange}
            wrapperCol={colSettings.half}
          />
        </Col>
      </Form>
    </Modal>
  )
}
