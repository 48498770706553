import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Typography } from 'antd'

import DemandApprovalStage from './DemandApprovalStage'

const { Text } = Typography

export default function DemandApprovalRoutes({ list, form, editMode }) {
  const [stages, setStages] = useState([])
  useEffect(() => {
    if (list) {
      setStages(list)
    }
  }, [list])

  const handleAddStage = useCallback(() => {
    let list = [
      ...(form.getFieldValue('approvalStages') || []),
      { name: 'Этап ' + (stages.length + 1), approvers: [] }
    ]
    setStages(list)
    form.setFieldsValue({ approvalStages: list })
  }, [form, stages])

  return (
    <div className="mb-3">
      <div className="sub-title">Маршрут согласования заявок</div>
      <Form.Item hidden name="approvalStages" />
      {stages?.length ? (
        stages.map(stage => (
          <DemandApprovalStage stage={stage} key={stage.name} form={form} isEdit={editMode} />
        ))
      ) : (
        <Text type="secondary">Не задано</Text>
      )}
      {editMode && (
        <Button
          type="primary"
          block
          ghost
          onClick={handleAddStage}
          size="large"
          className="mt-3 mb-3"
        >
          Добавить этап
        </Button>
      )}
    </div>
  )
}
