import React, { useContext } from 'react'
import { Col, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'

import { defaultTitle, modules } from '../../../../../constants'
import { appConfig } from '../../../../../constants/appConfig'
import { formatPhoneNumber, formatPrefix } from '../../../../../helpers/phone'
import { numberDeclension, getListOfNamesSeparatedByCommas } from '../../../../../helpers'
import { CandidatePanelContext } from '../../../../../contexts/candidatePanelContext'
import { CandidatePreferences, Actions, CurrentSituation, UserFieldsList } from '../../'

import CandidateComments from '../../../../../components/Common/CandidateComments/CandidateComments'
import './CandidateInfo.less'
import { useConfigContextData } from '../../../../../hooks/useConfigContextData'
import telegramIcon from '../../../../../img/telegram-icon.svg'
import watsappIcon from '../../../../../img/watsapp-icon.svg'
import { customPropertiesSettingsAtom } from '../../../../../recoil/atoms'
import { useVacancies } from '../../../../../hooks/useVacancies'

const { Text } = Typography

const CandidateInfo = ({
  // isSearch,
  title,
  wrapperInfoColSpan = 12,
  headerInfo = 'Из резюме кандидата:',
  phonePrefix,
  wrapperInfoSecondaryColSpan = 12,
  headerPreferences,
  onlyView = false,
  loading = false
}) => {
  const { candidate, isAmpCandidate } = useContext(CandidatePanelContext)
  const { departmentType, rolesLabels } = useConfigContextData()
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)
  const { isActiveVacancy } = useVacancies()

  return (
    <>
      <Row className="ant-row candidateInfo" data-title={title}>
        <Col span={wrapperInfoColSpan}>
          <div className="item">
            <b>Вакансия:</b> {candidate.vacancy?.displayName || defaultTitle}
            {candidate.vacancy &&
              !isActiveVacancy(candidate.vacancy?._id || candidate.vacancy) &&
              !loading && (
                <div>
                  <Text type="secondary">
                    <b className="danger-text">Вакансия неактивна. Выберите активную вакансию</b>
                  </Text>
                </div>
              )}
          </div>
          <div className="item">
            <b>Статус кандидата:</b> {candidate.state?.displayName || defaultTitle}
          </div>
          <Text type="secondary">{headerInfo}</Text>
          <div className="item">{candidate.name}</div>
          {candidate.phone && (
            <div className="item">
              <Row>
                <Col>
                  <a
                    href={`tel:${
                      phonePrefix ? candidate.phone.replace('+7', phonePrefix) : candidate.phone
                    }`}
                  >
                    {formatPhoneNumber(candidate.phone)}
                  </a>
                </Col>
                <Col className="social-logo ml-3">
                  <a
                    href={`https://wa.me/${formatPrefix(candidate.phone, '7')}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={watsappIcon} alt="watsapp" />
                  </a>
                </Col>
                <Col className="social-logo ml-3">
                  <a
                    href={`https://t.me/${formatPrefix(candidate.phone, '+7')}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={telegramIcon} alt="telegram" />
                  </a>
                </Col>
              </Row>
            </div>
          )}
          {candidate.email ? (
            <div className="item">
              <a href={`mailto:${candidate.email}`}>{candidate.email}</a>
            </div>
          ) : (
            <div className="item">Email: {defaultTitle}</div>
          )}
          {candidate?.age > 0 && (
            <div className="item">
              Возраст: {candidate.age} {numberDeclension(candidate.age, ['год', 'года', 'лет'])}
            </div>
          )}
          {candidate.birthday && dayjs(candidate.birthday).isValid() && (
            <div className="item">
              Дата рождения: {dayjs(candidate.birthday).format(appConfig.formats.shortDate)}
            </div>
          )}
          <div className="item">Гражданство: {candidate.citizenship || defaultTitle}</div>
          <div className="item">Город: {candidate.city || defaultTitle}</div>
          {moduleName === modules.bk && (
            <div className="item">Ст. метро: {candidate.metro || defaultTitle}</div>
          )}
          <div className="item">Адрес: {candidate.address || defaultTitle}</div>
          {moduleName === modules.bk && (
            <>
              <div className="item">Регион: {candidate.region || defaultTitle}</div>
              <div className="item">Дивизион: {candidate.division || defaultTitle}</div>
            </>
          )}
          <div className="item">Тип обращения: {candidate.requestType || defaultTitle}</div>
          <div className="item">Группа источников: {candidate.utm_medium || defaultTitle}</div>
          <div className="item">Источник: {candidate.utm_source || defaultTitle}</div>
          <div className="item">Utm Campaign: {candidate.utm_campaign || defaultTitle}</div>
          <div className="item">Utm Term: {candidate.utm_term || defaultTitle}</div>
          {candidate.profileUrl && (
            <div className="item white-space-nowrap">
              <a href={candidate.profileUrl} rel="noreferrer noopener" target="_blank">
                {candidate.profileUrl}
              </a>
            </div>
          )}
          <div className="item">
            Ответственный {rolesLabels[appConfig.roles.recruiter]?.toLowerCase()}:{' '}
            {getListOfNamesSeparatedByCommas(candidate.responsible)}
          </div>
        </Col>

        <Col span={wrapperInfoSecondaryColSpan}>
          <CurrentSituation candidate={candidate} />

          <CandidatePreferences candidate={candidate} title={headerPreferences} />
          <UserFieldsList userFields={candidate.userFields} />
          <div>
            <div className="item">&nbsp;</div>
            <div className="item">
              {departmentType} работа: {candidate?.application?.department?.name || defaultTitle}
            </div>
            <div className="item">
              {departmentType} собеседование:{' '}
              {candidate?.application?.interviewerDepartment?.name ||
                candidate?.application?.department?.name ||
                defaultTitle}
            </div>
          </div>
          {isAmpCandidate && (
            <div>
              <div className="item">&nbsp;</div>
              <div className="item">
                Номер заявки: {candidate?.application?.demand?.number || defaultTitle}
              </div>
            </div>
          )}
        </Col>
      </Row>
      {!onlyView && <Actions />}
      {candidate?._id && <CandidateComments candidate={candidate} avatarColSpan={2} />}
    </>
  )
}

export default CandidateInfo
