import React, { useCallback, useContext, useState } from 'react'
import { Col, Row, Typography, Tag, Switch } from 'antd'
import dayjs from 'dayjs'

import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import { appConfig } from '../../../../constants/appConfig'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import ChangesHistory from './ChangesHistory'
import './History.less'

const { Title, Text } = Typography

function History() {
  const { candidate } = useContext(CandidatePanelContext)
  const { getStateWithLabelByWorkflowAndName } = useConfigContextData()

  // Состояние для управления видимостью истории изменений
  const [showChangesHistory, setShowChangesHistory] = useState(false)

  // Обработчик переключателя
  const handleToggleChangesHistory = useCallback(checked => {
    setShowChangesHistory(checked)
  }, [])

  const getDepartmentText = useCallback(item => {
    const { interview } = item
    if (!interview) return ''

    const start = dayjs(interview.start)
      .utcOffset(interview?.timeOffset || dayjs().utcOffset())
      .format(appConfig.formats.shortDateAndTime)
    const end = dayjs(interview.end)
      .utcOffset(interview?.timeOffset || dayjs().utcOffset())
      .format(appConfig.formats.shortTime)

    return (
      <>
        {interview?.departmentName && (
          <>
            {` в `}
            <b>{interview.departmentName} </b>
          </>
        )}
        <span>
          {' '}
          {start} - {end}
        </span>
      </>
    )
  }, [])

  const state = useCallback(
    item =>
      getStateWithLabelByWorkflowAndName({
        stateKey: item?.name,
        workFlowName: candidate?.vacancy?.workflow?.name
      })?.label || item?.name,
    [getStateWithLabelByWorkflowAndName, candidate?.vacancy]
  )

  const renderItem = useCallback(
    item => (
      <Row key={item._id}>
        <Col span={5}>
          <Tag>{dayjs(item.createdAt).format(appConfig.formats.shortDate)}</Tag>
          <Tag>{dayjs(item.createdAt).format(appConfig.formats.shortTime)}</Tag>
        </Col>
        <Col span={19}>
          <Text>
            {state(item)}
            {getDepartmentText(item)}
            {item.rejectReason && `. Причина - ${item.rejectReason}`}
            {item.rejectReason && item.comment && `. Комментарий: "${item.comment}"`}
          </Text>
        </Col>
      </Row>
    ),
    [getDepartmentText, state]
  )

  return (
    <div className="history">
      <div className="toggle-container" onClick={() => setShowChangesHistory(!showChangesHistory)}>
        <Switch
          checked={showChangesHistory}
          onChange={handleToggleChangesHistory}
          className="mr-2"
        />
        <Text className="toggle-label">Показать историю изменений кандидата</Text>
      </div>

      {showChangesHistory ? (
        <ChangesHistory candidateId={candidate?._id} />
      ) : (
        <>
          <Row className="mb-1">
            <Col span={5}>
              <Title level={5}>Дата</Title>
            </Col>
            <Col>
              <Title level={5}>Статус</Title>
            </Col>
          </Row>
          {candidate?.history?.map(renderItem)}
        </>
      )}
    </div>
  )
}

export default History
