export const toolbarList = {
  inDropdown: false,
  className: undefined,
  component: undefined,
  dropdownClassName: undefined,
  options: ['unordered', 'ordered']
}
export const toolbarInline = {
  inDropdown: false,
  className: undefined,
  component: undefined,
  dropdownClassName: undefined,
  options: ['bold', 'italic', 'underline']
}
