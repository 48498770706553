import React from 'react'
import { Col, Row } from 'antd'
import { IntegrationsStatus, IntegrationsUsers } from '../../Components'
import { appConfig } from '../../../../constants/appConfig'
import FailureIntegrations from './FailureIntegrations'
import IntegrationsBots from '../../Components/Integrations/IntegrationsBots'
import TokenIntegrations from './TokenIntegrations'
import IntegrationsAvito from '../../Components/Integrations/IntegrationsAvito'

function IntegrationsContent({ data, onRefresh, jobSiteData }) {
  return (
    <Row gutter={[36, 12]}>
      <Col span={24}>
        <IntegrationsStatus data={data} onRefresh={onRefresh} jobSiteData={jobSiteData} />
        <Row className="integrations-content" justify="center">
          {appConfig.integrationStatuses.online === data.status ? (
            jobSiteData.id === appConfig.integrationSites.hrm ? (
              <IntegrationsBots data={data?.bots} jobSiteData={jobSiteData} />
            ) : jobSiteData.id === appConfig.integrationSites.avito ? (
              <IntegrationsAvito data={data?.vacancies} jobSiteData={jobSiteData} />
            ) : (
              <IntegrationsUsers users={data?.users} jobSiteData={jobSiteData} />
            )
          ) : jobSiteData.id === appConfig.integrationSites.hrm ? (
            <TokenIntegrations getUsers={onRefresh} jobSiteData={jobSiteData} />
          ) : (
            <FailureIntegrations requiredAction={data?.requiredAction} jobSiteData={jobSiteData} />
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default IntegrationsContent
