import React, { useMemo, useEffect, useCallback, useReducer } from 'react'
import { Divider, Spin, Tabs, message } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { useGetJobSites, useGetUsersJobSite } from '../../../../api/integrations'
import { withFeatureToggle } from '../../../../components/Common/FeatureToggle'
import { appConfig } from '../../../../constants/appConfig'
import AuthIntegrations from './AuthIntegrations'
import IntegrationsContent from './IntegrationsContent'
import { simpleReducer } from '../../../../helpers'
import TokenIntegrations from './TokenIntegrations'

const initialState = {
  currentJobSite: null,
  jobSiteDataUsers: {}
}
export const searchParamSite = 'site'

function Integrations() {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [searchParams, setSearchParams] = useSearchParams()
  let site = useMemo(() => searchParams.get(searchParamSite), [searchParams])

  const {
    data: dataGetJobSites,
    isError: isErrorGetJobSites,
    isLoading: isLoadingGetJobSites
  } = useGetJobSites()

  useEffect(() => {
    if (isErrorGetJobSites) {
      message.error('Ошибка получения списка рекрутинговых сайтов')
    }
  }, [isErrorGetJobSites])

  const list = useMemo(() => dataGetJobSites?.data?.list, [dataGetJobSites?.data])

  useEffect(() => {
    let currentJobSite = list?.find(e => e.id === site) || list?.[0]
    setState({ currentJobSite, jobSiteDataUsers: {} })
    currentJobSite && setSearchParams({ [searchParamSite]: currentJobSite?.id })
  }, [list, site, setSearchParams])

  const {
    data: dataGetUsersJobSite,
    isError: isErrorGetUsersJobSite,
    refetch: refetchGetUsersJobSite,
    isLoading: isLoadingGetUsersJobSite
  } = useGetUsersJobSite(state.currentJobSite?.id, {
    enabled: !!state.currentJobSite?.id
  })

  useEffect(() => {
    if (dataGetUsersJobSite?.data && site) {
      setState({ jobSiteDataUsers: dataGetUsersJobSite.data })
    }
  }, [dataGetUsersJobSite, site])

  useEffect(() => {
    if (isErrorGetUsersJobSite) {
      message.error('Ошибка получения списка рекрутинговых пользователей')
    }
  }, [isErrorGetUsersJobSite, dataGetUsersJobSite])

  const handleTabClick = jobSiteId => {
    let currentJobSite = list?.find(jobSite => jobSite.id === jobSiteId)
    setState({ currentJobSite, jobSiteDataUsers: {} })
    setSearchParams({ [searchParamSite]: currentJobSite?.id })
  }

  const switchContent = useCallback(
    jobSiteData => {
      switch (state.jobSiteDataUsers.status) {
        case appConfig.integrationStatuses.none:
          if (site === appConfig.integrationSites.hrm)
            return <TokenIntegrations getUsers={refetchGetUsersJobSite} jobSiteData={jobSiteData} />
          return <AuthIntegrations getUsers={refetchGetUsersJobSite} jobSiteData={jobSiteData} />
        default:
          return (
            <IntegrationsContent
              data={state.jobSiteDataUsers}
              onRefresh={refetchGetUsersJobSite}
              jobSiteData={jobSiteData}
            />
          )
      }
    },
    [state.jobSiteDataUsers, refetchGetUsersJobSite, site]
  )
  const tabsItems = useMemo(
    () =>
      list?.map(item => ({
        key: item?.id,
        label: item?.displayName,
        children: <Spin spinning={isLoadingGetUsersJobSite}>{switchContent(item)}</Spin>
      })),
    [list, isLoadingGetUsersJobSite, switchContent]
  )

  return (
    <div className="main__wrap integrations">
      <div className="block-title">Интеграция с работными сайтами</div>
      <Divider className="middle" />
      {isLoadingGetJobSites ? (
        <Spin spinning className="full-screen-spin" />
      ) : (
        <div className="content card-container">
          {list?.length > 0 && (
            <Tabs
              type="card"
              onTabClick={handleTabClick}
              activeKey={state.currentJobSite?.id}
              items={tabsItems}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default withFeatureToggle(Integrations, appConfig.features.jobSites)
