import React, { useCallback, useMemo } from 'react'
import { Button, Col, Row, Table } from 'antd'
import { sortBy, upperFirst } from '../../../../helpers'
import { useVacancies } from '../../../../hooks/useVacancies'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { renderUserFieldValue } from '../../../../helpers/userFields'

const defaultSize = {
  number: 150,
  vacancy: 200,
  employeeNumber: 70,
  action: 100
}
export default function DemandNeedTableAMP({
  department,
  size = 'middle',
  columnsSize = defaultSize,
  onAction
}) {
  const { isLoadingVacancies } = useVacancies({
    showInForecasting: true
  })

  const dataSource = useMemo(() => department?.demands, [department?.demands])

  const handleAssign = useCallback(
    item => onAction(department, { demandId: item._id }),
    [onAction, department]
  )

  const columns = [
    {
      title: 'Номер заявки',
      dataIndex: 'number',
      width: columnsSize.number
    },
    {
      title: 'Вакансия',
      dataIndex: ['vacancy', 'displayName'],
      width: columnsSize.vacancy,
      render: text => upperFirst(text)
    },
    {
      title: 'Количество',
      dataIndex: 'employeeNumber',
      align: 'center',
      width: columnsSize.employeeNumber
    },
    {
      title: '',
      dataIndex: '_id',
      width: columnsSize.action,
      render: (text, item) => (
        <Button type="link" onClick={() => handleAssign(item)}>
          Назначить
        </Button>
      )
    }
  ]

  return (
    <Table
      rowKey="_id"
      loading={isLoadingVacancies}
      columns={columns}
      dataSource={dataSource}
      bordered
      pagination={false}
      size={size}
      expandable={{
        expandedRowRender: record => (
          <div
            style={{
              margin: '0 -4px -16px -8px',
              padding: 0
            }}
            className="bg-white"
          >
            {record?.userFields
              ?.filter(item => item.field?.enable)
              ?.sort(sortBy('field.sortOrder'))
              ?.map(item => (
                <Row key={item._id} className="mb-3">
                  <Col offset={2} span={6}>
                    {item.field?.displayName}
                  </Col>
                  <Col span={16}>{renderUserFieldValue(item)}</Col>
                </Row>
              ))}
          </div>
        ),
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <DownOutlined onClick={e => onExpand(record, e)} />
          ) : (
            <RightOutlined onClick={e => onExpand(record, e)} />
          )
      }}
    />
  )
}
