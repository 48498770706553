import { api } from './api'
import { useMutation, useQuery } from 'react-query'

export const DEMANDS_KEY = 'demands'
export const DEMANDS_FILTER_CUSTOMERS_KEY = 'demandsFilterCustomers'
export const DEMANDS_FILTER_RECRUITERS_KEY = 'demandsFilterRecruiters'

const fetchGetDemandById = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  if (!id) return null
  return api.get({ path: DEMANDS_KEY + '/' + id })
}
export function useDemandQuery(id, options) {
  return useQuery([DEMANDS_KEY, id], fetchGetDemandById, options)
}

const fetchCreateDemand = data => {
  return api.put({ path: DEMANDS_KEY, data })
}
export const fetchUpdateDemand = ({ data, id }) => {
  return api.patch({ path: `${DEMANDS_KEY}/${id}`, data })
}
export function useMutateCreateDemand(queryClient) {
  return useMutation(fetchCreateDemand, {
    onSuccess: () => {
      queryClient.refetchQueries(DEMANDS_KEY)
    }
  })
}
export function useMutateUpdateDemand(queryClient) {
  return useMutation(fetchUpdateDemand, {
    onSuccess: () => {
      queryClient.refetchQueries(DEMANDS_KEY)
    }
  })
}
const getDemands = ({ queryKey }) => {
  const [, /* key */ { pagination, filters }] = queryKey
  let data = {}
  if (pagination) {
    data.limit = pagination.pageSize
    data.page = pagination.current
  }

  if (filters) {
    data = { ...data, ...filters }
  }

  return api.post({ path: DEMANDS_KEY, data })
}

export function useDemands(data = {}, options) {
  return useQuery([DEMANDS_KEY, data], getDemands, options)
}

const getDemandsFilterCustomers = ({ queryKey }) => {
  const [, /* key */ { filters }] = queryKey
  let data = filters || {}

  if (filters) {
    data = { ...data, ...filters }
  }

  return api.post({ path: DEMANDS_KEY + '/customers', data })
}

export function useDemandsFilterCustomers(data = {}, options) {
  return useQuery([DEMANDS_FILTER_CUSTOMERS_KEY, data], getDemandsFilterCustomers, options)
}

const getDemandsFilterRecruiters = ({ queryKey }) => {
  const [, /* key */ { filters }] = queryKey
  let data = filters || {}

  if (filters) {
    data = { ...data, ...filters }
  }

  return api.post({ path: DEMANDS_KEY + '/recruiters', data })
}

export function useDemandsFilterRecruiters(data = {}, options) {
  return useQuery([DEMANDS_FILTER_RECRUITERS_KEY, data], getDemandsFilterRecruiters, options)
}

const fetchGetDemandHistory = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  if (!id) return null
  return api.get({ path: `${DEMANDS_KEY}/${id}/history` })
}

/**
 * Хук для получения истории изменений заявки
 * @param {string} id - ID заявки
 * @param {Object} options - Опции для useQuery
 */
export function useDemandHistoryQuery(id, options) {
  return useQuery(['demandHistory', id], fetchGetDemandHistory, options)
}
