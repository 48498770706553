import React, { useEffect } from 'react'
import { Button, Col, message, Row } from 'antd'
import { useActionAuth } from '../../../../api/integrations'

function FailureIntegrations({ requiredAction, jobSiteData }) {
  const {
    mutate: mutateActionAuth,
    isError: isErrorActionAuth,
    isLoading: isLoadingActionAuth
  } = useActionAuth()

  useEffect(() => {
    if (isErrorActionAuth) {
      message.error('Ошибка получения ссылки авторизации')
    }
  }, [isErrorActionAuth])

  const handleAuth = () => {
    mutateActionAuth(
      { jobId: jobSiteData?.id, data: { returnTo: window.location.href } },
      {
        onSuccess: data => {
          window.location.href = data.data?.url
        }
      }
    )
  }

  return (
    <Col span={24} className="mt-3">
      <Row className="flex-column mt-3" gutter={[24, 12]} align="flex-start" justify="center">
        {/*<Title level={5}>Интеграция с {jobSiteData?.displayName}</Title>*/}
        <Col className="description">{requiredAction?.message}</Col>
        {requiredAction?.type === 'authorize' && (
          <Col>
            <Button type="primary" onClick={handleAuth} size="large" loading={isLoadingActionAuth}>
              Авторизоваться
            </Button>
          </Col>
        )}
      </Row>
    </Col>
  )
}

export default FailureIntegrations
