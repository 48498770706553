import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material'
import { Tabs, message, Spin, Typography, Col, Row } from 'antd'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'

import './CandidatePanel.less'
import {
  CandidateInfo,
  CandidateProfileEdit,
  History,
  Resume,
  OrisForm,
  ChatWithBot
} from '../index'
import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import { useCandidateByIdQuery } from '../../../../api/candidate'
import { UserContext } from '../../../../contexts/userContext'
import {
  customPropertiesSettingsAtom,
  findSettingsAtom,
  hiringManagerSettingsAtom,
  inboxSettingsAtom
} from '../../../../recoil/atoms'
import { inboxSettingsSelector } from '../../../../recoil/selectors'
import { modules } from '../../../../constants'
import { isCandidateForCheckupTab } from '../../../../helpers/checkup'
import { appConfig } from '../../../../constants/appConfig'
import { ConfigContext } from '../../../../contexts/configContext'

const { Text } = Typography

const CandidatePanel = ({
  candidateId,
  onlyView = false,
  withOris,
  candidateInfo,
  setIsClosableDrawer,
  onClose
}) => {
  const { isRecruiter, isHiringManager, user, isAdmin, isTU, isPartner } = useContext(UserContext)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)
  const setInboxSettings = useSetRecoilState(inboxSettingsAtom)
  const inboxSettings = useRecoilValue(inboxSettingsSelector)
  const [disableOris, setDisableOris] = useState(true)
  const {
    features: { data: features }
  } = useContext(ConfigContext)
  const [hiringManagerSettings, setHiringManagerSettings] =
    useRecoilState(hiringManagerSettingsAtom)
  const [findSettings, setFindSettings] = useRecoilState(findSettingsAtom)
  const [settings, setSettings] = useMemo(
    () =>
      candidateInfo.isSearch
        ? [findSettings, setFindSettings]
        : isRecruiter
          ? [inboxSettings, setInboxSettings]
          : [hiringManagerSettings, setHiringManagerSettings],
    [
      inboxSettings,
      setInboxSettings,
      hiringManagerSettings,
      setHiringManagerSettings,
      isRecruiter,
      findSettings,
      setFindSettings,
      candidateInfo
    ]
  )
  const [editMode, setEditMode] = useState(false)
  const [candidate, setCandidate] = useState({})
  const [activeTabNum, setActiveTabNum] = useState('0')
  const handleEditButton = useCallback(() => setEditMode(!editMode), [editMode])
  const handleCloseEdit = useCallback(() => setEditMode(false), [])
  const handleTabClick = useCallback(key => setActiveTabNum(key), [])
  /**
   * Get Candidate by id
   */
  const {
    status,
    data,
    isLoading,
    refetch: refetchCandidateData
  } = useCandidateByIdQuery(candidateId, { enabled: !!candidateId })

  useEffect(() => {
    if (status === 'success') {
      setCandidate?.(data?.data)
      if (!settings?.activeTab) {
        setSettings(oldValues => ({
          ...(oldValues || {}),
          activeTab: data?.data?.state?.displayName,
          ...(candidateInfo.isSearch ? { search: data?.data?.phone } : {})
        }))
      }
    }
  }, [settings?.activeTab, setSettings, data, status, candidateInfo])
  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (status === 'error') {
      message.error('Кандидат не найден')
    }
  }, [status])

  const handleSaveEdit = useCallback(() => {
    refetchCandidateData()
    setEditMode(false)
  }, [refetchCandidateData])

  useEffect(() => {
    // Закладка медосмотра должна быть доступна только для определенных ролей и статусов кандидата
    setDisableOris((isRecruiter || isHiringManager) && !isCandidateForCheckupTab(candidate))
  }, [candidate, isRecruiter, isHiringManager])

  const isAmpCandidate = useMemo(
    () => candidate?.vacancy?.module === appConfig.modules.amp,
    [candidate]
  )
  const isCrewmanCandidate = useMemo(
    () => candidate?.vacancy?.module === appConfig.modules.crewman,
    [candidate]
  )
  const isHiringManagerFindHisCandidate = useMemo(
    () => candidate?.application?.department?._id === user?.department?._id,
    [candidate?.application, user?.department]
  )

  const showOrisTab = useMemo(
    () =>
      moduleName === modules.bk &&
      (withOris ||
        isHiringManagerFindHisCandidate ||
        (candidateInfo?.isSearch && (isTU || isPartner || isAdmin || isRecruiter))),
    [
      isTU,
      isPartner,
      isAdmin,
      isRecruiter,
      withOris,
      isHiringManagerFindHisCandidate,
      moduleName,
      candidateInfo?.isSearch
    ]
  )
  const tabsItems = useMemo(
    () =>
      [
        {
          key: '0',
          label: 'Персональные данные',
          children: <CandidateInfo {...candidateInfo} loading={isLoading} />
        },
        {
          key: '3',
          label: 'Направление на Мед осмотр',
          hide: !showOrisTab,
          className: '',
          disabled: disableOris,
          children: <OrisForm />
        },
        {
          key: '1',
          label: 'Резюме',
          hide: !(candidate?.experience?.length > 0 || moduleName === modules.bk),
          children: <Resume />
        },
        {
          key: '2',
          label: 'История кандидата',
          children: <History />
        },
        {
          key: '4',
          label: 'Чат с ботом',
          children: <ChatWithBot />,
          hide: !features?.includes(appConfig.features.hrm)
        }
      ].filter(e => !e.hide),
    [candidate, disableOris, moduleName, showOrisTab, candidateInfo, isLoading, features]
  )

  return (
    <CandidatePanelContext.Provider
      value={{
        candidate,
        setCandidate,
        showPanelTab: setActiveTabNum,
        isAmpCandidate,
        isCrewmanCandidate,
        setIsClosableDrawer
      }}
    >
      <div className="drawer__wrap">
        <Row justify="space-between">
          <Col>
            <Row align="middle">
              <div
                className="profile-close"
                onClick={() => {
                  onClose?.()
                }}
              >
                <CloseIcon style={{ fontSize: 24, color: '#afafaf' }} />
              </div>
              <div className="block-title">
                <Col>АНКЕТА КАНДИДАТА</Col>
              </div>
            </Row>
          </Col>

          {!onlyView && (
            <Col>
              {/* {status !== 'error' && editMode && (
                <div className="profile-edit-close" onClick={handleCloseEdit}>
                  <CloseIcon style={{ fontSize: 24, color: '#afafaf' }} />
                </div>
              )} */}
              {!editMode && candidate?.canEdit && (
                <div className="profile-edit" onClick={handleEditButton}>
                  <EditIcon style={{ fontSize: 24, color: '#afafaf' }} />
                </div>
              )}
            </Col>
          )}
        </Row>
        {status === 'error' ? (
          <Text>К сожалению, кандидат не найден</Text>
        ) : (
          <Spin spinning={isLoading} size="large">
            {editMode ? (
              <CandidateProfileEdit onClose={handleCloseEdit} onSave={handleSaveEdit} />
            ) : (
              <Tabs
                defaultActiveKey="0"
                activeKey={activeTabNum}
                onTabClick={handleTabClick}
                items={tabsItems}
              />
            )}
          </Spin>
        )}
      </div>
    </CandidatePanelContext.Provider>
  )
}

export default CandidatePanel
