import { useMutation, useQuery } from 'react-query'
// import { APPOINTMENTS_KEY } from '../appointment'
// import { CANDIDATE_KEY, CANDIDATES_KEY, CANDIDATES_SEARCH_KEY } from '../candidate'
// import { AMP_KEY } from '../amp'
import {
  COMMENTS_KEY,
  deleteComment,
  getComments,
  onSuccessMutateComment,
  patchComment,
  putComment
} from './comments'

export function useQueryCandidateComments(params, options) {
  return useQuery([COMMENTS_KEY, params], getComments, options)
}

const putCandidateComment = ({ id, data }) => {
  return putComment({
    ...data,
    contentType: 'candidate',
    entity: id
  })
}

const deleteCandidateComment = ({ commentId }) => {
  return deleteComment(commentId)
}

const patchCandidateComment = ({ commentId: id, data }) => {
  return patchComment({ id, data })
}

// const onSuccessMutateCandidateComment = queryClient => (data, variables) => {
//   data?.data?._id && queryClient.setQueryData([CANDIDATE_KEY, variables.id], data)
//   queryClient.refetchQueries(CANDIDATE_KEY, { active: true })
//   queryClient.refetchQueries(CANDIDATES_KEY)
//   queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
//   queryClient.refetchQueries(APPOINTMENTS_KEY, { active: true })
//   queryClient.invalidateQueries(AMP_KEY)
//   queryClient.refetchQueries(AMP_KEY, { active: true })
// }

export function useMutateCreateCandidateComment(queryClient) {
  return useMutation(putCandidateComment, {
    onSuccess: onSuccessMutateComment(queryClient)
  })
}

export function useMutateDeleteCandidateComment(queryClient) {
  return useMutation(deleteCandidateComment, {
    onSuccess: onSuccessMutateComment(queryClient)
  })
}

export function useMutateUpdateCandidateComment(queryClient) {
  return useMutation(patchCandidateComment, {
    onSuccess: onSuccessMutateComment(queryClient)
  })
}
