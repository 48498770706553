import React, { useState, useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { Button, Result } from 'antd'

/**
 * Компонент для обработки ошибок в React приложении
 * Перехватывает ошибки рендеринга и отображает fallback UI
 */
export const ErrorBoundary = ({ children, fallback }) => {
  const [error, setError] = useState(null)

  const handleError = useCallback((error, errorInfo) => {
    console.error('Uncaught error:', error, errorInfo)

    const sentryExtras = {
      componentStack: errorInfo.componentStack
    }

    Sentry.captureException(error, { contexts: { react: sentryExtras } })
    setError(error)
  }, [])

  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])

  const handleReset = useCallback(() => {
    setError(null)
  }, [])

  if (error) {
    if (fallback) {
      return fallback
    }

    return (
      <Result
        status="error"
        title="Что-то пошло не так"
        subTitle={error?.message || 'Произошла ошибка в приложении'}
        extra={[
          <Button key="reload" type="primary" onClick={handleReload}>
            Перезагрузить страницу
          </Button>,
          <Button key="retry" onClick={handleReset}>
            Попробовать снова
          </Button>
        ]}
      />
    )
  }

  return (
    <Sentry.ErrorBoundary fallback={fallback} onError={handleError}>
      {children}
    </Sentry.ErrorBoundary>
  )
}
