import {
  Table,
  message,
  Spin,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Input,
  Checkbox,
  Switch
} from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { debounce } from '../../../../helpers'

import { appConfig } from '../../../../constants/appConfig'
import { emptyTitle } from '../../../../constants'
import { useUsers } from '../../../../api/users'
import { useLinkVacanciesJobSite } from '../../../../api/integrations'
import { selectSearchFilter } from '../../../../helpers'
import { useVacancies } from '../../../../hooks/useVacancies'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import IntegrationsMassUpdatePopup from './IntegrationsMassUpdatePopup'

// const { confirm } = Modal
const { Text } = Typography
const { Option } = Select
const { Search } = Input

/**
 * Компонент для интеграции с Avito, отображающий список вакансий и позволяющий их связывать с системными вакансиями.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Array} props.data - Данные о вакансиях Avito.
 * @param {Object} props.jobSiteData - Данные об интеграции с Avito.
 * @returns {JSX.Element} - Отрисованный компонент.
 */
function IntegrationsAvito({ data, jobSiteData }) {
  const queryClient = useQueryClient()
  const [avitoVacancies, setAvitoVacancies] = useState([]) // Состояние для хранения вакансий Avito
  const [errors, setErrors] = useState({}) // Состояние для хранения ошибок валидации
  const [selectedRowData, setSelectedRowData] = useState([]) // Состояние для хранения выбранных строк
  const [currentPage, setCurrentPage] = useState(1) // Текущая страница для пагинации
  const [pageSize, setPageSize] = useState(10) // Размер страницы для пагинации
  const [searchQuery, setSearchQuery] = useState('') // Запрос для поиска вакансий
  const { rolesLabels } = useConfigContextData() // Получение меток ролей из контекста
  const [showUnconfigured, setShowUnconfigured] = useState(false) // Флаг для отображения ненастроенных вакансий
  const [isPopupVisible, setIsPopupVisible] = useState(false) // Флаг для отображения попапа массового обновления
  const [selectedMassUpdateVacancy, setSelectedMassUpdateVacancy] = useState(null) // Выбранная вакансия для массового обновления
  const [selectedMassUpdateRecruiter, setSelectedMassUpdateRecruiter] = useState(null) // Выбранный рекрутер для массового обновления

  useEffect(() => {
    // Установка вакансий Avito при изменении входных данных
    setAvitoVacancies(structuredClone(data))
  }, [data])

  // Фильтрация вакансий на основе состояния и поискового запроса
  const filteredVacancies = useMemo(() => {
    let vacancies = avitoVacancies // Копируем вакансии Avito
    if (showUnconfigured) {
      // Фильтрация вакансий, которые не настроены
      vacancies = vacancies.filter(
        vacancy => !vacancy.internal?.vacancyId && !vacancy.internal?.userId
      )
    }
    if (!searchQuery) return vacancies // Если нет запроса на поиск, возвращаем все вакансии
    const searchTerms = searchQuery.toLowerCase().split(/\s+/).filter(Boolean) // Разделяем запрос на отдельные термины
    return vacancies.filter(vacancy => {
      const title = vacancy.external?.title?.toLowerCase() || '' // Получаем название вакансии
      const address = vacancy.external?.address?.toLowerCase() || '' // Получаем адрес вакансии
      // Проверяем, содержат ли все термины название или адрес вакансии
      return searchTerms.every(term => title.includes(term) || address.includes(term))
    })
  }, [avitoVacancies, searchQuery, showUnconfigured])

  // Общее количество найденных вакансий
  const totalFound = useMemo(() => filteredVacancies.length, [filteredVacancies]) // Общее количество найденных вакансий

  // Получение системных вакансий для отображения
  const {
    vacancies: systemVacancies,
    isErrorVacancies,
    isLoadingVacancies
  } = useVacancies({
    showInLists: true
  })

  // Обработка ошибки загрузки вакансий
  useEffect(() => {
    if (isErrorVacancies) {
      message.error('Ошибка загрузки данных для вакансий')
    }
  }, [isErrorVacancies])

  // Получение активных рекрутеров
  const {
    data: dataRecruiters,
    isError: isErrorRecruiters,
    isLoading: isLoadingRecruiters
  } = useUsers({
    filters: { roles: [appConfig.roles.recruiter], active: true }
  })

  // Список рекрутеров
  const recruiterList = useMemo(() => dataRecruiters?.data || [], [dataRecruiters?.data])

  // Обработка ошибки загрузки рекрутеров
  useEffect(() => {
    if (isErrorRecruiters) {
      message.error('Ошибка загрузки данных о рекрутерах')
    }
  }, [isErrorRecruiters])

  // Хук для связывания системных вакансий с вакансиями с сайта Avito
  const {
    mutate: mutateLinkVacanciesJobSite,
    isSuccess: isSuccessLinkVacanciesJobSite,
    isError: isErrorLinkVacanciesJobSite,
    isLoading: isLoadingLinkVacanciesJobSite
  } = useLinkVacanciesJobSite(jobSiteData?.id, queryClient)

  // Обработка ошибки/успеха привязки вакансий
  useEffect(() => {
    if (isErrorLinkVacanciesJobSite) {
      message.error('Ошибка привязки вакансий')
    } else if (isSuccessLinkVacanciesJobSite) {
      message.success('Вакансии успешно привязаны')
    }
  }, [isSuccessLinkVacanciesJobSite, isErrorLinkVacanciesJobSite])

  /**
   * Обработчик для очистки вакансии.
   * Удаляет связь вакансии с системной вакансией.
   *
   * @param {Object} item - Вакансия, которую нужно очистить.
   */
  const handleClearVacancy = useCallback(
    item => {
      let currentVacancy = avitoVacancies.find(
        vacancy => vacancy?.external?.id === item?.external?.id
      )
      currentVacancy.internal.vacancyId = undefined // Удаляем ID системной вакансии
      setAvitoVacancies([...avitoVacancies]) // Обновляем состояние вакансий
    },
    [avitoVacancies]
  )

  /**
   * Обработчик для очистки рекрутера.
   * Удаляет связь вакансии с рекрутером.
   *
   * @param {Object} item - Вакансия, для которой нужно очистить рекрутера.
   */
  const handleClearUser = useCallback(
    item => {
      let currentVacancy = avitoVacancies.find(
        vacancy => vacancy?.external?.id === item?.external?.id
      )
      currentVacancy.internal.userId = undefined // Удаляем ID рекрутера
      setAvitoVacancies([...avitoVacancies]) // Обновляем состояние вакансий
    },
    [avitoVacancies]
  )

  /**
   * Обработчик для выбора системной вакансии.
   * Устанавливает связь между вакансией Avito и системной вакансией.
   *
   * @param {string} value - ID выбранной системной вакансии.
   * @param {Object} item - Вакансия, для которой устанавливается связь.
   */
  const handleSelectVacancy = useCallback(
    (value, item) => {
      let currentVacancy = avitoVacancies.find(
        vacancy => vacancy?.external?.id === item?.external?.id
      )
      currentVacancy.internal = currentVacancy.internal || {}
      currentVacancy.internal.vacancyId = value // Устанавливаем ID системной вакансии
      setAvitoVacancies([...avitoVacancies]) // Обновляем состояние вакансий
    },
    [avitoVacancies]
  )

  /**
   * Обработчик для выбора рекрутера.
   * Устанавливает связь между вакансией Avito и рекрутером.
   *
   * @param {string} value - ID выбранного рекрутера.
   * @param {Object} item - Вакансия, для которой устанавливается связь.
   */
  const handleSelectUser = useCallback(
    (value, item) => {
      let currentVacancy = avitoVacancies.find(
        vacancy => vacancy?.external?.id === item?.external?.id
      )
      currentVacancy.internal = currentVacancy.internal || {}
      currentVacancy.internal.userId = value // Устанавливаем ID рекрутера
      setAvitoVacancies([...avitoVacancies]) // Обновляем состояние вакансий
    },
    [avitoVacancies]
  )

  /**
   * Обработчик для отмены изменений.
   * Сбрасывает состояние вакансий и ошибок.
   */
  const handleCancel = useCallback(() => {
    setAvitoVacancies(structuredClone(data)) // Сбрасываем вакансии к исходным данным
    setErrors({}) // Очищаем ошибки
  }, [data])

  /**
   * Обработчик для выбора строки.
   * Обновляет состояние выбранных строк.
   *
   * @param {Object} record - Запись, которая была выбрана или снята с выбора.
   * @param {boolean} selected - Флаг, указывающий, выбрана ли запись.
   */
  const handleSelect = useCallback((record, selected) => {
    if (selected) {
      setSelectedRowData(prev => [...prev, record.external.id]) // Добавляем ID выбранной записи
    } else {
      setSelectedRowData(prev => [...prev.filter(id => id !== record.external.id)]) // Удаляем ID снятой с выбора записи
    }
  }, [])

  /**
   * Обработчик для выбора всех строк.
   * Выбирает или снимает выбор со всех строк в зависимости от текущего состояния.
   */
  const handleSelectAll = useCallback(
    () =>
      setSelectedRowData(keys =>
        keys.length === filteredVacancies.length ? [] : filteredVacancies.map(r => r.external.id)
      ),
    [filteredVacancies]
  )

  // Заголовок для выбора всех строк
  const headerCheckbox = useMemo(
    () => (
      <Checkbox
        checked={selectedRowData.length}
        indeterminate={
          selectedRowData.length > 0 && selectedRowData.length < filteredVacancies.length
        }
        onChange={handleSelectAll}
      />
    ),
    [selectedRowData, filteredVacancies, handleSelectAll]
  )

  // Выбор строк
  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedRowData,
      onSelect: handleSelect,
      type: 'checkbox',
      fixed: true,
      columnTitle: headerCheckbox,
      preserveSelectedRowKeys: true,
      getCheckboxProps: record => ({
        disabled: false,
        name: record.external.id
      })
    }),
    [selectedRowData, handleSelect, headerCheckbox]
  )

  const columns = [
    {
      title: 'Размещенная вакансия',
      dataIndex: 'external',
      render: text => {
        if (!text) return emptyTitle
        return (
          <Col>
            <Row>
              <Text>{text.title}</Text>
            </Row>
            <Row>
              <Text type="secondary">{text.address}</Text>
            </Row>
          </Col>
        )
      }
    },
    {
      title: 'Системная вакансия',
      dataIndex: ['internal', 'vacancyId'],
      render: (vacancyId, item) => (
        <div className="select-with-error">
          <Select
            onSelect={id => handleSelectVacancy(id, item)}
            value={vacancyId}
            className={`integrations-selector ${
              errors?.[item.external.id] && !errors?.[item.external.id]?.vacancyId && 'error'
            }`}
            placeholder="Системная вакансия"
            showSearch
            optionFilterProp="children"
            filterOption={selectSearchFilter}
            allowClear
            onClear={() => handleClearVacancy(item)} // Обработчик очистки системной вакансии
          >
            {systemVacancies?.map(vacancy => (
              <Option key={vacancy?._id} value={vacancy?._id}>
                {vacancy?.displayName}
              </Option>
            ))}
          </Select>
          {errors?.[item.external.id] && !errors?.[item.external.id]?.vacancyId && (
            <Text type="danger">поле обязательно</Text>
          )}
        </div>
      )
    },
    {
      title: `Ответственный ${rolesLabels[appConfig.roles.recruiter].toLowerCase()}`,
      dataIndex: ['internal', 'userId'],
      render: (userId, item) => (
        <div className="select-with-error">
          <Select
            value={userId}
            onSelect={id => handleSelectUser(id, item)}
            placeholder={`Ответственный ${rolesLabels[appConfig.roles.recruiter].toLowerCase()}`}
            showSearch
            className={`integrations-selector ${
              errors?.[item.external.id] && !errors?.[item.external.id]?.userId && 'error'
            }`}
            optionFilterProp="children"
            filterOption={selectSearchFilter}
            allowClear
            onClear={() => handleClearUser(item)} // Обработчик очистки рекрутера
          >
            {recruiterList?.map(user => (
              <Option key={user._id} value={user._id}>
                {user.name}
              </Option>
            ))}
          </Select>
          {errors?.[item.external.id] && !errors?.[item.external.id]?.userId && (
            <Text type="danger">поле обязательно</Text>
          )}
        </div>
      )
    }
  ]

  /**
   * Обработчик для открытия попапа массового обновления.
   */
  const handleEditSelected = () => {
    setIsPopupVisible(true)
  }

  /**
   * Обработчик для массового обновления вакансий.
   * Обновляет выбранные вакансии с новыми данными.
   */
  const handleMassUpdate = () => {
    const vacanciesToUpdate = avitoVacancies.map(v => {
      if (selectedRowData.includes(v.external.id)) {
        return {
          ...v,
          internal: {
            userId: selectedMassUpdateRecruiter,
            vacancyId: selectedMassUpdateVacancy
          }
        }
      }
      return v // Возвращаем вакансию без изменений, если она не выбрана
    })

    mutateLinkVacanciesJobSite({
      jobId: jobSiteData?.id,
      data: {
        vacancies: vacanciesToUpdate.map(v => ({
          external: v.external,
          internal: v.internal
        }))
      }
    })
    setSelectedMassUpdateVacancy(null) // Сбрасываем выбранную вакансию
    setSelectedMassUpdateRecruiter(null) // Сбрасываем выбранного рекрутера
    setIsPopupVisible(false) // Закрываем попап
  }

  /**
   * Обработчик для сохранения изменений.
   * Проверяет наличие ошибок и отправляет данные на сервер.
   */
  const handleSave = () => {
    let errors = Object.fromEntries(
      avitoVacancies
        .filter(vacancy => vacancy.internal?.userId || vacancy.internal?.vacancyId)
        .filter(vacancy => !vacancy.internal?.userId || !vacancy.internal?.vacancyId)
        .map(vacancy => [vacancy.external.id, vacancy.internal]) // Создаем объект ошибок
    )
    if (Object.keys(errors).length) {
      setErrors(errors) // Устанавливаем ошибки, если они есть
    } else {
      mutateLinkVacanciesJobSite({
        jobId: jobSiteData?.id,
        data: {
          vacancies: avitoVacancies.map(v => ({
            external: v.external,
            internal: v?.internal?.userId ? v?.internal : null // Отправляем только те вакансии, у которых заполнен internal не отправляем пустой объект
          }))
        }
      })
    }
  }

  /**
   * Обработчик для изменения номера страницы и размера страницы в таблице.
   *
   * @param {Object} pagination - Объект пагинации.
   */
  const handleTableChange = pagination => {
    setCurrentPage(pagination.current) // Устанавливаем текущую страницу
    setPageSize(pagination.pageSize) // Устанавливаем размер страницы
  }

  const tablePagination = useMemo(
    () => ({
      current: currentPage,
      pageSize: pageSize,
      showSizeChanger: true,
      // Отображение общего количества вакансий в зависимости от поискового запроса и фильтрации
      showTotal: () => {
        if (searchQuery || showUnconfigured) {
          return totalFound > 0
            ? `Найдено ${totalFound} из ${avitoVacancies.length}`
            : 'Нет найденных вакансий'
        }
        return `Всего ${avitoVacancies.length} вакансий`
      },
      onChange: handleTableChange,
      onShowSizeChange: (current, size) => {
        setCurrentPage(1) // Сбрасываем текущую страницу при изменении размера
        setPageSize(size) // Устанавливаем новый размер страницы
      }
    }),
    [currentPage, pageSize, totalFound, searchQuery, showUnconfigured, avitoVacancies.length]
  )

  /**
   * Обработчик для поиска вакансий.
   * Дебаунс для предотвращения частых обновлений состояния.
   *
   * @param {string} value - Запрос для поиска.
   */
  const handleSearch = useCallback(
    debounce(value => {
      setSearchQuery(value) // Устанавливаем новый запрос на поиск
      setSelectedRowData([]) // Очищаем выбранные строки
      setCurrentPage(1) // Сбрасываем текущую страницу
    }, 500),
    []
  )

  /**
   * Обработчик для переключения отображения ненастроенных вакансий.
   */
  const handleToggleUnconfigured = useCallback(() => {
    setShowUnconfigured(prev => !prev)
  }, [])

  return (
    <Spin spinning={isLoadingRecruiters || isLoadingVacancies}>
      <div className="content IntegrationsAvito">
        <Row gutter={[16, 16]} className="mb-3" justify="space-between">
          <Col md={11} lg={12} xl={15}>
            <Search
              placeholder="Поиск по названию и адресу вакансии"
              allowClear
              onSearch={handleSearch}
              onChange={e => handleSearch(e.target.value)} // Обработчик изменения текста поиска
              style={{ width: '100%' }}
            />
          </Col>
          <Col>
            <Switch
              checked={showUnconfigured}
              onChange={handleToggleUnconfigured} // Обработчик переключения ненастроенных вакансий
              className="mr-3"
            />
            Показать ненастроенные вакансии
          </Col>
          {selectedRowData.length > 0 && (
            <Col span={24}>
              <Text className="mr-3">Выбрано: {selectedRowData.length}</Text>
              <Button
                type="primary"
                onClick={handleEditSelected} // Обработчик для открытия попапа массового обновления
                disabled={selectedRowData.length === 0}
              >
                Изменить выбранные
              </Button>
            </Col>
          )}
        </Row>
        <Table
          size="middle"
          columns={columns}
          rowKey={item => item.external.id}
          dataSource={filteredVacancies}
          pagination={tablePagination}
          scroll={{ x: '100%', y: 'calc(100vh - 400px)' }}
          rowSelection={rowSelection}
        />
        <Row>
          <Col span={24}>
            <Text>
              {(searchQuery || showUnconfigured) && !totalFound && 'Нет найденных вакансий'}
            </Text>
          </Col>
        </Row>
        <IntegrationsMassUpdatePopup
          visible={isPopupVisible}
          onClose={() => {
            setIsPopupVisible(false) // Закрываем попап
            setSelectedMassUpdateVacancy(null) // Сбрасываем выбранную вакансию
            setSelectedMassUpdateRecruiter(null) // Сбрасываем выбранного рекрутера
          }}
          onSave={handleMassUpdate} // Обработчик сохранения массового обновления
          vacancies={systemVacancies}
          recruiters={recruiterList}
          selectedVacancy={selectedMassUpdateVacancy}
          selectedRecruiter={selectedMassUpdateRecruiter}
          setSelectedVacancy={setSelectedMassUpdateVacancy}
          setSelectedRecruiter={setSelectedMassUpdateRecruiter}
        />
      </div>
      <Row justify="end" className="mt-3">
        <Col>
          <Button type="link" disabled={isLoadingLinkVacanciesJobSite} onClick={handleCancel}>
            Отмена
          </Button>
          <Button type="primary" onClick={handleSave} loading={isLoadingLinkVacanciesJobSite}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </Spin>
  )
}

export default IntegrationsAvito
