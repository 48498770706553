import React, { useEffect } from 'react'
import { Button, Col, Form, Input, Row, Spin, message } from 'antd'
import { useLoginJobSite } from '../../../../api/integrations'
import { nbsp } from '../../../../constants'
import { useQueryClient } from 'react-query'

function TokenIntegrations({ getUsers, jobSiteData }) {
  const queryClient = useQueryClient()
  const {
    mutate: mutateLoginJobSite,
    isSuccess: isSuccessLoginJobSite,
    isError: isErrorLoginJobSite,
    isLoading: isLoadingLoginJobSite
  } = useLoginJobSite(queryClient)

  useEffect(() => {
    if (isSuccessLoginJobSite) {
      getUsers()
    } else if (isErrorLoginJobSite) {
      message.error('Ошибка авторизации')
    }
  }, [getUsers, isSuccessLoginJobSite, isErrorLoginJobSite])

  const handleFinish = values => {
    mutateLoginJobSite({
      jobId: jobSiteData?.id,
      data: {
        // credentials: {
        accessToken: values.accessToken,
        refreshToken: values.refreshToken
        // }
      }
    })
  }

  return (
    <Col className="integrations-auth-token">
      <Spin spinning={isLoadingLoginJobSite}>
        <Row gutter={[24, 12]} align="top" justify="start" className="TokenIntegrations mt-3">
          <Col className="description">
            Токен авторизации HRMessenger можно получить в разделе &quot;Пользовательские
            токены&quot;
            {nbsp}
            <a
              href="https://crm.hrmessenger.com/integrations"
              rel="noreferrer noopener"
              target="_blank"
            >
              https://crm.hrmessenger.com/integrations
            </a>
            .
          </Col>
          <Col xs={24} md={18}>
            <Form name="TokenIntegrations" onFinish={handleFinish} layout="horizontal">
              <Form.Item
                className="d-flex"
                name="accessToken"
                label="Access Token"
                rules={[{ required: true, message: 'Пожалуйста введите ваш Access Token!' }]}
              >
                <Input size="large" placeholder="введите Access Token" autoComplete="off" />
              </Form.Item>

              <Form.Item
                className="d-flex"
                name="refreshToken"
                label="Refresh Token"
                rules={[{ required: true, message: 'Пожалуйста введите ваш Refresh Token!' }]}
              >
                <Input size="large" placeholder="введите Refresh Token" autoComplete="off" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  disabled={isLoadingLoginJobSite}
                >
                  Авторизоваться
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Col>
  )
}

export default TokenIntegrations
