import {
  Settings,
  Users,
  CandidateList,
  FindCandidateList,
  NewCandidate,
  DepartmentsList,
  DeduplicationList,
  Planning,
  Profile,
  ReportTable,
  Calendar,
  PlanningDepartments,
  Analytics,
  OrgStructure,
  AgencyList,
  Integrations,
  Sources,
  Vacancies,
  WorkflowTabs,
  UserFieldsList,
  DemandFieldsList,
  Demands,
  Demand
} from './Screens'

import {
  CANDIDATE_PAGE,
  CANDIDATES_PAGE,
  NEW_CANDIDATE_PAGE,
  FIND_CANDIDATE_PAGE,
  FIND_CANDIDATES_PAGE,
  PLANNING_PAGE,
  PLAN_PAGE,
  REPORT_PAGE,
  PROFILE_PAGE,
  SETTINGS_PAGE,
  USERS_PAGE,
  DEPARTMENTS_PAGE,
  DEPARTMENT_PAGE,
  PLANNING_EMPTY_PAGE,
  SELECTED_PLANNING_EMPTY_PAGE,
  CALENDAR_PAGE,
  PLANNING_PERIOD_PAGE,
  SELECTED_PLANNING_PERIOD_PAGE,
  ANALYTICS_PAGE,
  ORG_STRUCTURE_PAGE,
  PLANNING_DEPARTMENTS_PAGE,
  PLANNING_DEPARTMENT_PAGE,
  AGENCIES_PAGE,
  AGENCY_PAGE,
  DEDUPLICATION_PAGE,
  DEDUPLICATION_USER_PAGE,
  INTEGRATIONS_PAGE,
  SOURCES_PAGE,
  WORKFLOWS_PAGE,
  WORKFLOW_STATE_PAGE,
  VACANCIES_PAGE,
  VACANCY_PAGE,
  WORKFLOW_PAGE,
  USER_FIELDS_PAGE,
  USER_FIELD_PAGE,
  DEMANDS_PAGE,
  DEMAND_PAGE,
  DEMAND_FIELDS_PAGE,
  DEMAND_FIELD_PAGE
} from '../../constants/routes'
import { appConfig } from '../../constants/appConfig'

const routes = ({ departmentName, user }) => [
  {
    path: CALENDAR_PAGE,
    name: user?.roles?.includes(appConfig.roles.recruiter) ? 'Календарь' : 'Главная',
    component: Calendar,
    availableOptionally: { role: appConfig.roles.recruiter, module: appConfig.modules.amp },
    availableRoles: [
      appConfig.roles.hiringManager,
      appConfig.roles.partner,
      appConfig.roles.operationDirector,
      appConfig.roles.TU
    ]
  },
  {
    path: CANDIDATES_PAGE,
    name: 'Список кандидатов', // 'Мои кандидаты' - для ролей: HM, partner, operationDirector, TU
    component: CandidateList,
    availableRoles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.partner,
      appConfig.roles.operationDirector,
      appConfig.roles.TU
    ]
  },
  {
    path: CANDIDATE_PAGE,
    component: CandidateList,
    availableRoles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.partner,
      appConfig.roles.operationDirector,
      appConfig.roles.TU
    ]
  },
  {
    path: NEW_CANDIDATE_PAGE,
    // name: 'Добавить кандидата',
    component: NewCandidate,
    availableRoles: [appConfig.roles.recruiter, appConfig.roles.hiringManager] //, appConfig.roles.manager
  },
  {
    // path: PLANNING_DEPARTMENTS_PAGE,
    name: 'Потребность персонала',
    // component: PlanningDepartments,
    availableRoles: [
      appConfig.roles.hiringManager,
      appConfig.roles.recruiter,
      appConfig.roles.manager,
      appConfig.roles.admin,
      appConfig.roles.prioritySelector
    ],
    availableOptionally: { role: appConfig.roles.recruiter, module: appConfig.modules.amp },
    children: [
      {
        path: PLANNING_PERIOD_PAGE,
        component: PlanningDepartments,
        availableRoles: [appConfig.roles.hiringManager]
      },
      {
        path: SELECTED_PLANNING_PERIOD_PAGE,
        component: PlanningDepartments,
        availableRoles: [appConfig.roles.hiringManager]
      },
      {
        path: PLANNING_DEPARTMENTS_PAGE,
        name: 'Планирование',
        component: PlanningDepartments,
        availableRoles: [appConfig.roles.hiringManager]
      },
      {
        path: PLANNING_DEPARTMENT_PAGE,
        component: PlanningDepartments,
        availableRoles: [appConfig.roles.hiringManager]
      },
      {
        path: PLANNING_PAGE,
        name: 'Планирование',
        component: Planning,
        availableRoles: [
          appConfig.roles.recruiter,
          appConfig.roles.manager,
          appConfig.roles.admin,
          appConfig.roles.prioritySelector
        ]
      },
      {
        path: PLAN_PAGE,
        component: Planning,
        availableRoles: [
          appConfig.roles.recruiter,
          appConfig.roles.manager,
          appConfig.roles.admin,
          appConfig.roles.prioritySelector
        ]
      },
      {
        path: PLANNING_EMPTY_PAGE,
        name: `${departmentName} без плана`,
        component: Planning,
        availableRoles: [appConfig.roles.manager, appConfig.roles.admin]
      },
      {
        path: SELECTED_PLANNING_EMPTY_PAGE,
        component: Planning,
        availableRoles: [appConfig.roles.manager, appConfig.roles.admin]
      },
      {
        path: DEMANDS_PAGE,
        name: 'Заявки на подбор АУП',
        component: Demands,
        availableRoles: [appConfig.roles.manager, appConfig.roles.admin],
        availableOptionally: { role: appConfig.roles.recruiter, module: appConfig.modules.amp },
        featureToggle: appConfig.features.amp
      },
      {
        path: DEMAND_PAGE,
        component: Demand,
        availableRoles: [appConfig.roles.manager, appConfig.roles.admin],
        availableOptionally: { role: appConfig.roles.recruiter, module: appConfig.modules.amp },
        featureToggle: appConfig.features.amp
      }
    ]
  },
  {
    path: FIND_CANDIDATES_PAGE,
    // name: 'Поиск кандидата',
    component: FindCandidateList,
    availableRoles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.manager,
      appConfig.roles.admin
    ]
  },
  {
    path: FIND_CANDIDATE_PAGE,
    component: FindCandidateList,
    availableRoles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.manager,
      appConfig.roles.admin
    ]
  },
  {
    path: CANDIDATE_PAGE,
    component: FindCandidateList,
    availableRoles: [appConfig.roles.admin, appConfig.roles.manager]
  },
  {
    path: REPORT_PAGE,
    name: 'Отчёт',
    component: ReportTable,
    availableRoles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.manager,
      appConfig.roles.admin
    ]
  },
  {
    path: ANALYTICS_PAGE,
    name: 'Аналитика',
    component: Analytics,
    availableRoles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.manager,
      appConfig.roles.admin
    ],
    featureToggle: appConfig.features.analytics,
    concurrentConditionCb: user =>
      !(user?.modules?.length === 1 && user?.modules?.includes(appConfig.modules.amp))
  },
  {
    name: 'Настройки',
    availableRoles: [appConfig.roles.admin],
    children: [
      {
        path: SETTINGS_PAGE,
        name: 'Система',
        availableRoles: [appConfig.roles.admin],
        component: Settings
      },
      {
        path: WORKFLOWS_PAGE,
        name: 'Воронка',
        component: WorkflowTabs,
        availableRoles: [appConfig.roles.admin],
        featureToggle: appConfig.features.workflowDesigner
      },
      {
        path: USER_FIELDS_PAGE,
        name: 'Кандидат',
        availableRoles: [appConfig.roles.admin],
        component: UserFieldsList
      },
      {
        path: USER_FIELD_PAGE,
        availableRoles: [appConfig.roles.admin],
        component: UserFieldsList
      },
      {
        path: DEMAND_FIELDS_PAGE,
        name: 'Заявки',
        availableRoles: [appConfig.roles.admin],
        component: DemandFieldsList
      },
      {
        path: DEMAND_FIELD_PAGE,
        availableRoles: [appConfig.roles.admin],
        component: DemandFieldsList
      },
      {
        path: WORKFLOW_PAGE,
        component: WorkflowTabs,
        availableRoles: [appConfig.roles.admin],
        featureToggle: appConfig.features.workflowDesigner
      },
      {
        path: WORKFLOW_STATE_PAGE,
        component: WorkflowTabs,
        availableRoles: [appConfig.roles.admin],
        featureToggle: appConfig.features.workflowDesigner
      },
      {
        path: VACANCIES_PAGE,
        name: 'Вакансии',
        component: Vacancies,
        availableRoles: [appConfig.roles.admin],
        featureToggle: appConfig.features.vacancyEditor
      },
      {
        path: VACANCY_PAGE,
        component: Vacancies,
        availableRoles: [appConfig.roles.admin],
        featureToggle: appConfig.features.vacancyEditor
      },
      {
        path: ORG_STRUCTURE_PAGE,
        name: 'Оргструктура',
        component: OrgStructure,
        availableRoles: [appConfig.roles.admin],
        featureToggle: appConfig.features.orgLayers
      },
      {
        path: INTEGRATIONS_PAGE,
        name: 'Интеграции',
        component: Integrations,
        availableRoles: [appConfig.roles.admin],
        featureToggle: appConfig.features.jobSites
      },
      {
        path: SOURCES_PAGE,
        name: 'Источники',
        availableRoles: [appConfig.roles.admin],
        component: Sources
      }
    ]
  },
  {
    path: USERS_PAGE,
    name: 'Пользователи',
    component: Users,
    availableRoles: [appConfig.roles.admin]
  },
  {
    path: DEPARTMENTS_PAGE,
    name: departmentName,
    component: DepartmentsList,
    availableRoles: [appConfig.roles.admin]
  },
  {
    path: DEPARTMENT_PAGE,
    component: DepartmentsList,
    availableRoles: [appConfig.roles.admin]
  },
  {
    path: AGENCIES_PAGE,
    name: 'Агентства',
    component: AgencyList,
    availableRoles: [appConfig.roles.admin],
    featureToggle: appConfig.features.agencies
  },
  {
    path: AGENCY_PAGE,
    component: AgencyList,
    availableRoles: [appConfig.roles.admin],
    featureToggle: appConfig.features.agencies
  },
  {
    path: PROFILE_PAGE,
    name: 'Профиль',
    component: Profile,
    availableToAll: true
  },
  {
    path: DEDUPLICATION_PAGE,
    name: 'Дедупликация',
    component: DeduplicationList,
    // только для рекрутеров без агентств
    availableRoles: [appConfig.roles.recruiter],
    withoutAgency: true
  },
  {
    path: DEDUPLICATION_USER_PAGE,
    component: DeduplicationList,
    // только для рекрутеров без агентств
    availableRoles: [appConfig.roles.recruiter],
    withoutAgency: true
  }
]
export default routes
