import { api } from './api'
import { useQuery } from 'react-query'
import { defaultQueryOptions } from '../constants'

const MODULES_KEY = 'modules'

const fetchModules = () => {
  return api.get({ path: MODULES_KEY })
}
export function useQueryModules(options) {
  return useQuery([MODULES_KEY], fetchModules, { ...defaultQueryOptions, ...options })
}

const rolesQuery = options => ({
  queryKey: [MODULES_KEY],
  queryFn: async () => fetchModules(),
  options: { ...defaultQueryOptions, ...(options || {}) }
})
export const modulesLoader = async queryClient => {
  const query = rolesQuery()
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}
