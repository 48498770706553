import React, { useEffect, useMemo } from 'react'
import { Form, message, Col, Row, Select, Typography } from 'antd'

import { colSettings } from '../../../../../constants'
import { appConfig } from '../../../../../constants/appConfig'
import { useUsers } from '../../../../../api/users'
import { selectSearchFilter } from '../../../../../helpers'
import { useConfigContextData } from '../../../../../hooks/useConfigContextData'

const { Option } = Select
const { Text } = Typography

export default function ManagersItemSelect({
  role = appConfig.roles.manager,
  form,
  onOpenNewManager,
  onSelectManager,
  label = null,
  size = 'large',
  placeholder,
  labelOneRow = false,
  mode = 'multiple',
  name = 'managers'
}) {
  const { rolesLabels } = useConfigContextData()
  const {
    data: usersData,
    isSuccess: isSuccessUsers,
    isError: isErrorUsers,
    isLoading: isLoadingUsers
  } = useUsers({
    filters: {
      roles: [role]
    }
  })

  useEffect(() => {
    if (isErrorUsers) {
      message.error('Ошибка загрузки данных менеджеров')
    }
  }, [usersData, isSuccessUsers, isErrorUsers])

  const managerList = useMemo(() => {
    if (isSuccessUsers) {
      if (onOpenNewManager) {
        return [
          {
            _id: 'new',
            name: 'Добавить нового'
          },
          ...(usersData?.data || [])
        ]
      } else {
        return usersData?.data || []
      }
    }
    return []
  }, [usersData?.data, isSuccessUsers, onOpenNewManager])

  const handleSelect = id => {
    if (id) {
      if (id === 'new') {
        onOpenNewManager?.()
      } else {
        // const data = managerList?.find(user => user._id === id)
        form?.setFieldsValue?.({ managers: id })
        onSelectManager?.(id)
      }
    }
  }
  return (
    <Row gutter={[12, 6]} justify="start" align="middle">
      {label && (
        <Col>
          <Text>{label}</Text>
        </Col>
      )}
      <Col span={!labelOneRow && 24}>
        <Form.Item
          labelCol={colSettings.full}
          wrapperCol={colSettings.full}
          name={name}
          noStyle={labelOneRow}
        >
          <Select
            className="manager-selector"
            loading={isLoadingUsers}
            onChange={handleSelect}
            size={size}
            placeholder={
              placeholder || `Выберите ${rolesLabels[appConfig.roles.manager]?.toLowerCase()}а`
            }
            showSearch
            optionFilterProp="children"
            filterOption={selectSearchFilter}
            allowClear
            mode={mode}
          >
            {managerList?.map(user => (
              <Option key={user._id} value={user._id}>
                <div>{user.name}</div>
                {user?.position && (
                  <div>
                    <Text type="secondary">{user?.position?.name}</Text>
                  </div>
                )}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  )
}
