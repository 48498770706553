import { Typography, Button, Drawer, message, Spin } from 'antd'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SettingOutlined } from '@ant-design/icons'

// import orgUserImg from '../../../../../img/orgStructure/user.svg'
import { useMutateUser, useUsers } from '../../../../../api/users'
import { arrayEquals, debounce } from '../../../../../helpers'
import { appConfig } from '../../../../../constants/appConfig'
import HorizontalSlideTwoBlocks from '../../Animate/HorizontalSlideTwoBlocks'
import { useDepartmentsQuery } from '../../../../../api/department'
import EditOrgUser from './EditOrgUser'
import UserRelationList from '../../User/UserRelationList'
import { addDepartmentsToBranch } from '../../../../../helpers/orgStructure'
import { useMutateAddOrgStructure } from '../../../../../api/orgStructure'
import { OrgStructureContext } from '../../../../../contexts/orgStructureContext'
import { noOrgStructureName } from '../../../../../constants/orgStructure'
import { commonDrawerWidth } from '../../../../../constants'
import { useQueryClient } from 'react-query'

const { Text } = Typography

function OrgUnitUsers({ selectedUnits, lastSelected }) {
  const queryClient = useQueryClient()
  const orgStructure = useContext(OrgStructureContext)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [departments, setDepartments] = useState([])
  const [search, setSearch] = useState('')

  //мэнеджеры кого можно назначиь на оргЕдиницу
  const [managers, setManagers] = useState([])
  //мэнеджеры которые уже назначены на оргЕдиницу
  const [orgUnitManagers, setOrgUnitManagers] = useState([])
  //менеджеры выбранные для добавления чекбоксами
  const [selectedUsers, setSelectedUsers] = useState([])
  // менеджер который выбран чекбоксом или изи списка эже назначенных которому настраивают скоуп
  const [settingUser, setSettingUser] = useState('')
  // настройка скоупа одному из выбраных менеджеров
  const [showSecondaryBlock, setShowSecondaryBlock] = useState(false)
  // дерево орг структуры с встроенными внутрь департаментами для возможности выбрать
  const [orgUnitWithDepartments, setOrgUnitWithDepartments] = useState({})
  const [filteredOrgUnitWithDepartments, setFilteredOrgUnitWithDepartments] = useState(null)

  const {
    mutate: mutateUser,
    isSuccess: isSuccessUpdateUser,
    isError: isErrorUpdateUser,
    isLoading: isLoadingUpdateUser
  } = useMutateUser(queryClient)

  useEffect(() => {
    if (isSuccessUpdateUser) {
      message.success('Пользователь успешно обновлен')
    } else if (isErrorUpdateUser) {
      message.error('Ошибка сохранения данных пользователя')
    }
  }, [isSuccessUpdateUser, isErrorUpdateUser])

  const {
    data: dataManagers,
    isSuccess: isSuccessManagers,
    isLoading: isLoadingManagers,
    isError: isErrorManagers
  } = useUsers(
    {
      filters: { roles: [appConfig.roles.manager], text: search } // manager
    },
    {
      enabled: isDrawerOpen
    }
  )
  useEffect(() => {
    if (isSuccessManagers) {
      const orgUnitManagers = []
      const list = dataManagers?.data
        ?.map(manager => {
          if (lastSelected?.users?.includes(manager._id)) {
            orgUnitManagers.push(manager)
            return null
          }
          return manager
        })
        ?.filter(Boolean)
      setOrgUnitManagers(orgUnitManagers)
      setManagers(list)
    } else if (isErrorManagers) {
      message.error('Ошибка загрузки данных пользователей')
    }
  }, [dataManagers, isSuccessManagers, isErrorManagers, lastSelected])

  const {
    data: departmentsData,
    isSuccess: isSuccessDepartments,
    isLoading: isLoadingDepartments,
    isError: isErrorDepartments
  } = useDepartmentsQuery(
    { parentOrganizationUnits: [lastSelected?._id] },
    { enabled: isDrawerOpen && !!lastSelected?._id }
  )

  useEffect(() => {
    if (isSuccessDepartments && departmentsData?.data) {
      setDepartments(departmentsData?.data)
      const departmentsList = [...(departmentsData?.data || [])]
      const unit = JSON.parse(JSON.stringify(lastSelected))
      addDepartmentsToBranch({ unit, departmentsList })
      setOrgUnitWithDepartments(unit)
    }
    if (isErrorDepartments) message.error('Ошибка загрузки орг. единиц')
  }, [departmentsData, isSuccessDepartments, isErrorDepartments, lastSelected])

  const {
    isSuccess: isSuccessOrgStructure,
    isError: isErrorOrgStructure,
    mutate: mutateOrgStructure,
    isLoading: isLoadingOrgStructure
  } = useMutateAddOrgStructure(queryClient)

  useEffect(() => {
    if (isSuccessOrgStructure) {
      message.success('Данные успешно сохранены')
    } else if (isErrorOrgStructure) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessOrgStructure, isErrorOrgStructure])

  useEffect(() => {
    if (!isDrawerOpen) {
      setSearch('')
      setSelectedUsers([])
    }
  }, [isDrawerOpen])

  const handleClose = useCallback(() => {
    if (!showSecondaryBlock) {
      setIsDrawerOpen(false)
    } else {
      const newDepartments = departments?.map(item => item._id)

      setSettingUser('')
      setShowSecondaryBlock(false)
      // при отмене новым юзерам перетираем выбранные новые скоупы
      if (selectedUsers?.find(user => user._id === settingUser._id)) {
        setSelectedUsers([
          ...selectedUsers.filter(user => user._id !== settingUser._id),
          {
            ...settingUser,
            scope: {
              ...settingUser.scope,
              newDepartments
            }
          }
        ])
      }
    }
  }, [showSecondaryBlock, selectedUsers, departments, settingUser])

  const saveUser = useCallback(
    user => {
      mutateUser({
        id: user._id,
        data: {
          ...user,
          scope: {
            ...user.scope,
            departmentReport: [
              ...new Set([
                ...(user.scope.departmentsFromOtherUnits || []),
                ...user.scope.newDepartments
              ])
            ]
          }
        }
      })
    },
    [mutateUser]
  )

  const setUnitUsers = useCallback(
    (data, layerIndex = 0, isDeleted) => {
      if (layerIndex === selectedUnits.length) {
        data.users = isDeleted
          ? [...(data.users?.filter(id => id !== settingUser._id) || [])]
          : [...(data.users || []), ...(selectedUsers?.map(user => user._id) || [])]
        return null
      }

      const unit = data?.children?.find(item => item._id === selectedUnits[layerIndex]._id)
      setUnitUsers(unit, layerIndex + 1, isDeleted)
    },
    [selectedUsers, selectedUnits, settingUser]
  )

  const handleSave = useCallback(() => {
    if (showSecondaryBlock) {
      // если редактируем ранее назначенного юзера то сразу сохраняем
      // структуру не обновляем так как пользователи не изменились
      if (!selectedUsers?.find(user => user._id === settingUser._id)) {
        saveUser(settingUser)
      }
      setShowSecondaryBlock(false)
      setSettingUser('')
    } else {
      selectedUsers.forEach(saveUser)

      // обновляем в стрруктуре юнит - добавляем пользователей
      const units = { children: [...orgStructure.units] }
      setUnitUsers(units)
      mutateOrgStructure({
        layers: orgStructure.layers,
        units: units.children
      })
      setSelectedUsers([])
    }
  }, [
    settingUser,
    saveUser,
    selectedUsers,
    showSecondaryBlock,
    mutateOrgStructure,
    setUnitUsers,
    orgStructure
  ])

  const handleSubmit = useCallback(search => setSearch(search), [])

  const debounceSearch = useMemo(() => debounce(handleSubmit), [handleSubmit])

  const handleChangeSearch = useCallback(
    ({ target }) => debounceSearch(target.value),
    [debounceSearch]
  )

  const handleChangeAddress = useCallback(
    ({ target }) => {
      const departmentsList = departments.filter(item =>
        item.name?.toLowerCase().includes(target?.value?.toLowerCase())
      )
      if (!target.value) {
        return setFilteredOrgUnitWithDepartments(null)
      }
      if (!departmentsList.length) {
        return setFilteredOrgUnitWithDepartments({})
      }

      const unit = JSON.parse(JSON.stringify(lastSelected))
      addDepartmentsToBranch({
        withParent: true,
        unit,
        departmentsList
      })
      setFilteredOrgUnitWithDepartments(unit)
    },
    [departments, lastSelected]
  )

  const lastSelectedUnitChildrenIds = useMemo(() => {
    if (!selectedUnits?.length) return null
    const ids = []
    const countChildren = list => {
      if (list.children?.length) {
        list.children.forEach(child => {
          ids.push(child._id)
          countChildren(child)
        })
      }
    }
    countChildren(orgUnitWithDepartments)
    return ids
  }, [orgUnitWithDepartments, selectedUnits])

  const handleSelectUser = useCallback(
    user => {
      if (selectedUsers?.find(e => e._id === user._id)) {
        setSelectedUsers(selectedUsers.filter(e => e._id !== user._id))
      } else {
        const newDepartments = departments?.map(item => item._id) || []
        setSelectedUsers([
          ...selectedUsers,
          {
            ...user,
            scope: {
              ...user.scope,
              newDepartments,
              departmentsFromOtherUnits: user.scope?.departmentReport?.filter(
                item => !newDepartments.includes(item._id) || []
              )
            }
          }
        ])
      }
    },
    [selectedUsers, departments]
  )
  const handleSelectAll = useCallback(() => {
    if (selectedUsers.length === managers?.length) {
      setSelectedUsers([])
    } else {
      const newDepartments = departments?.map(item => item._id) || []
      const selectedUsersIds = selectedUsers?.map(selectedUser => selectedUser._id) || []
      setSelectedUsers([
        ...(selectedUsers || []),
        ...(managers
          ?.filter(user => !selectedUsersIds.includes(user._id))
          ?.map(user => ({
            ...user,
            scope: {
              ...user.scope,
              newDepartments,
              departmentsFromOtherUnits: user.scope?.departmentReport?.filter(
                item => !newDepartments.includes(item._id) || []
              )
            }
          })) || [])
      ])
    }
  }, [departments, managers, selectedUsers])

  const handleEditOrgUnitManager = useCallback(
    user => {
      const newDepartments = departments?.map(item => item._id) || []

      setShowSecondaryBlock(true)
      setSettingUser({
        ...user,
        scope: {
          ...user.scope,
          newDepartments:
            user.scope?.departmentReport?.filter(departmentId =>
              newDepartments.includes(departmentId)
            ) || [],
          departmentsFromOtherUnits:
            user.scope?.departmentReport?.filter(
              departmentId => !newDepartments.includes(departmentId)
            ) || []
        }
      })
    },
    [departments]
  )

  const handleChangeUserScope = useCallback(
    user => {
      if (selectedUsers?.find(e => e._id === user._id)) {
        setSelectedUsers([...selectedUsers.filter(e => e._id !== user._id), user])
      } else {
        setSettingUser(user)
      }
    },
    [selectedUsers]
  )

  const handleSettingUser = useCallback(
    user => {
      setShowSecondaryBlock(true)
      setSettingUser(selectedUsers?.find(e => e._id === user._id))
    },
    [selectedUsers]
  )
  const handleDeleteUser = useCallback(() => {
    mutateUser({
      id: settingUser._id,
      data: {
        ...settingUser,
        scope: {
          ...settingUser.scope,
          departmentReport: settingUser.scope.departmentsFromOtherUnits || []
        }
      }
    })

    // обновляем в стрруктуре юнит - удаляем пользователя
    const units = { children: [...orgStructure.units] }
    setUnitUsers(units, 0, true)
    mutateOrgStructure({
      layers: orgStructure.layers,
      units: units.children
    })

    setShowSecondaryBlock(false)
    setSettingUser('')
  }, [mutateUser, settingUser, setUnitUsers, orgStructure, mutateOrgStructure])

  const isHeaderWithDeleteBtn = useMemo(() => {
    const {
      departmentReport = [],
      departmentsFromOtherUnits = [],
      newDepartments = []
    } = settingUser?.scope || {}
    return (
      (!!settingUser &&
        !selectedUsers?.find(user => user._id === settingUser._id) &&
        arrayEquals(departmentReport, [...departmentsFromOtherUnits, ...newDepartments])) ||
      !newDepartments.length
    )
  }, [selectedUsers, settingUser])

  const primaryBlock = useMemo(
    () => (
      <Spin spinning={isLoadingManagers || isLoadingDepartments}>
        <UserRelationList
          onSelectAll={handleSelectAll}
          onCancel={handleClose}
          onSave={handleSave}
          isLoading={isLoadingUpdateUser || isLoadingOrgStructure}
          title={lastSelected?.name || noOrgStructureName}
          enabledUserEdit
          onEditOrgUnitUser={handleEditOrgUnitManager}
          lastSelectedUnitChildrenIds={lastSelectedUnitChildrenIds}
          onChangeSearch={handleChangeSearch}
          onSelectUser={handleSelectUser}
          enabledSettingUser
          onSettingUser={handleSettingUser}
          departments={departments}
          otherUsers={managers}
          relatedUsers={orgUnitManagers}
          selectedUsers={selectedUsers}
          search={search}
          enabledEditTitle
          selectedUnits={selectedUnits}
        />
      </Spin>
    ),
    [
      handleSelectAll,
      handleClose,
      handleSave,
      isLoadingUpdateUser,
      lastSelected?.name,
      handleEditOrgUnitManager,
      lastSelectedUnitChildrenIds,
      handleChangeSearch,
      handleSelectUser,
      handleSettingUser,
      departments,
      managers,
      orgUnitManagers,
      selectedUsers,
      isLoadingOrgStructure,
      isLoadingManagers,
      search,
      isLoadingDepartments,
      selectedUnits
    ]
  )

  const tree = useMemo(
    () => [filteredOrgUnitWithDepartments || orgUnitWithDepartments],
    [filteredOrgUnitWithDepartments, orgUnitWithDepartments]
  )

  if (!selectedUnits?.length) return null

  return (
    <div className="org-layer-action">
      <Button
        className="header-button"
        type="link"
        size="small"
        onClick={() => setIsDrawerOpen(true)}
      >
        <SettingOutlined />
        <Text>Настройки</Text>
      </Button>
      <Drawer
        closable={false}
        width={commonDrawerWidth}
        placement="right"
        onClose={handleClose}
        open={isDrawerOpen}
        className="editOrgUsers-drawer"
        destroyOnClose
      >
        <HorizontalSlideTwoBlocks
          showSecondaryBlock={showSecondaryBlock}
          primaryBlock={primaryBlock}
          secondaryBlock={
            <EditOrgUser
              tree={tree}
              onChangeAddress={handleChangeAddress}
              onDeleteUser={handleDeleteUser}
              onClose={handleClose}
              onSave={handleSave}
              isLoadingUpdateUser={isLoadingUpdateUser}
              isHeaderWithDeleteBtn={isHeaderWithDeleteBtn}
              lastSelectedUnitChildrenIds={lastSelectedUnitChildrenIds}
              showSecondaryBlock={showSecondaryBlock}
              settingUser={settingUser}
              onChangeUserScope={handleChangeUserScope}
            />
          }
        />
        {/*</div>*/}
      </Drawer>
    </div>
  )
}
export default OrgUnitUsers
