import React, { useState } from 'react'
import { Row, Button } from 'antd'
import CommentsItem from './CommentsItem'
import CommentForm from './CommentForm'

function Comments({
  comments,
  entity,
  avatarColSpan = 3,
  onDelete,
  onCreate,
  onUpdate,
  saveButtonTitle,
  title = 'Комментарии'
}) {
  const [last, beforeLast, ...begin] = (comments || []).reverse()
  const twoLastComments = [beforeLast, last].filter(Boolean)
  const restComments = begin.reverse()

  const [isRestOpened, setIsRestOpened] = useState(false)

  return (
    <div className="commentList mt-3">
      <Row align="middle" className="comment-info-heading">
        {title}
        {restComments.length > 0 && (
          <Button type="link" onClick={() => setIsRestOpened(!isRestOpened)}>
            {isRestOpened ? 'Показать последние' : 'Показать все'}
          </Button>
        )}
      </Row>
      {isRestOpened &&
        restComments?.map(comment => (
          <CommentsItem
            key={comment._id}
            entity={entity}
            onDelete={onDelete}
            onCreate={onCreate}
            onUpdate={onUpdate}
            comment={comment}
            avatarColSpan={avatarColSpan}
          />
        ))}
      {twoLastComments?.map(comment => (
        <CommentsItem
          key={comment._id}
          entity={entity}
          onDelete={onDelete}
          onCreate={onCreate}
          onUpdate={onUpdate}
          comment={comment}
          avatarColSpan={avatarColSpan}
        />
      ))}
      <CommentForm
        entity={entity}
        avatarColSpan={avatarColSpan}
        onCreate={onCreate}
        onUpdate={onUpdate}
        saveButtonTitle={saveButtonTitle}
      />
    </div>
  )
}

export default Comments
