import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useOutlet, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { appConfig } from '../constants/appConfig'
import { UserContext } from '../contexts/userContext'
import { useGetProfile } from '../api/users'
import { areArraysIntersect } from '../helpers'

const ampRoles = [appConfig.roles.partner, appConfig.roles.operationDirector, appConfig.roles.TU]

export const UserLayout = () => {
  const [user, setUser] = useState({})
  const [isUserLoaded, setIsUserLoaded] = useState(false)
  const [isLogout, setIsLogout] = useState(false)
  const outlet = useOutlet()
  const navigate = useNavigate()

  const login = useCallback(async user => {
    // eslint-disable-next-line no-unused-vars
    const { email, name, ...clearUser } = user
    Sentry.setUser(clearUser)
    setUser(user)
    setIsUserLoaded(true)
  }, [])

  const logout = useCallback(() => {
    setUser({})
    Sentry.setUser({})
    setIsLogout(true)
    navigate('/', { replace: true })
  }, [navigate])

  const { data: profile, isSuccess: isSuccessProfile, refetch } = useGetProfile({ retry: 0 })

  useEffect(() => {
    if (!Object.keys(user)?.length && !isLogout) {
      refetch()
    }
  }, [user, refetch, isLogout])

  useEffect(() => {
    if (isSuccessProfile && profile.data) {
      login(profile.data)
    }
  }, [isSuccessProfile, profile, login])

  const userContextValue = useMemo(
    () => ({
      user,
      login,
      logout,
      setUser,
      isUserLoaded,
      isRecruiter: user.roles?.includes(appConfig.roles.recruiter),
      isHiringManager: user.roles?.includes(appConfig.roles.hiringManager),
      isManager: user.roles?.includes(appConfig.roles.manager),
      isPositionForPlan: user.position?.planNotification,
      isAdmin: user.roles?.includes(appConfig.roles.admin),
      isPrioritySelector: user.roles?.includes(appConfig.roles.prioritySelector),
      isOperationDirector: user.roles?.includes(appConfig.roles.operationDirector),
      isTU: user.roles?.includes(appConfig.roles.TU),
      isPartner: user.roles?.includes(appConfig.roles.partner),
      isAmpUser:
        user.modules?.includes(appConfig.modules.amp) || areArraysIntersect(ampRoles, user.roles),
      isNoAuthorize: false, //isError,
      //@TODO: проверить нужно ли еще?
      isOneRole: user.roles?.length === 1
    }),
    [user, login, logout, isUserLoaded]
  )

  return <UserContext.Provider value={userContextValue}>{outlet}</UserContext.Provider>
}
