import React, { useEffect, useMemo } from 'react'
import { message, Spin } from 'antd'

import Comments from '../../../../components/Common/Comments/Comments'
import {
  useMutateDeletePlanComment,
  useMutateUpdatePlanComment,
  useMutateCreatePlanComment,
  useQueryPlanComments
} from '../../../../api/comments/planComment'

function PlanComments({ plan }) {
  const {
    data: commentsData,
    isError: isErrorComments,
    isLoading: isLoadingComments
  } = useQueryPlanComments({ entity: plan?._id }, { enabled: !!plan?._id })

  const comments = useMemo(() => commentsData?.data?.docs || [], [commentsData?.data])

  useEffect(() => {
    if (isErrorComments) {
      message.error('Ошибка загрузки комментариев')
    }
  }, [isErrorComments])

  return (
    <Spin spinning={isLoadingComments}>
      <Comments
        title=""
        saveButtonTitle="Сохранить комментарий"
        entity={plan}
        comments={comments}
        onDelete={useMutateDeletePlanComment}
        onCreate={useMutateCreatePlanComment}
        onUpdate={useMutateUpdatePlanComment}
      />
    </Spin>
  )
}

export default PlanComments
