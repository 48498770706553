import React, { useCallback } from 'react'
import { Form, Select } from 'antd'
import { appConfig } from '../../../../../constants/appConfig'
import { colSettings } from '../../../../../constants'
// import { useQueryRoles } from '../../../../../api/roles'
import { useConfigContextData } from '../../../../../hooks/useConfigContextData'

const { Option } = Select

function RoleItem({
  roles,
  disabled = false,
  adminOptionDisabled = false,
  size = 'middle',
  labelCol = colSettings.six,
  wrapperCol = colSettings.full,
  disabledRoles = [],
  required = true,
  label = 'Роль',
  name = 'roles',
  onChange
}) {
  const { rolesLabels } = useConfigContextData()

  const handleChange = useCallback(
    value => {
      onChange?.(value, name)
    },
    [onChange, name]
  )

  // const { isError: isErrorRoles, data: dataRoles } = useQueryRoles()
  // useEffect(() => {
  //   if (isErrorRoles) {
  //     message.error('Ошибка получения списка ролей')
  //   }
  // }, [isErrorRoles])
  //
  // const rolesLabels = useMemo(() =>
  //   dataRoles?.data?.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.displayName }), {})
  // )

  return (
    <Form.Item
      label={label}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign="left"
      required={required}
      name={name}
      rules={[
        {
          required,
          message: 'Выберите роль'
        }
      ]}
    >
      <Select
        size={size}
        placeholder="Выберите роль"
        disabled={disabled}
        mode="multiple"
        showSearch={false}
        onChange={handleChange}
      >
        {roles.map(role => (
          <Option
            key={role}
            value={role}
            disabled={
              (adminOptionDisabled && role === appConfig.roles.admin) ||
              disabledRoles.includes(role)
            }
          >
            {rolesLabels[role]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default RoleItem
