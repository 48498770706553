import React, { useContext, useMemo, useState, useReducer, useEffect } from 'react'
import { useOutlet } from 'react-router-dom'
import { simpleReducer } from '../helpers'
import { ConfigContext, initReferences } from '../contexts/configContext'

export const RequiredDataProvider = ({ settings, features, workflows, roles, modules }) => {
  const outlet = useOutlet()

  const [refsConfig, setRefsConfig] = useReducer(simpleReducer, initReferences)
  const [settingsConfig, setSettingsConfig] = useReducer(simpleReducer, /*settings || */ {})
  const [featuresConfig, setFeaturesConfig] = useState(/*features ||*/ [])
  const [isLoadedFeatures, setIsLoadedFeatures] = useState(false)
  // const navigate = useNavigate()

  useEffect(() => {
    setSettingsConfig(settings || {})
  }, [settings])
  useEffect(() => {
    setFeaturesConfig(features || [])
    setIsLoadedFeatures(true)
  }, [features])

  const configContextValue = useMemo(
    () => ({
      references: { data: refsConfig, setData: setRefsConfig },
      features: {
        data: featuresConfig,
        setData: setFeaturesConfig,
        isLoaded: isLoadedFeatures
      },
      settings: { data: settingsConfig, setData: setSettingsConfig },
      workflows: { data: workflows },
      roles: { data: roles },
      modules: { data: modules }
    }),
    [featuresConfig, refsConfig, settingsConfig, isLoadedFeatures, workflows, roles]
  )

  return (
    <ConfigContext.Provider value={configContextValue}>
      {isLoadedFeatures && outlet}
    </ConfigContext.Provider>
  )
}

export const useConfig = () => {
  return useContext(ConfigContext)
}
