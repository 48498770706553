import { useMutation, useQuery } from 'react-query'
import { api } from './api'
import { USERS_KEY } from './users'
import { REFS_KEY } from './reference'
import { AGENCY_KEY } from './agency'
import { APPOINTMENTS_KEY } from './appointment'

export const DEPARTMENT_KEY = 'department'
export const DEPARTMENT_SEARCH_KEY = 'departments_with_params'
export const DEPARTMENT_APPROVED_DEMAND_KEY = 'department_approved_demand'
const DEPARTMENT_PREFIX = 'departments'

const onSuccessUpdateQuery = queryClient => () => {
  queryClient.invalidateQueries(DEPARTMENT_KEY)
  queryClient.invalidateQueries(DEPARTMENT_SEARCH_KEY)
  queryClient.invalidateQueries(USERS_KEY)
  queryClient.invalidateQueries(AGENCY_KEY)
  queryClient.refetchQueries(REFS_KEY, { active: true })
}

const fetchGetDepartments = ({ queryKey }) => {
  const [, /* key */ params] = queryKey
  let path = DEPARTMENT_PREFIX
  return api.get({ path, params })
}

const fetchGetDepartmentById = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: DEPARTMENT_PREFIX + '/' + id })
}

const fetchGetHQDepartments = () => {
  return api.get({ path: DEPARTMENT_PREFIX + '/hq' })
}

const fetchPostDepartmentFind = ({ queryKey }) => {
  const [, /* key */ address, search] = queryKey
  // можно передавать либо одно, либо второе. если передаешь name, то address игнорируется
  const data = {
    ...(search ? { name: search } : { address })
  }
  return api.post({ path: DEPARTMENT_PREFIX + '/find', data })
}

const patchDepartmentSettings = data => {
  return api.patch({ path: DEPARTMENT_PREFIX + '/settings', data })
}

const getDepartmentsNoDemand = ({ queryKey }) => {
  const [, , /* key */ /* second key */ params] = queryKey
  return api.get({ path: DEPARTMENT_PREFIX + `/noDemand/${params.year}/${params.month}` })
}

const postDepartmentsWithPlans = ({ queryKey }) => {
  const [, /* key */ address, search, limit, distance] = queryKey
  // можно передавать либо одно, либо второе. если передаешь name, то address игнорируется
  const data = {
    ...(search ? { name: search } : { address }),
    limit,
    distance: Number(distance)
  }
  return api.post({ path: DEPARTMENT_PREFIX + '/findWithApprovedDemand', data })
}

const postDepartmentsWithDemands = ({ queryKey }) => {
  const [, /* key */ address, search, limit, distance] = queryKey
  // можно передавать либо одно, либо второе. если передаешь name, то address игнорируется
  const data = {
    ...(search ? { name: search } : { address }),
    limit,
    distance: Number(distance)
  }
  return api.post({ path: DEPARTMENT_PREFIX + '/withDemands/find', data })
}

const patchDepartment = ({ id, data }) => {
  return api.patch({ path: `${DEPARTMENT_PREFIX}/${id}`, data })
}

export function useMutateDepartment(queryClient) {
  return useMutation(patchDepartment, {
    onSuccess: onSuccessUpdateQuery(queryClient)
  })
}

const putCreateDepartment = ({ data }) => {
  return api.put({ path: DEPARTMENT_PREFIX, data })
}

export function useMutateCreateDepartment(queryClient) {
  return useMutation(putCreateDepartment, {
    onSuccess: onSuccessUpdateQuery(queryClient)
  })
}

export function useDepartmentsQuery(params, options) {
  return useQuery([DEPARTMENT_SEARCH_KEY, params], fetchGetDepartments, options)
}

export function useDepartmentByIdQuery(id, options) {
  return useQuery([DEPARTMENT_KEY, id], fetchGetDepartmentById, options)
}

export function useDepartmentFindQuery(address, search, options) {
  return useQuery([DEPARTMENT_KEY, address, search], fetchPostDepartmentFind, options)
}

export function useHQDepartmentsQuery(options) {
  return useQuery([DEPARTMENT_KEY, 'hq'], fetchGetHQDepartments, options)
}

export function useMutateDepartmentSettings(queryClient) {
  return useMutation(patchDepartmentSettings, {
    onSuccess: data => {
      queryClient.setQueryData([DEPARTMENT_KEY, data.data._id], { data: data.data })
      queryClient.refetchQueries(APPOINTMENTS_KEY, { active: true })
    }
  })
}

export function useDepartmentsNoDemand(params, options) {
  return useQuery([DEPARTMENT_KEY, 'noDemand', params], getDepartmentsNoDemand, options)
}

export function useDepartmentsWithPlans({ address, search, limit = 100, distance }, options) {
  return useQuery(
    [DEPARTMENT_APPROVED_DEMAND_KEY, address, search, limit, distance],
    postDepartmentsWithPlans,
    options
  )
}

export function useDepartmentsWithDemand({ address, search, limit = 100, distance }, options) {
  return useQuery(
    [DEPARTMENT_APPROVED_DEMAND_KEY, address, search, limit, distance],
    postDepartmentsWithDemands,
    options
  )
}
